var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import translate from "src/i18n/translate";
import ReportingYearTable from "./ReportingYearsTable";
import { useEffect, useReducer } from "react";
import { sendRequest } from "src/common/http";
import WidgetLoader from "src/features/common/elements/WidgetLoader";
import CreateReportingYearModal from "./CreateReportingYearModal";
var defaultAction = {
    type: "",
    reportingYears: [],
    updateReportingYear: null
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setReportingYears":
            return __assign(__assign({}, state), { reportingYears: action.reportingYears, showLoader: false });
        case "toggleReportingYearModal":
            return __assign(__assign({}, state), { showReportingYearModalState: !state.showReportingYearModalState });
        case "updateReportingYear":
            return __assign(__assign({}, state), { updateReportingYear: action.updateReportingYear });
        default:
            return state;
    }
};
var initialState = {
    reportingYears: null,
    showLoader: true,
    showReportingYearModalState: false,
    updateReportingYear: null
};
function checkNoReportingYears(showLoader, reportingYears) {
    return (showLoader === false &&
        reportingYears !== null &&
        reportingYears.length === 0);
}
function checkReportingYearsExist(showLoader, reportingYears) {
    return showLoader === false && reportingYears && reportingYears.length > 0;
}
export function ReportingYears() {
    var _a = useReducer(reducer, initialState), state = _a[0], dispatch = _a[1];
    function fetchReportingYears() {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setReportingYears", reportingYears: result }));
        };
        sendRequest({ uri: "/sa/reporting_years", camelize: true }, applyResult);
    }
    useEffect(function () {
        fetchReportingYears();
    }, []);
    function toggleCreateReportingYearModal() {
        dispatch(__assign(__assign({}, defaultAction), { type: "toggleReportingYearModal" }));
        dispatch(__assign(__assign({}, defaultAction), { type: "updateReportingYear", updateReportingYear: null }));
    }
    function editReportingYearModal(reportingYear) {
        dispatch(__assign(__assign({}, defaultAction), { type: "updateReportingYear", updateReportingYear: reportingYear }));
        dispatch(__assign(__assign({}, defaultAction), { type: "toggleReportingYearModal" }));
    }
    return (_jsxs("div", { children: [_jsxs("div", { className: "reporting-year-title", children: [_jsx("div", { children: translate("app.settings.reportingYears") }), checkReportingYearsExist(state.showLoader, state.reportingYears) && (_jsx(WmButton, { "button-type": "primary", id: "add-new-reporting-year", className: "create-reporting-year", onClick: toggleCreateReportingYearModal, children: translate("app.settings.addReportingYears") }))] }), state.showLoader && state.reportingYears === null && (_jsx(WidgetLoader, { loaderContainerClasses: "loader", loaderClasses: "content" })), checkReportingYearsExist(state.showLoader, state.reportingYears) && (_jsx("div", { id: "reporting-year-table-wrapper", children: _jsx(ReportingYearTable, { reportingYears: state.reportingYears, editReportingYearModal: editReportingYearModal }) })), checkNoReportingYears(state.showLoader, state.reportingYears) && (_jsx(ReportingYearEmptyState, { toggleCreateReportingYearModal: toggleCreateReportingYearModal })), _jsx(CreateReportingYearModal, { openReportingYearModalState: state.showReportingYearModalState, closeReportingYearModal: toggleCreateReportingYearModal, reportingYears: state.reportingYears, fetchReportingYears: fetchReportingYears, updateReportingYear: state.updateReportingYear })] }));
}
function ReportingYearEmptyState(_a) {
    var toggleCreateReportingYearModal = _a.toggleCreateReportingYearModal;
    return (_jsx("div", { className: "no-reporting-years-state", children: _jsxs("table", { className: "data-table -shadow ", children: [_jsx("thead", { className: "dataheader", children: _jsx("tr", { className: "dataheaderrow", children: _jsx("th", { className: "dataheadercell" }) }) }), _jsx("tbody", { className: "databody", children: _jsx("tr", { className: "datarow", children: _jsx("td", { className: "datacell", children: _jsxs("div", { className: "empty-msg", children: [_jsx("span", { className: "heading", children: translate("app.settings.noReportingYears") }), _jsx("div", { className: "-message", children: translate("app.settings.noReportingYearsText") }), _jsx("div", { className: "add-reporting-year", children: _jsx(WmButton, { "button-type": "primary", id: "add-new-reporting-year", onClick: toggleCreateReportingYearModal, children: translate("app.settings.addReportingYears") }) })] }) }) }) })] }) }));
}
