import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "../common/elements/Header.scss";
import translate from "src/i18n/translate";
import Header from "src/features/common/elements/Header";
import OrgProfileContent from "src/features/common/elements/OrgProfileContent";
import ScrollableHeader from "../common/elements/ScrollableHeader";
import { useContext, useEffect } from "react";
import { onScrollChangeBreadCrumb } from "../../static/js/commons";
import { AppContext } from "src/contexts/AppContext";
import PreviewModal from "../lightcast/PreviewModal";
import UnauthorizedComponent from "src/features/insights/UnauthorizedComponent";
export default function OrgProfileComponent(_a) {
    var setSelectedTabView = _a.setSelectedTabView, auth = _a.auth;
    var institution = useContext(AppContext).institution;
    useEffect(function () {
        setTimeout(function () {
            onScrollChangeBreadCrumb(institution === null || institution === void 0 ? void 0 : institution.navigatedFrom);
        }, 1000);
    }, []);
    return (_jsx(_Fragment, { children: auth ? (_jsxs("main", { id: "maincontent", className: "main-body-container-home", children: [_jsx(Header, {}), _jsx(ScrollableHeader, {}), _jsx("div", { className: "insights-hub-body flex-row", children: _jsx("div", { id: "main-org-profile-container", className: "main-org-profile", children: _jsxs("div", { children: [_jsxs("div", { className: "page-banner -insights-header insights-header-home", children: [_jsx("div", { className: "titles", children: "Insights" }), _jsx("div", { className: "description", children: translate("app.header.description") })] }), _jsx("div", { className: "body -height", id: "tab-components", children: _jsx(OrgProfileContent, { setSelectedTabView: setSelectedTabView }) })] }) }) }), _jsx(PreviewModal, {})] })) : (_jsx(UnauthorizedComponent, {})) }));
}
