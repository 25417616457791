var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useReducer, useContext } from "react";
import ParticipationOverview from "./ParticipationOverview";
import { sendRequest } from "../../common/http";
import "./Sll.scss";
import AssessmentProgressChart from "./AssessmentProgressChart";
import translate from "src/i18n/translate";
import WidgetLoader from "../common/elements/WidgetLoader";
import { AppContext } from "../../contexts/AppContext";
import ApiError from "../common/elements/ApiError";
import { WmButton } from "@watermarkinsights/ripple-react";
var defaultSLLAction = {
    type: "",
    apiError: false,
    learningAssessments: {
        noActivityTerms: "",
        participationOverview: null,
        studentAssessmentProgressByTerm: []
    }
};
var stateReducer = function (state, action) {
    switch (action.type) {
        case "showLoader":
            return __assign(__assign({}, state), { loader: true });
        case "setLearningAssessments":
            return __assign(__assign({}, state), { hideWidget: action.learningAssessments.participationOverview === null, learningAssessments: action.learningAssessments, loader: false, apiError: false });
        case "setApiError":
            return __assign(__assign({}, state), { apiError: action.apiError });
        default:
            return state;
    }
};
export default function Sll(_a) {
    var selectedReportingYear = _a.selectedReportingYear, onComponentLoad = _a.onComponentLoad;
    var institution = useContext(AppContext).institution;
    var initialState = {
        hideWidget: institution && institution.platformUuid === null ? true : false,
        loader: true,
        apiError: false,
        learningAssessments: {
            noActivityTerms: "",
            participationOverview: null,
            studentAssessmentProgressByTerm: []
        }
    };
    var _b = useReducer(stateReducer, initialState), state = _b[0], dispatch = _b[1];
    useEffect(function () {
        if (institution && institution.platformUuid && selectedReportingYear.uuid) {
            dispatch(__assign(__assign({}, defaultSLLAction), { type: "setApiError", apiError: false }));
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultSLLAction), { type: "setLearningAssessments", learningAssessments: result }));
                onComponentLoad("sll");
            };
            var onError = function () {
                dispatch(__assign(__assign({}, defaultSLLAction), { type: "setApiError", apiError: true }));
                onComponentLoad("sll");
            };
            dispatch(__assign(__assign({}, defaultSLLAction), { type: "showLoader" }));
            sendRequest({
                uri: "/sll/learning_assessments?start_date=".concat(selectedReportingYear.startDate, "&end_date=").concat(selectedReportingYear.endDate, "&with_activities=").concat(false),
                camelize: true
            }, applyResult, onError);
        }
    }, [selectedReportingYear, institution && institution.platformUuid]);
    var openSllHTMLViewLink = function () {
        return window.open(getURL(), "_blank");
    };
    var getURL = function () {
        var queryString = window.location.search;
        var param = new URLSearchParams(queryString);
        var locale = param.get("locale");
        var url = "/insights/sll/reporting_year/".concat(selectedReportingYear.uuid, "/html");
        if (locale !== null) {
            url = "".concat(url, "?locale=").concat(locale);
        }
        return url;
    };
    var header = function () {
        var _a;
        return (_jsxs("div", { className: "header", children: [_jsxs("div", { className: "info", children: [_jsx("h3", { className: "title", children: translate("app.sll.learningAssessment") }), _jsx("p", { className: "description", children: translate("app.sll.reviewProgress") })] }), ((_a = state.learningAssessments.studentAssessmentProgressByTerm) === null || _a === void 0 ? void 0 : _a.length) >
                    0 &&
                    !state.loader &&
                    !state.apiError ? (_jsx("div", { children: _jsx(WmButton, { id: "export-button", "aria-controls": "acc-listbox", "aria-expanded": "false", tabIndex: 0, onClick: openSllHTMLViewLink, "button-type": "secondary", children: translate("app.exportHtml") }) })) : ("")] }));
    };
    var body = function () {
        var _a, _b;
        return (_jsxs(_Fragment, { children: [header(), _jsxs("div", { className: "body", children: [state.loader && !state.apiError && (_jsx(WidgetLoader, { loaderContainerClasses: "loader-container", loaderClasses: "widget-loader" })), !state.loader && !state.apiError && (_jsxs(_Fragment, { children: [((_a = state.learningAssessments.studentAssessmentProgressByTerm) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsxs("div", { id: "participation-overview", className: "sll-section", children: [_jsx(ParticipationOverview, { overview: state.learningAssessments.participationOverview, reportingYear: selectedReportingYear.name }), _jsx("div", { className: "middle-section" }), _jsx(AssessmentProgressChart, { assessmentProgress: state.learningAssessments.studentAssessmentProgressByTerm, noActivityTerms: state.learningAssessments.noActivityTerms })] })), ((_b = state.learningAssessments.studentAssessmentProgressByTerm) === null || _b === void 0 ? void 0 : _b.length) === 0 && _jsx(NoLearningAssessments, {})] })), state.apiError && _jsx(ApiError, {})] })] }));
    };
    return state.hideWidget ? (_jsx(React.Fragment, {})) : (_jsx("div", { className: "grid-container wm-grid-col-12 wm-grid-col-md-10", children: _jsx("div", { className: "paper-card paper-card-height", children: body() }) }));
}
var NoLearningAssessments = function () {
    return (_jsxs("div", { className: "no-plans", children: [_jsx("div", { className: "no-plans-text", children: translate("app.sll.noAssessments") }), _jsx("div", { className: "no-plans-text-select", children: translate("app.sll.noAssessmentsSubText") })] }));
};
