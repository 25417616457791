var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import { useReducer } from "react";
import "./Lightcast.scss";
import translate from "src/i18n/translate";
import { WmButton } from "@watermarkinsights/ripple-react";
var initialState = {
    openTermsAndConditionsModal: false
};
var defaultLightcastFooterAction = {
    data: false,
    type: ""
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setTermsAndConditionsModalStatus":
            return __assign(__assign({}, state), { openTermsAndConditionsModal: action.data });
        default:
            return state;
    }
};
export default function LightcastFooter() {
    var _a = useReducer(reducer, initialState), state = _a[0], dispatch = _a[1];
    var toggleTermsModal = function (state) {
        dispatch(__assign(__assign({}, defaultLightcastFooterAction), { type: "setTermsAndConditionsModalStatus", data: state }));
    };
    return (_jsxs("div", { className: "lightcast-branding-container", children: [_jsxs("div", { className: "lightcast-branding", children: [_jsx("span", { className: "powered-by", children: translate("app.lightcast.poweredBy") }), _jsx("img", { className: "lightcast-logo", src: "/../images/lighcast_logo.svg", alt: "lightcast-logo" })] }), _jsx(WmButton, { buttonType: "textonly", onClick: function () { return toggleTermsModal(true); }, children: _jsx("span", { className: "terms-and-conditions", children: translate("app.lightcast.viewTermsAndConditions") }) }), state.openTermsAndConditionsModal && (_jsx(TermsAndConditionsModal, { onClose: function () { return toggleTermsModal(false); } }))] }));
}
