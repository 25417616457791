var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useReducer } from "react";
import { sendRequest } from "../../common/http";
import translate from "src/i18n/translate";
import { WmProgressMonitor, WmProgressSlice, WmProgressIndicator, WmTabList, WmTabItem } from "@watermarkinsights/ripple-react";
import ReportingYears, { dateWithoutTimezone } from "../common/elements/ReportingYears";
import { WmTabPanel } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import WidgetLoader from "../common/elements/WidgetLoader";
import ApiError from "../common/elements/ApiError";
import { AppContext } from "../../contexts/AppContext";
import { defaultReportingYear as emptyReportingYear } from "../common/interfaces/Interface";
var curriculumProposalsAction = {
    type: "",
    selectedReportingYear: emptyReportingYear,
    tab: "",
    curriculumChanges: null,
    showLoader: true,
    apiError: false
};
var reducer = function (state, action) {
    switch (action.type) {
        case "selectReportingYear":
            return __assign(__assign({}, state), { selectedReportingYear: action.selectedReportingYear });
        case "setSelectedTab":
            return __assign(__assign({}, state), { tab: action.tab });
        case "setCurriculumData":
            return __assign(__assign({}, state), { curriculumChanges: action.curriculumChanges });
        case "setShowLoader":
            return __assign(__assign({}, state), { showLoader: action.showLoader });
        case "setApiError":
            return __assign(__assign({}, state), { apiError: action.apiError });
        default:
            return state;
    }
};
export default function CurriculumProposals(_a) {
    var _b, _c;
    var reportingYears = _a.reportingYears, defaultReportingYear = _a.defaultReportingYear, mappingId = _a.mappingId, onComponentLoad = _a.onComponentLoad, resetLoadedComponent = _a.resetLoadedComponent, ryApiError = _a.ryApiError;
    var initialState = {
        selectedReportingYear: defaultReportingYear,
        tab: "program-proposals",
        curriculumChanges: null,
        showLoader: true,
        apiError: false
    };
    var _d = useReducer(reducer, initialState), state = _d[0], dispatch = _d[1];
    var membership = useContext(AppContext).membership;
    var selectReportingYear = function (year) {
        dispatch(__assign(__assign({}, curriculumProposalsAction), { type: "selectReportingYear", selectedReportingYear: year }));
    };
    var onWmTabSelected = function (event) {
        dispatch(__assign(__assign({}, curriculumProposalsAction), { type: "setSelectedTab", tab: event.detail.tabId }));
    };
    var tabOption = [
        {
            name: translate("app.missionAndOutcomes.programs"),
            id: "program-proposals"
        },
        {
            name: translate("app.missionAndOutcomes.courses"),
            id: "course-proposals"
        }
    ];
    var resetApiErrors = function () {
        dispatch(__assign(__assign({}, curriculumProposalsAction), { type: "setApiError", apiError: false }));
    };
    useEffect(function () {
        var _a, _b;
        if (state.selectedReportingYear && state.selectedReportingYear.startDate) {
            dispatch(__assign(__assign({}, curriculumProposalsAction), { type: "setShowLoader", showLoader: true }));
            resetLoadedComponent("curriculumProposals");
            resetApiErrors();
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, curriculumProposalsAction), { type: "setShowLoader", showLoader: false }));
                dispatch(__assign(__assign({}, curriculumProposalsAction), { type: "setCurriculumData", curriculumChanges: result }));
                onComponentLoad("curriculumProposals");
            };
            var onError = function () {
                dispatch(__assign(__assign({}, curriculumProposalsAction), { type: "setShowLoader", showLoader: false }));
                dispatch(__assign(__assign({}, curriculumProposalsAction), { type: "setApiError", apiError: true }));
                onComponentLoad("curriculumProposals");
            };
            sendRequest({
                uri: "/cs/curricular_changes" +
                    "?mapping_id=" +
                    mappingId +
                    "&start_date=" +
                    dateWithoutTimezone((_a = state.selectedReportingYear) === null || _a === void 0 ? void 0 : _a.startDate) +
                    "&end_date=" +
                    dateWithoutTimezone((_b = state.selectedReportingYear) === null || _b === void 0 ? void 0 : _b.endDate),
                camelize: true
            }, applyResult, onError);
        }
    }, [state.selectedReportingYear, mappingId]);
    useEffect(function () {
        selectReportingYear(defaultReportingYear);
    }, [defaultReportingYear]);
    var showData = function () { return !state.showLoader && !ryApiError && !state.apiError; };
    var showLoader = function () { return state.showLoader && !ryApiError && !state.apiError; };
    return (_jsxs("div", { className: "paper-card program-ilo-widget", children: [_jsx("div", { className: "header", children: _jsxs("div", { className: "info", children: [_jsx("h3", { className: "title", children: translate("app.institution.curriculumProposalsHeader") }), _jsx("p", { className: "description", children: translate("app.institution.curriculumProposalsDescription") })] }) }), _jsxs("div", { className: (reportingYears === null || reportingYears === void 0 ? void 0 : reportingYears.length) === 0 ? "body flex-row center-element" : "body", children: [(reportingYears === null || reportingYears === void 0 ? void 0 : reportingYears.length) === 0 && (_jsx(ReportingYearEmptyState, { membership: membership })), !ryApiError &&
                        reportingYears &&
                        (reportingYears === null || reportingYears === void 0 ? void 0 : reportingYears.length) > 0 &&
                        state.selectedReportingYear.startDate ? (_jsxs(_Fragment, { children: [_jsx(ReportingYears, { reportingYears: reportingYears, selectedReportingYear: state.selectedReportingYear, selectReportingYear: selectReportingYear }, state.selectedReportingYear.uuid), showLoader() ? (_jsx(WidgetLoader, { loaderContainerClasses: "loader-container", loaderClasses: "widget-loader" })) : (""), showData() ? (_jsx("div", { className: "tabbar curriculam-tab", children: _jsx(WmTabList, { id: "curriculum-tab", selectedTab: state.tab, onWmTabSelected: onWmTabSelected, children: tabOption.map(function (tab, index) { return (_jsx(WmTabItem, { tabId: tab.id, children: tab.name }, tab.id + (index + 1))); }) }) })) : ("")] })) : (""), ryApiError && _jsx(ApiError, {}), state.apiError && !ryApiError ? (_jsx(ApiError, { containerClasses: "api-error curriculum-proposals-api-error" })) : (""), showData() ? (_jsxs(_Fragment, { children: [_jsx(WmTabPanel, { className: "tab-panel", id: "program-proposals", tabId: "program-proposals", children: _jsxs("div", { className: "curriculum-panel", children: [state.curriculumChanges &&
                                            state.curriculumChanges.totalProgramProposals > 0 ? (_jsx("h4", { className: "title-curriculum-chart", children: translate("app.institution.curriculum.programProposals") })) : (""), ((_b = state.curriculumChanges) === null || _b === void 0 ? void 0 : _b.totalProgramProposals) === 0 ? (_jsx(NoProgramProposals, {})) : (""), state.curriculumChanges &&
                                            state.curriculumChanges.totalProgramProposals > 0 ? (_jsxs(_Fragment, { children: [_jsx("p", { className: "org totals-curriculum-chart", children: translate("app.institution.curriculum.totalProposals", {
                                                        total: state.curriculumChanges.totalProgramProposals
                                                    }) }), _jsx(LegendWrapper, {}), _jsx(CurriculumProposalsProgramsChart, { proposals: state.curriculumChanges.programProposals })] })) : ("")] }) }), _jsx(WmTabPanel, { className: "tab-panel", id: "course-proposals", tabId: "course-proposals", children: _jsxs("div", { className: "curriculum-panel", children: [state.curriculumChanges &&
                                            state.curriculumChanges.totalCourseProposals > 0 ? (_jsx("h4", { className: "title-curriculum-chart", children: translate("app.institution.curriculum.courseProposals") })) : (""), ((_c = state.curriculumChanges) === null || _c === void 0 ? void 0 : _c.totalCourseProposals) === 0 ? (_jsx(NoCourseProposals, {})) : (""), state.curriculumChanges &&
                                            state.curriculumChanges.totalCourseProposals > 0 ? (_jsxs(_Fragment, { children: [_jsx("p", { className: "org totals-curriculum-chart", children: translate("app.institution.curriculum.totalProposals", {
                                                        total: state.curriculumChanges.totalCourseProposals
                                                    }) }), _jsx(LegendWrapper, {}), _jsx(CurriculumProposalsCoursesChart, { proposals: state.curriculumChanges.courseProposals })] })) : ("")] }) })] })) : ("")] })] }));
}
var CurriculumProposalsProgramsChart = function (_a) {
    var programProposals = _a.proposals;
    var accepted = useIntl().formatMessage({
        id: "app.institution.curriculum.accepted"
    });
    var inReview = useIntl().formatMessage({
        id: "app.institution.curriculum.inReview"
    });
    var acceptedPopoverTextRevised = useIntl().formatMessage({
        id: "app.institution.curriculum.acceptedPopoverTextRevised"
    });
    var inReviewPopoverTextRevised = useIntl().formatMessage({
        id: "app.institution.curriculum.inReviewPopoverRevised"
    });
    var acceptedPopoverTextNew = useIntl().formatMessage({
        id: "app.institution.curriculum.acceptedPopoverTextNew"
    });
    var inReviewPopoverTextNew = useIntl().formatMessage({
        id: "app.institution.curriculum.inReviewPopoverNew"
    });
    var newProgram = useIntl().formatMessage({
        id: "app.institution.curriculum.newProgram"
    });
    var reviseProgram = useIntl().formatMessage({
        id: "app.institution.curriculum.revisedProgram"
    });
    var newTotal = programProposals.newAccepted + programProposals.newInReview;
    var revisedTotal = programProposals.revisedAccepted + programProposals.revisedInReview;
    return (_jsx(_Fragment, { children: programProposals && (_jsxs(WmProgressMonitor, { id: "curriculum-Programs", children: [programProposals.newAccepted || programProposals.newInReview ? (_jsxs(WmProgressIndicator, { label: newProgram, children: [_jsx(WmProgressSlice, { legend: accepted, amount: "".concat(programProposals.newAccepted), popoverTitle: popover(programProposals.newAccepted, newTotal), popoverText: acceptedPopoverTextNew }), _jsx(WmProgressSlice, { legend: inReview, amount: "".concat(programProposals.newInReview), popoverTitle: popover(programProposals.newInReview, newTotal), popoverText: inReviewPopoverTextNew })] }, "new-program-proposals")) : (_jsxs("div", { children: [_jsx("div", { className: "proposals-label", children: newProgram }), _jsx("div", { className: "proposals-empty", children: translate("app.institution.curriculum.newProgramEmptyState") })] })), programProposals.revisedAccepted ||
                    programProposals.revisedInReview ? (_jsxs(WmProgressIndicator, { label: reviseProgram, children: [_jsx(WmProgressSlice, { legend: accepted, amount: "".concat(programProposals.revisedAccepted), popoverTitle: popover(programProposals.revisedAccepted, revisedTotal), popoverText: acceptedPopoverTextRevised }), _jsx(WmProgressSlice, { legend: inReview, amount: "".concat(programProposals.revisedInReview), popoverTitle: popover(programProposals.revisedInReview, revisedTotal), popoverText: inReviewPopoverTextRevised })] }, "revise-program-proposals")) : (_jsxs("div", { children: [_jsx("div", { className: "proposals-label", children: reviseProgram }), _jsx("div", { className: "proposals-empty", children: translate("app.institution.curriculum.revisedProgramEmptyState") })] }))] })) }));
};
var LegendWrapper = function () {
    return (_jsx("div", { className: "legend-wrapper", children: _jsxs("div", { className: "legend", "aria-hidden": "true", children: [_jsxs("div", { className: "legend-item", children: [_jsx("div", { className: "legend-color", style: { backgroundColor: "rgb(107, 107, 107)" } }), _jsx("div", { className: "legend-text", children: translate("app.institution.curriculum.inReview") })] }), _jsxs("div", { className: "legend-item", children: [_jsx("div", { className: "legend-color", style: { backgroundColor: "rgb(8, 128, 0)" } }), _jsx("div", { className: "legend-text", children: translate("app.institution.curriculum.accepted") })] })] }) }));
};
var CurriculumProposalsCoursesChart = function (_a) {
    var courseProposals = _a.proposals;
    var accepted = useIntl().formatMessage({
        id: "app.institution.curriculum.accepted"
    });
    var inReview = useIntl().formatMessage({
        id: "app.institution.curriculum.inReview"
    });
    var acceptedPopoverTextCourseRevised = useIntl().formatMessage({
        id: "app.institution.curriculum.acceptedPopoverTextCourseRevised"
    });
    var inReviewPopoverTextCourseRevised = useIntl().formatMessage({
        id: "app.institution.curriculum.inReviewPopoverTextCourseRevised"
    });
    var acceptedPopoverTextCourseNew = useIntl().formatMessage({
        id: "app.institution.curriculum.acceptedPopoverTextCourseNew"
    });
    var inReviewPopoverTextCourseNew = useIntl().formatMessage({
        id: "app.institution.curriculum.inReviewPopoverTextCourseNew"
    });
    var newCourse = useIntl().formatMessage({
        id: "app.institution.curriculum.newCourse"
    });
    var reviseCourse = useIntl().formatMessage({
        id: "app.institution.curriculum.revisedCourse"
    });
    var newTotal = courseProposals.newAccepted + courseProposals.newInReview;
    var revisedTotal = courseProposals.revisedAccepted + courseProposals.revisedInReview;
    return (_jsx(_Fragment, { children: courseProposals && (_jsxs(WmProgressMonitor, { id: "curriculum-courses", children: [courseProposals.newAccepted || courseProposals.newInReview ? (_jsxs(WmProgressIndicator, { label: newCourse, children: [_jsx(WmProgressSlice, { legend: accepted, amount: "".concat(courseProposals.newAccepted), popoverTitle: popover(courseProposals.newAccepted, newTotal), popoverText: acceptedPopoverTextCourseNew }), _jsx(WmProgressSlice, { legend: inReview, amount: "".concat(courseProposals.newInReview), popoverTitle: popover(courseProposals.newInReview, newTotal), popoverText: inReviewPopoverTextCourseNew })] }, "new-course-proposals")) : (_jsxs("div", { children: [_jsx("div", { className: "proposals-label", children: newCourse }), _jsx("div", { className: "proposals-empty", children: translate("app.institution.curriculum.newCourseEmptyState") })] })), courseProposals.revisedAccepted ||
                    courseProposals.revisedInReview ? (_jsxs(WmProgressIndicator, { label: reviseCourse, children: [_jsx(WmProgressSlice, { legend: accepted, amount: "".concat(courseProposals.revisedAccepted), popoverTitle: popover(courseProposals.revisedAccepted, revisedTotal), popoverText: acceptedPopoverTextCourseRevised }), _jsx(WmProgressSlice, { legend: inReview, amount: "".concat(courseProposals.revisedInReview), popoverTitle: popover(courseProposals.revisedInReview, revisedTotal), popoverText: inReviewPopoverTextCourseRevised })] }, "revise-course-proposals")) : (_jsxs("div", { children: [_jsx("div", { className: "proposals-label", children: reviseCourse }), _jsx("div", { className: "proposals-empty", children: translate("app.institution.curriculum.revisedCourseEmptyState") })] }))] })) }));
};
var popover = function (amount, total) {
    return "".concat(amount, "/").concat(total);
};
var ReportingYearEmptyState = function (_a) {
    var membership = _a.membership;
    return (_jsx("div", { className: "empty-state-curriculum-widget flex-row center-element", children: _jsxs("div", { children: [_jsx("div", { className: "empty-state-title", children: translate("app.continuousImprovement.noAcademicYearTitle") }), membership && membership.userRole === "admin" && adminEmptyState(), membership &&
                    membership.userRole === "contributor" &&
                    contributorEmptyState()] }) }));
};
function contributorEmptyState() {
    return (_jsx("div", { className: "empty-state-text margin-auto", children: translate("app.institution.curriculum.contactAdmin") }));
}
function adminEmptyState() {
    var REDIRECT_URI = runConfig.REDIRECT_URI;
    return (_jsxs("div", { className: "empty-state-text margin-auto", children: [_jsx("a", { className: "empty-state-link", href: "".concat(REDIRECT_URI, "/admin/configuration_settings/reporting_year"), children: translate("app.faculty.createReportingYear") }), translate("app.institution.curriculum.contributor")] }));
}
var NoProgramProposals = function () {
    return (_jsxs("div", { className: "no-plans", children: [_jsx("div", { className: "no-plans-text", children: translate("app.institution.curriculum.noProgramProposalsText") }), _jsx("div", { className: "no-plans-text-select", children: translate("app.institution.curriculum.noProposalsSubText") })] }));
};
var NoCourseProposals = function () {
    return (_jsxs("div", { className: "no-plans", children: [_jsx("div", { className: "no-plans-text ", children: translate("app.institution.curriculum.noCourseProposalsText") }), _jsx("div", { className: "no-plans-text-select", children: translate("app.institution.curriculum.noProposalsSubText") })] }));
};
