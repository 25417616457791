import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "../common/elements/Header.scss";
import AppBar from "src/features/common/elements/AppBar";
import MainSideNavBar from "src/features/common/elements/MainSideNavBar";
import Loader from "src/features/common/elements/Loader";
export var PlanningHeaderSidebar = function (_a) {
    var institution = _a.institution, componentLoaded = _a.componentLoaded, children = _a.children;
    var isMainComponentLoaded = function () {
        return componentLoaded.institution && componentLoaded.membership;
    };
    return (_jsxs(_Fragment, { children: [!isMainComponentLoaded() && _jsx(Loader, {}), _jsxs("div", { className: "pagewrapper", children: [institution && _jsx(AppBar, { institution: institution }), _jsxs("div", { className: "nav-main", children: [institution && (_jsx(MainSideNavBar, { institution: institution, collapseSideNav: componentLoaded.collapseSideNav })), _jsx("div", { className: "contentwrapper", children: children })] })] })] }));
};
