import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmModal, WmModalFooter, WmModalHeader, WmButton } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import translate from "../../i18n/translate";
export default function TermsAndConditionsModal(_a) {
    var onClose = _a.onClose;
    var previewModalPrimaryBtn = useIntl().formatMessage({
        id: "app.lightcast.termsAndConditionsModalPrimaryBtn"
    });
    var termsAndConditionsModalHeading = useIntl().formatMessage({
        id: "app.lightcast.termsAndConditionsModalHeading"
    });
    var openTermsAndService = function () {
        window.open("https://www.watermarkinsights.com/legal/lightcast-terms/", "_blank");
    };
    return (_jsx("div", { id: "terms-and-conditions-modal", children: _jsxs(WmModal, { open: true, id: "my-terms-and-conditions-modal", elementToFocus: "primary", "aria-label": termsAndConditionsModalHeading, modalType: "dialog", uid: "my-terms-and-conditions-modal", onWmModalCloseTriggered: onClose, onWmModalPrimaryTriggered: onClose, children: [_jsx(WmModalHeader, { heading: termsAndConditionsModalHeading }), _jsx("div", { className: "modal-content body", id: "content-preview-model", children: _jsx("div", { className: "preview-content", children: _jsxs("div", { className: "para-text", children: [translate("app.lightcast.termsAndConditionsModalPara"), _jsx(WmButton, { buttonType: "textonly", onClick: openTermsAndService, children: _jsx("span", { className: "terms-and-conditions", children: translate("app.lightcast.termsOfService") }) })] }) }) }), _jsx(WmModalFooter, { primaryText: previewModalPrimaryBtn, deleteStyle: false })] }) }));
}
