import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import camelcaseKeys from "camelcase-keys";
import translate from "src/i18n/translate";
export function formatDate(date) {
    var ymd = date.split("T")[0].split("-");
    var year = ymd[0].split("");
    return ymd[1] + "/" + ymd[2] + "/" + year[2] + year[3];
}
export function formatDateFullYear(date) {
    var ymd = date.split("T")[0].split("-");
    return ymd[1] + "/" + ymd[2] + "/" + ymd[0];
}
export function formatDateIso(date) {
    var ymd = date.split("T")[0].split("-");
    if (Number(ymd[2]) >= 0 && Number(ymd[2]) < 10) {
        ymd[2] = 0 + "" + ymd[2];
    }
    return ymd[1] + "/" + ymd[2] + "/" + ymd[0];
}
export function formatDateUsToIso(date) {
    var mdy = date.split("T")[0].split("/");
    return mdy[2] + "-" + mdy[0] + "-" + mdy[1];
}
export function formatDateWithMonthAbbr(dateStr) {
    return new Date(dateStr).toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric"
    });
}
function currentDateWithTimeStamp() {
    var date = new Date();
    var month = String(date.getUTCMonth() + 1).padStart(2, "0");
    var day = String(date.getUTCDate()).padStart(2, "0");
    var year = String(date.getUTCFullYear());
    var hours = date.getUTCHours();
    var amOrPm = hours < 12 ? "AM" : "PM";
    var hour = tweleHourFormat(hours);
    var minutes = String(date.getUTCMinutes()).padStart(2, "0");
    return "".concat(month, day, year, "_", hour, minutes, "_", amOrPm, "_", "UTC");
}
function tweleHourFormat(hours) {
    var hour = "";
    if (hours === 0 || hours === 12) {
        hour = "12";
    }
    else if (hours > 0 && hours <= 11) {
        hour = String(hours).padStart(2, "0");
    }
    else if (hours > 12) {
        hour = String(hours - 12).padStart(2, "0");
    }
    return hour;
}
export function downloadPDF(pdfFileBytes, fileTitle) {
    var PDFData = pdfFileBytes;
    var blob = base64ToStringBlob(PDFData, "application/pdf");
    var fileName = fileTitle + "_" + currentDateWithTimeStamp() + ".pdf";
    // For IE and Edge
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    }
    else {
        var link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
function base64ToStringBlob(data, contentType) {
    var byteCharacters = atob(data);
    var byteArrays = [];
    var sliceSize = 512;
    contentType = contentType || "application/pdf";
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {
        type: contentType
    });
}
export var getLoaderStatus = function (loaderState, liveRegion) {
    if (liveRegion && loaderState) {
        setTimeout(function () {
            liveRegion.textContent = "insights data is loading";
        }, 300);
    }
    else if (liveRegion && !loaderState) {
        setTimeout(function () {
            liveRegion.textContent = "insights data is ready to review";
        }, 300);
    }
};
export function camlizeKeys(result) {
    return camelcaseKeys(result, { deep: true, exclude: [/-/] });
}
export var NoResults = function () {
    return (_jsxs("table", { className: "data-table -shadow", children: [_jsx("thead", { className: "dataheader", children: _jsx("tr", { className: "dataheaderrow", children: _jsx("th", { className: "dataheadercell" }) }) }), _jsx("tbody", { className: "databody", children: _jsx("tr", { className: "datarow", children: _jsx("td", { children: _jsxs("div", { className: "empty-msg", children: [_jsx("span", { className: "heading", children: translate("app.noResultFound") }), _jsx("div", { className: "-message", children: translate("app.drilldown.selectDifferentStatus") })] }) }) }) })] }));
};
export var Table = function (props) {
    return _jsx("table", { className: "data-table -shadow", children: props.children });
};
export var TableHeader = function (props) {
    return (_jsx("thead", { className: "dataheader", children: _jsxs("tr", { className: "dataheaderrow", children: [props.columns.map(function (column, index) {
                    return (_jsx("th", { className: "dataheadercell", "data-th": column, children: column }, "column-".concat(index)));
                }), !props.forCourseDetails && (_jsx("td", { className: "dataheadercell", "data-th": "Email Link" }))] }) }));
};
export var TableBody = function (props) {
    return _jsx("tbody", { className: "databody", children: props.children });
};
export var checkIfProductIntegrated = function (integratedProducts, productId) {
    return integratedProducts === null || integratedProducts === void 0 ? void 0 : integratedProducts.some(function (product) {
        return product.id === productId;
    });
};
export var checkIfSllProductIntegrated = function (integratedProducts) {
    return checkIfProductIntegrated(integratedProducts, "sll");
};
export var checkIfSseProductIntegrated = function (integratedProducts) {
    return checkIfProductIntegrated(integratedProducts, "sse");
};
export var checkIfFsProductIntegrated = function (integratedProducts) {
    return checkIfProductIntegrated(integratedProducts, "fs");
};
export var checkIfCsProductIntegrated = function (integratedProducts) {
    return checkIfProductIntegrated(integratedProducts, "cs");
};
export var getCurrentReportingYear = function (reportingYears) {
    var today = new Date();
    return reportingYears.filter(function (ry) {
        return new Date(ry.startDate) < today && new Date(ry.endDate) > today;
    })[0];
};
export var scrollToTop = function (className) {
    var element = document.querySelector(className);
    if (element) {
        element === null || element === void 0 ? void 0 : element.scrollIntoView();
    }
};
export function navigateToUrl(url) {
    window.location.href = url;
}
