import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { formatDateFullYear } from "src/features/common/helper";
import translate from "src/i18n/translate";
export default function ProgramsTable(_a) {
    var missionHierarchy = _a.missionHierarchy, successHierarchy = _a.successHierarchy, learningHierarchy = _a.learningHierarchy, iloHierarchy = _a.iloHierarchy, leads = _a.leads, curriculumMap = _a.curriculumMap;
    var completeHierarchy = {
        missionHierarchy: missionHierarchy,
        successHierarchy: successHierarchy,
        learningHierarchy: learningHierarchy,
        iloHierarchy: iloHierarchy,
        curriculumMap: curriculumMap
    };
    return (_jsx(_Fragment, { children: _jsxs(Table, { children: [_jsx(TableHeader, { columns: [
                        translate("app.drilldown.organization"),
                        translate("app.orgProgressCard.missionStatement"),
                        translate("app.orgProgressCard.learningOutcomes"),
                        translate("app.orgProgressCard.successOutcomes"),
                        translate("app.orgProgressCard.curriculumMap"),
                        translate("app.orgProgressCard.ILOMapped"),
                        translate("app.drilldown.lead")
                    ] }), missionHierarchy[0].name && (_jsx(TableBody, { children: Array.isArray(completeHierarchy.missionHierarchy)
                        ? completeHierarchy.missionHierarchy.map(function (node, index) {
                            return (_jsx(RenderHierarchy, { node: {
                                    missionHierarchy: node,
                                    successHierarchy: successHierarchy[index],
                                    learningHierarchy: learningHierarchy[index],
                                    iloHierarchy: iloHierarchy[index],
                                    curriculumMap: curriculumMap[index]
                                }, leads: leads }, "".concat(node.name, " ").concat(index)));
                        })
                        : "" }))] }) }));
}
var Table = function (props) {
    return _jsx("table", { className: "data-table -shadow", children: props.children });
};
var TableHeader = function (props) {
    return (_jsx("thead", { className: "dataheader", children: _jsx("tr", { className: "dataheaderrow", children: props.columns.map(function (column, index) {
                return (_jsx("th", { className: "dataheadercell", "data-th": column, children: column }, "column-".concat(index)));
            }) }) }));
};
var TableBody = function (props) {
    return _jsx("tbody", { className: "databody", children: props.children });
};
var Node = function (props) {
    var node = props.node, leads = props.leads;
    return (_jsx(_Fragment, { children: node.missionHierarchy.show_stats ? (_jsx(CompleteRow, { node: node, lead: leads[node.missionHierarchy["uuid"]] })) : (_jsx(NoStatsRow, { node: node })) }));
};
var CompleteRow = function (props) {
    var node = props.node, lead = props.lead;
    var leadName = function () {
        if (typeof lead != "undefined") {
            return lead.leads.map(function (l, index) {
                return (_jsx("div", { children: l }, "lead-".concat(index, "-").concat(node.missionHierarchy.uuid)));
            });
        }
    };
    return (_jsxs("tr", { className: "datarow", children: [_jsx("td", { className: "datacell level-".concat(node.missionHierarchy.level), children: node.missionHierarchy.name }), _jsxs("td", { className: "datacell", children: [_jsx(StatusCell, { status: node.missionHierarchy.status }), node.missionHierarchy.updated_at ? (_jsx("span", { children: formatDateFullYear(node.missionHierarchy.updated_at) })) : (translate("app.na"))] }), _jsxs("td", { className: "datacell", children: [_jsx(StatusCell, { status: node.learningHierarchy.status }), node.learningHierarchy.name && (_jsx("span", { children: translate("app.strategy.outcomes", {
                            outcomes: node.learningHierarchy.outcomes
                        }) }))] }), _jsxs("td", { className: "datacell", children: [_jsx(StatusCell, { status: node.successHierarchy.status }), node.successHierarchy.name && (_jsx("span", { children: translate("app.strategy.outcomes", {
                            outcomes: node.successHierarchy.outcomes
                        }) }))] }), _jsxs("td", { className: "datacell", children: [_jsx(StatusCell, { status: node.curriculumMap.status }), node.curriculumMap.updated_at ? (_jsx("span", { children: formatDateFullYear(node.curriculumMap.updated_at) })) : (translate("app.na"))] }), _jsxs("td", { className: "datacell", children: [_jsx(StatusCell, { status: node.iloHierarchy.status }), node.iloHierarchy.name && (_jsx("span", { children: translate("app.strategy.mapped", {
                            outcomes: node.iloHierarchy.ilo_mapped
                        }) }))] }), _jsx("td", { className: "datacell", children: leadName() })] }, "".concat(node.missionHierarchy.uuid, "-datarow")));
};
var StatusCell = function (props) {
    var content = function () {
        switch (props.status) {
            case "not_started":
                return (_jsx("div", { className: "not-started-status", children: translate("app.drilldown.notStarted") }));
            case "completed":
                return (_jsx("div", { className: "success-status -completed", children: _jsx("div", { className: "title-text", children: translate("app.drilldown.completed") }) }));
        }
    };
    return _jsx(_Fragment, { children: content() });
};
var NoStatsRow = function (props) {
    var node = props.node;
    return (_jsxs("tr", { className: "datarow", children: [_jsx("td", { className: "datacell level-".concat(node.missionHierarchy.level), children: node.missionHierarchy.name }), _jsx("td", { className: "datacell" }), _jsx("td", { className: "datacell" }), _jsx("td", { className: "datacell" }), _jsx("td", { className: "datacell" }), _jsx("td", { className: "datacell" }), _jsx("td", { className: "datacell" })] }, "".concat(node.missionHierarchy.uuid, "-datarow")));
};
var RenderHierarchy = function (props) {
    var node = props.node, leads = props.leads;
    return (_jsxs(React.Fragment, { children: [_jsx(Node, { node: node, leads: leads }), node.missionHierarchy.children
                ? node.missionHierarchy.children.map(function (child, index) {
                    return (_jsx(RenderHierarchy, { node: {
                            missionHierarchy: child,
                            successHierarchy: node.successHierarchy.children[index],
                            learningHierarchy: node.learningHierarchy.children[index],
                            iloHierarchy: node.iloHierarchy.children[index],
                            curriculumMap: node.curriculumMap.children[index]
                        }, leads: leads }, "".concat(node.missionHierarchy.name, " ").concat(index, "-hierarchy")));
                })
                : ""] }));
};
