var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useReducer } from "react";
import "../../app/App.scss";
import Header from "../../features/common/elements/Header";
import { applyPolyfills, defineCustomElements } from "@watermarkinsights/ripple/dist/loader";
import { sendRequest } from "../../common/http";
import { useParams } from "react-router-dom";
import OrganizationContent from "./OrganizationContent";
import { defaultOrganization } from "../common/interfaces/Interface";
import PssSideNav from "../common/elements/PssSideNav";
import { AppContext } from "src/contexts/AppContext";
import ScrollableHeader from "../common/elements/ScrollableHeader";
import UnauthorizedComponent from "src/features/insights/UnauthorizedComponent";
var defaultAction = {
    type: "",
    organization: defaultOrganization,
    component: ""
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setOrganization":
            return __assign(__assign({}, state), { organization: action.organization });
        default:
            return state;
    }
};
var initialState = {
    organization: defaultOrganization
};
export function OrgInsightsBody(_a) {
    var auth = _a.auth;
    return _jsxs(_Fragment, { children: [" ", auth ? _jsx(OrgInsights, {}) : _jsx(UnauthorizedComponent, {}), " "] });
}
function OrgInsights() {
    var REDIRECT_URI = runConfig.REDIRECT_URI;
    var _a = useContext(AppContext), institution = _a.institution, sysUser = _a.sysUser;
    applyPolyfills().then(function () {
        defineCustomElements();
    });
    var _b = useParams(), institutionUuid = _b.institution_uuid, orgUuid = _b.organization_uuid;
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    useEffect(function () {
        if (institutionUuid && orgUuid) {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setOrganization", organization: result }));
            };
            var onError = function () {
                window.location.href = REDIRECT_URI;
            };
            sendRequest({
                uri: "/org_insights/institution/".concat(institutionUuid, "/organization/").concat(orgUuid, "/organization_info"),
                camelize: true
            }, applyResult, onError);
        }
    }, [REDIRECT_URI, institutionUuid, orgUuid]);
    return (_jsxs(_Fragment, { children: [institution && institution.navigatedFrom === "navigator" && (_jsx(NavigatorOrgInsightsView, { institution: institution, user: sysUser, organization: state.organization })), institution && institution.navigatedFrom === "planning" && (_jsx(PlanningOrgInsightsView, { institution: institution, organization: state.organization }))] }));
}
var PlanningOrgInsightsView = function (_a) {
    var institution = _a.institution, organization = _a.organization;
    return (_jsx("div", { children: institution && institution.platformUuid != "" && (_jsx(_Fragment, { children: _jsxs("main", { id: "maincontent", className: "main-body-container org-programs-wrapper", children: [_jsx(Header, { organization: organization }), _jsx(ScrollableHeader, { organization: organization }), _jsxs("div", { className: "insights-hub-body flex-row", children: [_jsx(PssSideNav, { organization: organization }), _jsx("div", { id: "main-org-profile-container", className: "main-org-profile", children: _jsx(OrganizationContent, { organization: organization }) })] })] }) })) }));
};
var NavigatorOrgInsightsView = function (_a) {
    var institution = _a.institution, user = _a.user, organization = _a.organization;
    return (_jsx("div", { children: institution && user && institution.platformUuid != "" && (_jsx(_Fragment, { children: _jsxs("main", { id: "maincontent", className: "main-body-container-home", children: [organization.platformUuid !== "" && (_jsx(Header, { organization: organization })), _jsx(ScrollableHeader, { organization: organization }), _jsx("div", { className: "insights-hub-body flex-row", children: _jsx("div", { id: "main-org-profile-container", className: "main-org-profile", children: _jsx(OrganizationContent, { organization: organization }) }) })] }) })) }));
};
