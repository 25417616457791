import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { WmProgressIndicator, WmProgressMonitor, WmProgressSlice } from "@watermarkinsights/ripple-react";
import "../../../common/Insights.scss";
import translate from "../../../../i18n/translate";
import { useIntl } from "react-intl";
export var popover = function (amount, total) {
    return "".concat(amount, "/").concat(total);
};
export default function CoursesChart(_a) {
    var courseData = _a.courseData;
    return (_jsxs("div", { children: [_jsx("div", { className: "title organization-progress", children: translate("app.courseCard.courseProgress") }), _jsx("p", { className: "course totals", children: translate("app.courseCard.courseTotal", {
                    totalCourses: courseData.totalCourses
                }) }), _jsx("div", { id: "course-charts-html", children: _jsx(CourseProgressChart, { courseData: courseData }) })] }));
}
var CourseProgressChart = function (_a) {
    var courseData = _a.courseData;
    var created = useIntl().formatMessage({
        id: "app.drilldown.created"
    });
    var notCreated = useIntl().formatMessage({
        id: "app.drilldown.notCreated"
    });
    var mapped = useIntl().formatMessage({
        id: "app.drilldown.mapped"
    });
    var notMapped = useIntl().formatMessage({
        id: "app.drilldown.notMapped"
    });
    var completedPopoverText = useIntl().formatMessage({
        id: "app.courseCard.coursesLO"
    });
    var notStartedPopoverText = useIntl().formatMessage({
        id: "app.courseCard.coursesWithoutLO"
    });
    var learningOutcomes = useIntl().formatMessage({
        id: "app.orgProgressCard.learningOutcomes"
    });
    var iLOMapped = useIntl().formatMessage({
        id: "app.orgProgressCard.ILOMapped"
    });
    return (_jsxs(WmProgressMonitor, { id: "course-progress", "print-mode": true, "bar-labels-width": "5rem", children: [_jsxs(WmProgressIndicator, { label: learningOutcomes, children: [_jsx(WmProgressSlice, { legend: created, amount: courseData.courseLearningOutcomesComplete.toString(), popoverTitle: popover(courseData.courseLearningOutcomesComplete, courseData.totalCourses), popoverText: completedPopoverText }), _jsx(WmProgressSlice, { legend: notCreated, amount: courseData.courseLearningOutcomesNotStarted.toString(), popoverTitle: popover(courseData.courseLearningOutcomesNotStarted, courseData.totalCourses), popoverText: notStartedPopoverText })] }), _jsxs(WmProgressIndicator, { label: iLOMapped, children: [_jsx(WmProgressSlice, { legend: mapped, amount: courseData.courseIloComplete.toString(), popoverTitle: popover(courseData.courseIloComplete, courseData.totalCourses), popoverText: completedPopoverText }), _jsx(WmProgressSlice, { legend: notMapped, amount: courseData.courseIloNotStarted.toString(), popoverTitle: popover(courseData.courseIloNotStarted, courseData.totalCourses), popoverText: notStartedPopoverText })] })] }));
};
