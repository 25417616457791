import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmButton, WmToggletip } from "@watermarkinsights/ripple-react";
import translate from "src/i18n/translate";
import AssessmentProgressChart from "../AssessmentProgressChart";
import { useIntl } from "react-intl";
import { Fragment } from "react";
var RenderCourseAssessments = function (_a) {
    var assessmentProgressByCourse = _a.assessmentProgressByCourse, openFlyoutCourseDetails = _a.openFlyoutCourseDetails;
    if (Array.isArray(assessmentProgressByCourse) &&
        !assessmentProgressByCourse.length) {
        return _jsx(EmptyState, {});
    }
    else {
        return (_jsx("div", { id: "table-curriculum-engagement", className: "curriculum-engagement-table", children: _jsxs(Table, { children: [_jsx(TableHeader, { columns: [
                            translate("app.programCurriculumEngagement.studentSubmissionRate"),
                            translate("app.programCurriculumEngagement.facultyAssessmentRate")
                        ] }), _jsx(TableBody, { assessmentProgressByCourse: assessmentProgressByCourse, openFlyoutCourseDetails: openFlyoutCourseDetails })] }) }));
    }
};
var EmptyState = function () {
    return (_jsx("div", { className: "academic-yr-empty-state flex-row", children: _jsxs("div", { children: [_jsx("div", { className: "empty-state-title", children: translate("app.programCurriculumEngagement.EmptyState") }), _jsx("div", { className: "empty-state-desc", children: translate("app.programCurriculumEngagement.termEmptyStateDesc") })] }) }));
};
var Table = function (props) {
    return _jsx("table", { className: "data-table -shadow", children: props.children });
};
var TableBody = function (_a) {
    var assessmentProgressByCourse = _a.assessmentProgressByCourse, openFlyoutCourseDetails = _a.openFlyoutCourseDetails;
    return (_jsx("tbody", { className: "databody", children: assessmentProgressByCourse.map(function (courseAssessment, index) {
            var studentEnrolled = courseAssessment.assessmentProgress.studentEnrolled || 0;
            return (_jsxs(Fragment, { children: [_jsxs("tr", { className: "datarow", children: [_jsx("td", { className: "datacell", children: _jsx(CourseCell, { courseAssessment: courseAssessment, openFlyoutCourseDetails: openFlyoutCourseDetails }) }), _jsx("td", { className: "datacell submission-rate", children: _jsx("div", { className: "progress-chart", children: _jsx(RenderStudentSubmission, { courseAssessment: courseAssessment }) }) }), _jsx("td", { className: "datacell submission-rate", children: _jsx(RenderFacultyAssessment, { courseAssessment: courseAssessment }) })] }), _jsx("tr", { className: "small-screen-row", children: _jsxs("div", { children: [_jsxs("div", { className: "flex-row cell-items", children: [_jsx("div", { className: "course-label", children: translate("app.missionAndOutcomes.course") }), _jsx(CourseCell, { courseAssessment: courseAssessment, openFlyoutCourseDetails: openFlyoutCourseDetails })] }), _jsxs("div", { className: "flex-row cell-items", children: [_jsx("div", { className: "course-label", children: translate("app.programCurriculumEngagement.studentSubmissionRate") }), _jsx("div", { className: "progress-chart", children: _jsx(RenderStudentSubmission, { courseAssessment: courseAssessment }) })] }), _jsxs("div", { className: "flex-row cell-items", children: [_jsx("div", { className: "course-label", children: translate("app.programCurriculumEngagement.facultyAssessmentRate") }), _jsx("div", { className: "progress-chart", children: _jsx(RenderFacultyAssessment, { courseAssessment: courseAssessment }) })] })] }) })] }, "".concat(index, "-").concat(courseAssessment.uuid)));
        }) }));
};
export var TableHeader = function (props) {
    return (_jsx("thead", { id: "program-engagement-table-header", className: "dataheader", children: _jsxs("tr", { className: "dataheaderrow", children: [_jsx("th", { className: "sortable-table-header dataheadercell", "data-th": props.columns[0], children: _jsxs("div", { children: [_jsx("span", { children: translate("app.missionAndOutcomes.course") }), _jsx("div", { className: "arrow-position", children: _jsx("span", { className: "mdi mdi-arrow-down sort-arrow" }) })] }) }), props.columns.map(function (column, index) {
                    return (_jsx("th", { className: "dataheadercell", "data-th": column, children: column }, "column-".concat(index)));
                })] }) }));
};
var RenderStudentSubmission = function (_a) {
    var courseAssessment = _a.courseAssessment;
    var tooltipCompletedTextStudent = useIntl().formatMessage({
        id: "app.programCurriculumEngagement.tooltipCompletedTextStudent"
    });
    var tooltipNotCompletedTextStudent = useIntl().formatMessage({
        id: "app.programCurriculumEngagement.tooltipNotCompletedTextStudent"
    });
    var sectionCount = courseAssessment.sectionCount, assessmentProgress = courseAssessment.assessmentProgress;
    if (showSubmissionRateChart(sectionCount, assessmentProgress.studentEnrolled, assessmentProgress.submissionRate)) {
        return (_jsx(AssessmentProgressChart, { amount: Number(assessmentProgress.submissionRate), actualCount: Number(assessmentProgress.actualStudentSubmission), expectedCount: Number(assessmentProgress.expectedStudentSubmission), tooltipCompletedText: tooltipCompletedTextStudent, tooltipNotCompletedText: tooltipNotCompletedTextStudent }));
    }
    if (checkNoSubmissionAndAssessmentRate(sectionCount, assessmentProgress.studentEnrolled, assessmentProgress.submissionRate, assessmentProgress.assessmentRate)) {
        return (_jsx("div", { className: "no-data no-assesments", children: translate("app.programCurriculumEngagement.noAssesments") }));
    }
    return _jsx("div", { className: "no-data", children: translate("app.na") });
};
function CourseCell(_a) {
    var courseAssessment = _a.courseAssessment, openFlyoutCourseDetails = _a.openFlyoutCourseDetails;
    var studentEnrolled = courseAssessment.assessmentProgress.studentEnrolled || 0;
    return (_jsxs("div", { className: "course", children: [_jsxs(WmButton, { "button-type": "textonly", className: "courseName", onClick: function () { return openFlyoutCourseDetails(courseAssessment); }, disabled: courseAssessment.sectionCount === 0, children: [_jsx("span", { children: "".concat(courseAssessment.code, " ") }), _jsx("span", { children: courseAssessment.name })] }), _jsxs("div", { children: [_jsx("span", { children: "".concat(courseAssessment.sectionCount, " ") }), _jsx("span", { children: translate("app.programCurriculumEngagement.sections") })] }), _jsxs("div", { children: [_jsx("span", { children: "".concat(studentEnrolled, " ") }), _jsx("span", { children: translate("app.programCurriculumEngagement.studentsEnrolled") })] })] }));
}
var RenderFacultyAssessment = function (_a) {
    var courseAssessment = _a.courseAssessment;
    var tooltipCompletedTextFaculty = useIntl().formatMessage({
        id: "app.programCurriculumEngagement.tooltipCompletedTextFaculty"
    });
    var tooltipNotCompletedTextFaculty = useIntl().formatMessage({
        id: "app.programCurriculumEngagement.tooltipNotCompletedTextFaculty"
    });
    var toggeleTooltipText = useIntl().formatMessage({
        id: "app.programCurriculumEngagement.toggleTooltipText"
    });
    var sectionCount = courseAssessment.sectionCount, assessmentProgress = courseAssessment.assessmentProgress;
    if (showAssesmentRate(sectionCount, assessmentProgress.studentEnrolled, assessmentProgress.assessmentRate, assessmentProgress.submissionRate)) {
        return (_jsx(AssessmentProgressChart, { amount: Number(assessmentProgress.assessmentRate), actualCount: Number(assessmentProgress.actualCompletedAssessments), expectedCount: Number(assessmentProgress.expectedCompletedAssessments), tooltipCompletedText: tooltipCompletedTextFaculty, tooltipNotCompletedText: tooltipNotCompletedTextFaculty }));
    }
    if (checkNoSubmissionRate(sectionCount, assessmentProgress.studentEnrolled, assessmentProgress.submissionRate)) {
        return _jsx(_Fragment, {});
    }
    if (checkSubmissionRateZero(courseAssessment.sectionCount, courseAssessment.assessmentProgress.studentEnrolled, courseAssessment.assessmentProgress.submissionRate)) {
        return (_jsxs("div", { className: "no-data", children: [_jsx("span", { className: "no-data-toggletip", children: translate("app.na") }), _jsx(WmToggletip, { tooltip: toggeleTooltipText, "tooltip-position": "top", class: "toggletip" })] }));
    }
    return _jsx("div", { className: "no-data", children: translate("app.na") });
};
function showSubmissionRateChart(sectionCount, studentsEnrolled, submissionRate) {
    return sectionCount && studentsEnrolled && submissionRate !== null;
}
function checkNoSubmissionAndAssessmentRate(sectionCount, studentsEnrolled, submissionRate, assesmentRate) {
    return (sectionCount &&
        studentsEnrolled &&
        submissionRate === null &&
        assesmentRate === null);
}
function checkNoSubmissionRate(sectionCount, studentsEnrolled, submissionRate) {
    return sectionCount && studentsEnrolled && submissionRate === null;
}
function showAssesmentRate(sectionCount, studentsEnrolled, assesmentRate, submissionRate) {
    return (sectionCount &&
        studentsEnrolled &&
        assesmentRate !== null &&
        submissionRate !== 0);
}
function checkSubmissionRateZero(sectionCount, studentsEnrolled, submissionRate) {
    return sectionCount && studentsEnrolled && submissionRate === 0;
}
export default RenderCourseAssessments;
