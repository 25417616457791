const { REDIRECT_URI } = runConfig;

export const openFlyout = (flyoutId, focusAfterClosed, focusFirst) => {
  window.requestAnimationFrame(() => {
    let flyout = new wmrk.Flyout(flyoutId, focusAfterClosed, focusFirst);
  });
};

export const closeFlyout = () => {
  window.requestAnimationFrame(() => {
    let topFlyout = wmrk.getCurrentFlyout();

    if (typeof topFlyout != "undefined" && topFlyout != null) {
      topFlyout.close();
    }
  });
};

export const onScrollChangeHeader = (id) => {
  wmrk.onScrollChangeHeader();
};

export const onScrollChangeBreadCrumb = (navigatedFrom) => {
  wmrk.onScrollChangeBreadCrumb(navigatedFrom);
};

export const focusButtonComponent = (id) => {
  wmrk.Utils.focusButtonComponent(id);
};

export const focusActionMenuComponent = (id) => {
  wmrk.Utils.focusActionMenuComponent(id);
};

export const toggleNav = () => {
  wmrk.toggleNav();
};

export const toggleNavOrgMgt = () => {
  wmrk.toggleNavOrgMgt();
};

function handleAdministratorsKeyEvt(evt) {
  let nextId = wmrk.Utils.handleAssessmentLeadsKeyEvt(evt);
  if (nextId !== undefined && nextId !== "") {
    let nextIdElement = document.getElementById(nextId);
    nextIdElement.focus();
  }
}

export const handleAdministratorKeydown = () => {
  let searchInput;
  let container;
  let candidates;
  let tagContainer;

  window.requestAnimationFrame(() => {
    searchInput = document.getElementById("searchbox");
    container = document.getElementById("suggestedcandidates");
    candidates = container.querySelectorAll(".person");
    tagContainer = document.getElementById("non-editable-leads-tag");
    let tags = tagContainer.querySelectorAll(".tag");
    if (container) {
      container.addEventListener("mouseout", unfocus);
      wmrk.Utils.forEach(candidates, function (i, val) {
        candidates[i].addEventListener(
          "keydown",
          handleAdministratorsKeyEvt,
          true
        );
      });
    }

    if (searchInput) {
      searchInput.removeEventListener(
        "keydown",
        handleAdministratorsKeyEvt,
        true
      );
      searchInput.addEventListener("keydown", handleAdministratorsKeyEvt, true);

      container.addEventListener("mouseout", unfocus);
    } else {
      wmrk.Utils.handleKeyDownWithMaxAssessmentLeads();
    }
  });

  function unfocus() {
    container.removeAttribute("aria-activedescendant");
  }
};

export const deleteAssigneeTag = (tagId, keyCode) => {
  let tag = document.getElementById(tagId);
  if (keyCode == "Backspace") {
    tag?.click();
  }
};

window.wmrk.toggleHierarchySelect = function () {
  if (window.wmrk.hierarchyDD) {
    return;
  } else {
    let hierarchySelectLink = document.getElementById("oa-menu");
    let hierarchyListbox = new aria.Listbox(
      document.getElementById("oa-menu-listbox")
    );
    let hierarchyListboxButton = new aria.ListboxButton(
      hierarchySelectLink,
      hierarchyListbox
    );
    window.wmrk.hierarchyDD = hierarchyListbox;
    window.wmrk.hierarchyDD.listboxNode.setAttribute("aria-expanded", "true");
    hierarchyListboxButton.showListbox();
  }
};

window.wmrk.toggleAccountMenu = function () {
  if (window.wmrk.accountDD) {
    setTimeout(function () {
      window.wmrk.accountDD.popupMenu.setFocusToFirstItem();
    }, 100);
    return;
  } else {
    let accountSelectLink = document.getElementById("account-select-link");
    window.wmrk.accountDD = new Menubutton(accountSelectLink);
    window.wmrk.accountDD.init();
    window.wmrk.accountDD.popupMenu.open();
    window.wmrk.accountDD.domNode.setAttribute("aria-expanded", "true");
    setTimeout(function () {
      window.wmrk.accountDD.popupMenu.setFocusToFirstItem();
    }, 100);
  }
};

window.wmrk.goToProfile = function () {
  window.location = REDIRECT_URI + "/profile";
};

window.wmrk.switchHierarchy = function (
  current_institution_platform_uuid,
  institution_platform_uuid,
  institution_uuid
) {
  if (current_institution_platform_uuid !== institution_platform_uuid)
    window.location = REDIRECT_URI + "/switch_hierarchy/" + institution_uuid;
};

window.wmrk.logout = function () {
  window.location = REDIRECT_URI + "/logout";
};
window.wmrk.goToLocation = function (uri) {
  window.location = uri;
};
export const formatNumber = (value) => {
  let number = Number.parseInt(value);
  if (Number.isNaN(number)) return "0";

  return number.toLocaleString("en-US");
};
export const getPercentage = (value, total) => {
  if (total === 0) {
    return 0;
  } else {
    return Math.round((Number(value) / total) * 100);
  }
};
