import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "../common/elements/Header.scss";
import { toggleNavOrgMgt } from "../../static/js/commons";
import translate from "src/i18n/translate";
import { Link } from "react-router-dom";
import { scrollToTop } from "../common/helper";
import { useContext } from "react";
import { AppContext } from "src/contexts/AppContext";
export default function NavigatorSideNav(_a) {
    var collapseSideNav = _a.collapseSideNav, hideNavigatorSideNav = _a.hideNavigatorSideNav;
    var hide_sideNav_class = function (collapseSideNav) {
        return hideNavigatorSideNav || !collapseSideNav ? "hamburger-hide" : "";
    };
    var toggle_nav = function (collapseSideNav) {
        return toggleNavOrgMgt;
    };
    var urlPath = window.location.pathname;
    var queryString = window.location.search;
    var institution = useContext(AppContext).institution;
    function removeOverlay() {
        var _a;
        var nav = document.getElementById("mainnav");
        var expanded = nav === null || nav === void 0 ? void 0 : nav.classList.contains("expanded");
        if (expanded && (institution === null || institution === void 0 ? void 0 : institution.navigatedFrom) === "navigator") {
            toggleNavOrgMgt();
        }
        scrollToTop(".home-page-header");
        scrollToTop("#page-header");
        var overlay = document.getElementById("navoverlay");
        if (overlay) {
            (_a = overlay.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(overlay);
        }
    }
    return (_jsxs("nav", { id: "mainnav", className: "main-nav main-nvs items-9 ".concat(hide_sideNav_class(collapseSideNav)), children: [_jsx("div", { id: "nav-start-div", className: "navwrapper" }), _jsxs("button", { id: "nav-toggle", className: "icononly show-nav-toggle", onClick: toggle_nav(collapseSideNav), children: [_jsx("span", { className: "sr-only tooltip -bottom -multiline", children: translate("app.hideNavigation") }), _jsx("span", { className: "hamburger-inner" })] }), _jsxs("ul", { id: "navmenu", className: "menu  org-profile-menu", children: [_jsx("li", { className: urlPath.includes("home") ? "menuitem active-parent" : "menuitem", children: _jsxs(Link, { to: queryString.includes("locale")
                                ? "/insights/home/".concat(queryString)
                                : "/insights/home", id: "nav-home", className: urlPath.includes("home")
                                ? "navlink nav-links active home-nav-link"
                                : "navlink nav-links home-nav-link", onClick: removeOverlay, children: [_jsx("span", { className: "icon pe-va pe-fw pe-7s-home" }), _jsx("span", { id: "hometext", children: translate("app.mainnav.home") })] }) }), _jsx("li", { className: urlPath.includes("academic") ? "menuitem active-parent" : "menuitem", children: _jsxs(Link, { to: queryString.includes("locale")
                                ? "/insights/academic_office/".concat(queryString)
                                : "/insights/academic_office", id: "org-link", className: urlPath.includes("academic")
                                ? "navlink nav-links active home-nav-link"
                                : "navlink nav-links home-nav-link", onClick: removeOverlay, children: [_jsx("span", { className: "icon pe-va pe-fw pe-7s-notebook" }), _jsx("span", { id: "orgtext", children: translate("app.mainnav.academicsAndOffices") })] }) }), _jsx("li", { className: urlPath.includes("settings") ? "menuitem active-parent" : "menuitem", children: _jsxs(Link, { to: queryString.includes("locale")
                                ? "/insights/settings/".concat(queryString)
                                : "/insights/settings", id: "settings-link", className: urlPath.includes("settings")
                                ? "navlink nav-links active home-nav-link"
                                : "navlink nav-links home-nav-link", onClick: removeOverlay, children: [_jsx("span", { className: "icon pe-va pe-fw pe-7s-config" }), _jsx("span", { id: "plantext", children: translate("app.home.settings") })] }) })] })] }));
}
