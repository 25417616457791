import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmOption, WmSelect } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import { formatDate } from "../common/helper";
var Terms = function (_a) {
    var terms = _a.terms, selectedTerm = _a.selectedTerm, selectTerm = _a.selectTerm;
    var termLabel = useIntl().formatMessage({
        id: "app.sse.termLabel"
    });
    return (_jsxs("div", { className: "reporting-year-width", children: [_jsx(WmSelect, { label: termLabel, labelPosition: "left", id: "select-terms", children: terms.map(function (term) {
                    return (_jsx(WmOption, { id: "terms-".concat(term.uuid), selected: (selectedTerm === null || selectedTerm === void 0 ? void 0 : selectedTerm.uuid) === term.uuid, onClick: function () { return selectTerm(term); }, children: term.name }, "terms-".concat(term.uuid)));
                }) }), selectedTerm && (_jsx("div", { className: "reporting-year-duration", children: "".concat(formatDate(selectedTerm === null || selectedTerm === void 0 ? void 0 : selectedTerm.startDate), " - ").concat(formatDate(selectedTerm === null || selectedTerm === void 0 ? void 0 : selectedTerm.endDate)) }))] }));
};
export default Terms;
