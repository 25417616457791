var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReducer } from "react";
import OrganizationProgressCard from "./OrganizationProgressCard";
import ProgramProgressCard from "./ProgramProgressCard";
import CourseProgressCard from "./CourseProgressCard";
import TabComponent from "../../common/tabComponent/TabComponent";
import { WmButton, WmTabPanel } from "@watermarkinsights/ripple-react";
import "../../common/Insights.scss";
import translate from "../../../i18n/translate";
import WidgetLoader from "../../common/elements/WidgetLoader";
var allTabsLoaded = function (loadedTabs) {
    return loadedTabs.organizations && loadedTabs.programs && loadedTabs.courses;
};
var initialState = {
    selectedTab: "item-1",
    loadedTabs: {
        organizations: false,
        programs: false,
        courses: false
    }
};
var reducer = function (state, action) {
    switch (action.type) {
        case "SetSelectedTab":
            return __assign(__assign({}, state), { selectedTab: action.tabId });
        case "setLoadedTab":
            var tab = action.tab;
            var loadedTabs = state.loadedTabs;
            loadedTabs[tab] = true;
            return __assign(__assign({}, state), { loadedTabs: loadedTabs });
        default:
            return state;
    }
};
var getURL = function () {
    var queryString = window.location.search;
    var param = new URLSearchParams(queryString);
    var locale = param.get("locale");
    var url = "/insights/institution-strategy/html";
    if (locale !== null) {
        url = "".concat(url, "?locale=").concat(locale);
    }
    return url;
};
var openMissionAndOutcomesHTMLViewLink = function () {
    return window.open(getURL(), "_blank");
};
export default function MissionAndOutcomes(_a) {
    var onComponentLoad = _a.onComponentLoad;
    var tabOption = [
        {
            name: translate("app.missionAndOutcomes.organizations"),
            id: "tab1"
        },
        {
            name: translate("app.missionAndOutcomes.programs"),
            id: "tab2"
        },
        {
            name: translate("app.missionAndOutcomes.courses"),
            id: "tab3"
        }
    ];
    var defaultAction = { type: "", tabId: "", tab: "" };
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var onWmTabSelected = function (event) {
        dispatch(__assign(__assign({}, defaultAction), { type: "SetSelectedTab", tabId: event.detail.tabId }));
    };
    var onTabLoad = function (tab) {
        dispatch(__assign(__assign({}, defaultAction), { type: "setLoadedTab", tab: tab }));
    };
    return (_jsxs("div", { className: "paper-card -withtabs paper-card-height", children: [_jsxs("div", { className: "header", children: [_jsxs("div", { className: "info", children: [_jsx("h3", { className: "title", children: translate("app.missionAndOutcomes.title") }), _jsx("p", { className: "description", children: translate("app.missionAndOutcomes.description") })] }), _jsx("div", { children: _jsx(WmButton, { id: "export-mission-and-outcomes", "aria-controls": "acc-listbox", "aria-expanded": "false", tabIndex: 0, onClick: openMissionAndOutcomesHTMLViewLink, "button-type": "secondary", children: translate("app.exportHtml") }) })] }), _jsxs("div", { className: "body", id: "tab-component", children: [!allTabsLoaded(state.loadedTabs) && (_jsx("div", { className: "loader-position", children: _jsx(WidgetLoader, { loaderContainerClasses: "loader-container", loaderClasses: "widget-loader" }) })), allTabsLoaded(state.loadedTabs) && (_jsx("div", { className: "tabbar", children: _jsx(TabComponent, { selectedTab: state.selectedTab, onWmTabSelected: onWmTabSelected, tabOption: tabOption }) })), _jsx(WmTabPanel, { className: "tab-panel", id: "tab-one", tabId: "item-1", children: _jsx(OrganizationProgressCard, { onTabLoad: onTabLoad, onComponentLoad: onComponentLoad }) }), _jsx(WmTabPanel, { className: "tab-panel", id: "tab-two", tabId: "item-2", children: _jsx(ProgramProgressCard, { onTabLoad: onTabLoad, onComponentLoad: onComponentLoad }) }), _jsx(WmTabPanel, { className: "tab-panel", id: "tab-three", tabId: "item-3", children: _jsx(CourseProgressCard, { onTabLoad: onTabLoad, onComponentLoad: onComponentLoad }) })] })] }));
}
