var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import translate from "../../../i18n/translate";
import { useEffect, useReducer } from "react";
import { downloadPDF } from "../../common/helper";
import { sendRequest, sendRequestWithPayload } from "../../../common/http";
import useIntl from "react-intl/src/components/useIntl";
var defaultAction = {
    type: "",
    token: "",
    data: false
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setToken":
            return __assign(__assign({}, state), { token: action.token });
        case "setExportInProgress":
            return __assign(__assign({}, state), { isExportInProgress: action.data });
        default:
            return state;
    }
};
var initialState = {
    token: null,
    isExportInProgress: false
};
export default function Header(_a) {
    var reportingYear = _a.reportingYear;
    var header = useIntl().formatMessage({
        id: "app.sll.learningAssessment"
    });
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    function exportToPdf() {
        var sllHtml = document.getElementById("sll-html");
        var downloadText = document.querySelector(".download-text");
        if (downloadText) {
            downloadText.setAttribute("tabindex", "0");
            downloadText.focus();
        }
        if (sllHtml) {
            var clone = sllHtml.cloneNode(true);
            var headerSection = clone.firstChild;
            headerSection && clone.removeChild(headerSection);
            var wrapperMain = document.createElement("main");
            wrapperMain.classList.add("main-container");
            wrapperMain.appendChild(clone);
            var wrapperDiv = document.createElement("div");
            wrapperDiv.appendChild(wrapperMain);
            var formData = new FormData();
            formData.append("html", wrapperDiv.outerHTML);
            formData.append("sub_header", reportingYear);
            formData.append("header", header);
            formData.append("_csrf_token", state.token ? state.token : "");
            var applyResult = function (result) {
                downloadPDF(result.pdf_file_bytes, "Learning_Assessments_Insights");
                dispatch(__assign(__assign({}, defaultAction), { type: "setToken", token: null }));
                dispatch(__assign(__assign({}, defaultAction), { type: "setExportInProgress", data: false }));
            };
            var onError = function () {
                dispatch(__assign(__assign({}, defaultAction), { type: "setToken", token: null }));
                dispatch(__assign(__assign({}, defaultAction), { type: "setExportInProgress", data: false }));
            };
            sendRequestWithPayload({
                uri: "/export",
                body: formData
            }, applyResult, onError);
        }
    }
    useEffect(function () {
        if (state.isExportInProgress) {
            sendRequest({
                uri: "/export/token",
                camelize: true
            }, function (result) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setToken", token: result.token }));
            });
        }
    }, [state.isExportInProgress]);
    useEffect(function () {
        if (state.token && state.isExportInProgress) {
            exportToPdf();
        }
    }, [state.isExportInProgress, state.token]);
    return (_jsx(_Fragment, { children: _jsx("section", { className: "page-banner-container -mainheader -withbreadcrumbs only-breadcrumbs header-padding", id: "page-header", children: _jsxs("div", { className: "flex-row export-container", children: [_jsxs("div", { children: [_jsx("div", { children: _jsx("span", { className: "org-name uppercase", children: translate("app.sll.learningAssessment") }) }), _jsx("div", { className: "identity-details", children: _jsx("div", { className: "page-header -titleonly", children: _jsx("h2", { className: "plan-title-header", children: reportingYear }) }) })] }), _jsxs("div", { className: "flex-row export-button", children: [state.isExportInProgress && (_jsx("span", { className: "download-text", children: translate("app.planParticipation.exportPdf.downloadReportText") })), _jsx("div", { className: "button-container", children: _jsx(WmButton, { "button-type": "secondary", icon: "f1da", "icon-size": "20px", onClick: function () {
                                        return dispatch(__assign(__assign({}, defaultAction), { type: "setExportInProgress", data: true }));
                                    }, disabled: state.isExportInProgress, children: translate("app.downloadHtml") }) })] })] }) }) }));
}
