import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function render() {
    var App = require("../src/app/App").default;
    var container = document.getElementById("root");
    var root = createRoot(container);
    root.render(_jsx(CookiesProvider, { children: _jsx(App, {}) }));
}
render();
if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("../src/app/App", render);
}
reportWebVitals();
