var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./Header.scss";
import { WmTabPanel } from "@watermarkinsights/ripple-react";
import Lightcast from "src/features/lightcast/Lightcast";
import ContinuousImprovement from "src/features/continuous-improvement/ContinuousImprovement";
import Strategy from "src/features/strategy/Strategy";
import InsightsTabComponent from "../tabComponent/InsightsTabComponent";
import { useIntl } from "react-intl";
import { useReducer, useEffect } from "react";
import Sse from "src/features/sse/Sse";
import FacultySuccess from "src/features/faculty-success/FacultySuccess";
import { sendRequest } from "src/common/http";
import { getDefaultReportingYear } from "./ReportingYears";
import { defaultReportingYear } from "../interfaces/Interface";
import { useHistory } from "react-router";
import { useContext } from "react";
import { AppContext } from "src/contexts/AppContext";
import { checkIfFsProductIntegrated, checkIfCsProductIntegrated, checkIfSseProductIntegrated, checkIfSllProductIntegrated } from "../helper";
var defaultInitialTabOption = {
    name: "",
    id: ""
};
var defaultAction = {
    type: "",
    strategyTabOption: defaultInitialTabOption,
    laborMarketTabOption: defaultInitialTabOption,
    studentsTabOption: defaultInitialTabOption,
    facultyTabOption: defaultInitialTabOption,
    continuousImprovementTabOption: defaultInitialTabOption,
    selectedReportingYearUuid: "",
    data: [],
    ryApiError: false
};
var reducer = function (state, action) {
    var tabOptions = state.tabOptions;
    switch (action.type) {
        case "addLightcastTab":
            tabOptions.splice(4, 0, action.laborMarketTabOption);
            return __assign(__assign({}, state), { tabOptions: tabOptions });
        case "addSseTab":
            var faculty = tabOptions.find(function (option) {
                return option.id == "faculty-insights";
            });
            if (faculty) {
                tabOptions.splice(3, 0, action.studentsTabOption);
            }
            else {
                tabOptions.splice(2, 0, action.studentsTabOption);
            }
            return __assign(__assign({}, state), { tabOptions: tabOptions });
        case "addFacultyTab":
            tabOptions.splice(2, 0, action.facultyTabOption);
            return __assign(__assign({}, state), { tabOptions: tabOptions });
        case "addContinuousImprovementTab":
            var ciTab = tabOptions.find(function (option) {
                return option.id == "continuous-improvement-insights";
            });
            ciTab
                ? tabOptions
                : tabOptions.splice(1, 0, action.continuousImprovementTabOption);
            return __assign(__assign({}, state), { tabOptions: tabOptions });
        case "addStrategyTab":
            var strategyTab = tabOptions.find(function (option) {
                return option.id == "institution-strategy";
            });
            strategyTab
                ? tabOptions
                : tabOptions.splice(0, 0, action.strategyTabOption);
            return __assign(__assign({}, state), { tabOptions: tabOptions });
        case "setReportingYears":
            var defaultReportingYear_1 = getReportingYear(action.selectedReportingYearUuid, action.data) ||
                getDefaultReportingYear(state.defaultReportingYear, action.data);
            return __assign(__assign({}, state), { defaultReportingYear: defaultReportingYear_1, reportingYears: action.data });
        case "setRyApiError":
            return __assign(__assign({}, state), { ryApiError: action.ryApiError });
        default:
            return state;
    }
};
var getReportingYear = function (selectedReportingYearUuid, reportingYears) {
    if (selectedReportingYearUuid === undefined ||
        selectedReportingYearUuid === null) {
        return undefined;
    }
    else {
        var reportingYear = reportingYears.filter(function (reportingYear) {
            return reportingYear.uuid === selectedReportingYearUuid;
        });
        return reportingYear[0];
    }
};
var orderedTabIds = [
    "institution-strategy",
    "continuous-improvement-insights",
    "faculty-insights",
    "labor-market-insights",
    "students-insights"
];
var showStrategyTab = function (institution, integratedProducts) {
    return (institution.isPssIntegrated ||
        checkIfCsProductIntegrated(integratedProducts));
};
var showContinuousImprovementTab = function (institution, integratedProducts) {
    return ((institution === null || institution === void 0 ? void 0 : institution.isPssIntegrated) ||
        (institution === null || institution === void 0 ? void 0 : institution.showCourseEvaluation) ||
        checkIfSllProductIntegrated(integratedProducts));
};
export default function OrgProfileContent(_a) {
    var setSelectedTabView = _a.setSelectedTabView;
    var _b = useContext(AppContext), institution = _b.institution, selectedTab = _b.selectedTab, onWmTabSelectedEvent = _b.onWmTabSelectedEvent, integratedProducts = _b.integratedProducts;
    var history = useHistory();
    var queryString = window.location.search;
    var param = new URLSearchParams(queryString);
    var selectedReportingYearUuid = param.get("reporting_year_uuid");
    var strategyLabel = useIntl().formatMessage({
        id: "app.orgProfileContent.strategy"
    });
    var laborMarketLabel = useIntl().formatMessage({
        id: "app.orgProfileContent.laborMarket"
    });
    var sseLabel = useIntl().formatMessage({
        id: "app.orgProfileContent.sseLabel"
    });
    var continuousImprovementLabel = useIntl().formatMessage({
        id: "app.orgProfileContent.countinousImprovement"
    });
    var facultyLabel = useIntl().formatMessage({
        id: "app.orgProfileContent.faculty"
    });
    var initialTabOptions = [];
    var initialState = {
        tabOptions: initialTabOptions,
        reportingYears: null,
        defaultReportingYear: defaultReportingYear,
        ryApiError: false
    };
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setReportingYears", data: result, selectedReportingYearUuid: selectedReportingYearUuid }));
        };
        var onError = function () {
            dispatch(__assign(__assign({}, defaultAction), { type: "setRyApiError", ryApiError: true }));
        };
        sendRequest({ uri: "/reporting_years", camelize: true }, applyResult, onError);
    }, []);
    useEffect(function () {
        var tabParam = new URLSearchParams(window.location.search).get("tab");
        if (state.tabOptions.length > 0 && setSelectedTabView && !tabParam) {
            var firstTabId = orderedTabIds.find(function (tabId) {
                return state.tabOptions.some(function (option) { return option.id === tabId; });
            });
            if (firstTabId)
                setSelectedTabView(firstTabId);
        }
    }, [state.tabOptions.length]);
    useEffect(function () {
        if (institution && integratedProducts && integratedProducts.length > 0) {
            if (showStrategyTab(institution, integratedProducts)) {
                setStrategyTab();
            }
            if (showContinuousImprovementTab(institution, integratedProducts)) {
                setContinuousImprovementTab();
            }
            if (checkIfFsProductIntegrated(integratedProducts)) {
                setFacultyTab();
            }
            if (checkIfSseProductIntegrated(integratedProducts)) {
                setSseTab();
            }
        }
    }, [integratedProducts]);
    var setLightcastTab = function () {
        dispatch(__assign(__assign({}, defaultAction), { type: "addLightcastTab", laborMarketTabOption: {
                name: laborMarketLabel,
                id: "labor-market-insights"
            } }));
    };
    var setStrategyTab = function () {
        dispatch(__assign(__assign({}, defaultAction), { type: "addStrategyTab", strategyTabOption: {
                name: strategyLabel,
                id: "institution-strategy"
            } }));
    };
    var setSseTab = function () {
        dispatch(__assign(__assign({}, defaultAction), { type: "addSseTab", studentsTabOption: {
                name: sseLabel,
                id: "students-insights"
            } }));
    };
    var setFacultyTab = function () {
        dispatch(__assign(__assign({}, defaultAction), { type: "addFacultyTab", facultyTabOption: {
                name: facultyLabel,
                id: "faculty-insights"
            } }));
    };
    var setContinuousImprovementTab = function () {
        dispatch(__assign(__assign({}, defaultAction), { type: "addContinuousImprovementTab", continuousImprovementTabOption: {
                name: continuousImprovementLabel,
                id: "continuous-improvement-insights"
            } }));
    };
    var onWmTabSelect = function (event) {
        var params = new URLSearchParams();
        params.append("tab", event.detail.tabId);
        var queryString = window.location.search;
        var param = new URLSearchParams(queryString);
        var locale = param.get("locale");
        if (locale !== null) {
            params.append("locale", locale);
        }
        history.replace({
            search: params.toString()
        });
        onWmTabSelectedEvent(event);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "tabbar -tabbar", children: _jsx(InsightsTabComponent, { selectedTab: selectedTab, onWmTabSelected: onWmTabSelect, tabOption: state.tabOptions }) }), _jsx(WmTabPanel, { className: "tab-panel", id: "strategy", tabId: "institution-strategy", children: institution &&
                    integratedProducts &&
                    showStrategyTab(institution, integratedProducts) && (_jsx(Strategy, { reportingYears: state.reportingYears, defaultReportingYear: state.defaultReportingYear, ryApiError: state.ryApiError, setStrategyTab: setStrategyTab })) }), _jsx(WmTabPanel, { className: "tab-panel", id: "continuous-improvement", tabId: "continuous-improvement-insights", children: institution &&
                    integratedProducts &&
                    showContinuousImprovementTab(institution, integratedProducts) && (_jsx(ContinuousImprovement, { reportingYears: state.reportingYears, defaultReportingYear: state.defaultReportingYear, ryApiError: state.ryApiError })) }), _jsx(WmTabPanel, { className: "tab-panel", id: "faculty", tabId: "faculty-insights", children: checkIfFsProductIntegrated(integratedProducts) && (_jsx(FacultySuccess, { reportingYears: state.reportingYears, defaultReportingYear: state.defaultReportingYear, ryApiError: state.ryApiError })) }), _jsx(WmTabPanel, { className: "tab-panel", id: "students", tabId: "students-insights", children: checkIfSseProductIntegrated(integratedProducts) && _jsx(Sse, {}) }), (institution === null || institution === void 0 ? void 0 : institution.isPssIntegrated) && (_jsx(WmTabPanel, { className: "tab-panel", id: "labor-market", tabId: "labor-market-insights", children: _jsx(Lightcast, { setLightcastTab: setLightcastTab }) }))] }));
}
