import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import translate from "../../i18n/translate";
import { closeFlyout } from "../../static/js/commons";
import { formatDateFullYear } from "../common/helper";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
export default function OpenDrillDownViewResponseRate(_a) {
    var setIsDrilldownOpen = _a.setIsDrilldownOpen, title = _a.title, remainingDays = _a.remainingDays, startDate = _a.startDate, endDate = _a.endDate, status = _a.status, showTable = _a.showTable, completeHierarchy = _a.completeHierarchy;
    var CloseDrillDownView = function () {
        setIsDrilldownOpen(false);
        closeFlyout();
    };
    var closeTooltip = useIntl().formatMessage({
        id: "app.ces.responseRate.drilldownTooltipClose"
    });
    return (_jsxs("div", { className: "flyout-panel half-flyout show", role: "dialog", id: "drill-down-view", tabIndex: -1, "aria-label": "Drill down view", children: [_jsx("div", { className: "overlay" }), _jsxs("div", { className: "container-box", children: [_jsx("div", { className: "paper-card", children: _jsxs("div", { className: "overlay-content", children: [_jsx(WmButton, { buttonType: "navigational", icon: "f156", tooltip: closeTooltip, tooltipPosition: "bottom", id: "drill-down-close-btn", class: "hydrated", onClick: CloseDrillDownView }), _jsxs("div", { className: "helper-text", children: [_jsx("h2", { className: "title", children: translate("app.ces.responseRate.drilldownHeading") }), _jsx("div", { className: "description", children: translate("app.ces.responseRate.drilldownSubHeading") })] })] }) }), _jsxs("section", { className: "body", children: [_jsxs("div", { className: "flex-row -justified plan-sub-header-container", children: [_jsx("div", { className: "plan-title-drilldown", children: title }), _jsx(CourseEvaluationStatus, { status: status })] }), _jsxs("div", { className: "ces-duration ces-duration-drilldown", children: [_jsxs("div", { className: "ces-dates", children: [_jsx("span", { className: "mdi mdi-calendar-clock" }), "".concat(formatDateFullYear(startDate), " -\n                    ").concat(formatDateFullYear(endDate))] }), remainingDays > 0 && (_jsxs("div", { className: "ces-days ces-days-drildown", children: [_jsx("div", { className: "mdi mdi-timer-sand" }), translate("app.ces.responseRate.daysRemaining", {
                                                daysRemaining: remainingDays
                                            })] }))] }), showTable && completeHierarchy ? (_jsx(RenderHierarchyTable, { completeHierarchy: completeHierarchy })) : (_jsx("div", { className: "loader -halfscreen", children: _jsx("div", { className: "content content-loader", children: translate("app.loading") }) }))] })] })] }));
}
var CourseEvaluationStatus = function (_a) {
    var status = _a.status;
    switch (status) {
        case 3:
            return (_jsx("div", { className: "in-progress-status drilldown-status", children: _jsx("div", { className: "title-text", children: translate("app.drilldown.inProgress") }) }));
        case 4:
            return (_jsx("div", { className: "ended-status -completed drilldown-status", children: _jsx("div", { className: "title-text", children: translate("app.drilldown.ended") }) }));
        default:
            return _jsx(_Fragment, {});
    }
};
var getDrilldownColumns = function () {
    return [
        translate("app.drilldown.organizationAndPrograms"),
        translate("app.drilldown.enrollments"),
        translate("app.drilldown.responseRate")
    ];
};
var Table = function (props) {
    return _jsx("table", { className: "data-table -shadow", children: props.children });
};
var TableHeader = function (props) {
    return (_jsx("thead", { className: "dataheader", children: _jsx("tr", { className: "dataheaderrow", children: props.columns.map(function (column, index) {
                return (_jsx("th", { className: "dataheadercell", "data-th": column, children: column }, "column-".concat(index)));
            }) }) }));
};
export var RenderHierarchyTable = function (_a) {
    var completeHierarchy = _a.completeHierarchy;
    return (_jsx("div", { className: "org-table response-rate-table", children: _jsxs(Table, { children: [_jsx(TableHeader, { columns: getDrilldownColumns() }), _jsx(TableBody, { children: Array.isArray(completeHierarchy) ? (completeHierarchy.map(function (node) {
                        return _jsx(RenderHierarchy, { node: node });
                    })) : (_jsx(RenderHierarchy, { node: completeHierarchy })) })] }) }));
};
var RenderHierarchy = function (_a) {
    var node = _a.node;
    return (_jsxs(React.Fragment, { children: [_jsx(Node, { node: node }), node.children.map(function (child) {
                return (_jsx(RenderHierarchy, { node: child }, "".concat(child.uuid, "-hierarchy-child")));
            })] }, "".concat(node.uuid, "-hierarchy")));
};
var TableBody = function (props) {
    return _jsx("tbody", { className: "databody", children: props.children });
};
var Node = function (props) {
    var node = props.node;
    return _jsx(CompleteRow, { node: node });
};
var CompleteRow = function (props) {
    var node = props.node;
    return (_jsxs("tr", { className: "datarow", children: [_jsx("td", { className: "datacell level-".concat(node.level), children: node.name }), _jsx("td", { className: "datacell", children: node.enrollments }), _jsx("td", { className: "datacell", children: translate("app.drilldown.responseRatePercentage", {
                    responseRatePercentage: node.rate
                }) })] }, "".concat(node.uuid, "-datarow")));
};
