var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import translate from "src/i18n/translate";
import React, { Fragment, useReducer } from "react";
import { WmButton } from "@watermarkinsights/ripple-react";
import { openFlyout } from "../../../static/js/commons";
import AllProgramsFlyout from "./AllProgramsFlyout";
var topProgramsTableDefaultAction = {
    type: "",
    isDrilldownOpen: false
};
var initialState = {
    isDrilldownOpen: false
};
var reducer = function (state, action) {
    switch (action.type) {
        case "ToggleDrilldown":
            return __assign(__assign({}, state), { isDrilldownOpen: !state.isDrilldownOpen });
        default:
            return state;
    }
};
export default function TopProgramsTable(_a) {
    var programDetails = _a.programDetails;
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var setIsDrilldownOpen = function () {
        dispatch(__assign(__assign({}, topProgramsTableDefaultAction), { type: "ToggleDrilldown" }));
    };
    var openDrilldownView = function () {
        setTimeout(function () {
            return openFlyout("drill-down-view", "course-charts", "all-programs-close-btn");
        }, 400);
        setIsDrilldownOpen();
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { id: "org-table-catalog", className: programDetails.length <= 10 ? "catalog-table" : "", children: _jsxs(Table, { children: [_jsx(TableHeader, { columns: [
                                translate("app.strategy.topProgramsByCatalog.program"),
                                translate("app.strategy.topProgramsByCatalog.pageViews")
                            ] }), _jsx(TableBody, { programDetails: programDetails })] }) }), _jsx("div", { className: "view-more", children: programDetails.length > 10 && (_jsx(WmButton, { buttonType: "textonly", onClick: function () { return openDrilldownView(); }, children: _jsx("span", { className: "read-more", children: translate("app.strategy.topProgramsByCatalog.viewMore") }) })) }), state.isDrilldownOpen ? (_jsxs(Fragment, { children: [_jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-top", tabIndex: 0 }), _jsx(AllProgramsFlyout, { setIsDrilldownOpen: setIsDrilldownOpen, programDetails: programDetails }), _jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-top", tabIndex: 0 })] })) : (""), _jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-bottom", tabIndex: 0 })] }));
}
var TableBody = function (_a) {
    var programDetails = _a.programDetails;
    return (_jsx("tbody", { className: "databody", children: programDetails
            .slice(0, 10)
            .map(function (programDetail, index) {
            return (_jsxs("tr", { className: "datarow", children: [_jsx("td", { className: "datacell", children: _jsx("div", { children: programDetail.programName }) }), _jsx("td", { className: "datacell", children: programDetail.pageViews })] }, "".concat(index, "-").concat(programDetail.programName)));
        }) }));
};
var Table = function (props) {
    return _jsx("table", { className: "data-table -shadow", children: props.children });
};
export var TableHeader = function (props) {
    return (_jsx("thead", { className: "dataheader", children: _jsx("tr", { className: "dataheaderrow", children: props.columns.map(function (column, index) {
                return (_jsx("th", { className: "dataheadercell", "data-th": column, children: column }, "column-".concat(index)));
            }) }) }));
};
