import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import translate from "src/i18n/translate";
import WidgetLoader from "../common/elements/WidgetLoader";
export var defaultParticipationOverview = {
    assessmentCount: 0,
    courseCount: 0,
    instructorCount: 0,
    studentCount: 0
};
var StudentLearningAssesment = function (_a) {
    var participationOverview = _a.participationOverview, showLoader = _a.showLoader;
    return (_jsxs("div", { className: "wm-grid-row student-learning-assesment", children: [_jsx("span", { className: "sr-only faculty-loader-status", role: "status", "aria-live": "assertive" }), _jsx("div", { className: "grid-container wm-grid-col-12 wm-grid-col-md-10", children: _jsxs("div", { className: "paper-card paper-card-height", children: [_jsx("div", { className: "header", children: _jsxs("div", { className: "info", children: [_jsx("h3", { className: "title", children: translate("app.studentLearningAssesment.widgetLabel") }), _jsx("p", { className: "description", children: translate("app.studentLearningAssesment.widgetDescription") })] }) }), _jsx("div", { children: showLoader ? (_jsx(WidgetLoader, { loaderContainerClasses: "loader", loaderClasses: "content" })) : (_jsx(RenderParticipationOverview, { participationOverview: participationOverview })) })] }) })] }));
};
var RenderParticipationOverview = function (_a) {
    var participationOverview = _a.participationOverview;
    if (participationOverview.studentCount === 0) {
        return _jsx(EmptyState, {});
    }
    else {
        return (_jsx(ParticipationOverviewDetails, { participationOverview: participationOverview }));
    }
};
var ParticipationOverviewDetails = function (_a) {
    var participationOverview = _a.participationOverview;
    return (_jsxs("div", { className: "body student-learning-assesment-content", children: [_jsxs("div", { className: " -detail participation", children: [_jsx("div", { className: "detail-label", children: translate("app.studentLearningAssessment.participation") }), _jsxs("div", { className: "-detail-value", children: [_jsx("div", { className: "assesment-count", children: participationOverview.studentCount }), _jsx("div", { className: "assesment-label", children: translate("app.studentLearningAssessment.student") })] })] }), _jsxs("div", { className: "-detail course-assessing", children: [_jsx("div", { className: "detail-label", children: translate("app.studentLearningAssessment.assessment") }), _jsxs("div", { className: "-detail-value", children: [_jsx("div", { className: "assesment-count", children: participationOverview.courseCount }), _jsx("div", { className: "assesment-label", children: translate("app.studentLearningAssessment.course") })] })] }), _jsx("div", { className: "-detail instructors-assessing", children: _jsxs("div", { className: "-detail-value", children: [_jsx("div", { className: "assesment-count", children: participationOverview.instructorCount }), _jsx("div", { className: "assesment-label", children: translate("app.studentLearningAssessment.instructor") })] }) }), _jsx("div", { className: "assessments -detail", children: _jsxs("div", { className: "-detail-value", children: [_jsx("div", { className: "assesment-count", children: participationOverview.assessmentCount }), _jsx("div", { className: "assesment-label", children: translate("app.studentLearningAssessment.assessments") })] }) })] }));
};
var EmptyState = function () {
    return (_jsx("div", { className: "academic-yr-empty-state flex-row", children: _jsxs("div", { children: [_jsx("div", { className: "empty-state-title", children: translate("app.studentLearningAssesment.termEmptyState") }), _jsx("div", { className: "empty-state-desc", children: translate("app.studentLearningAssesment.termEmptyStateDesc") })] }) }));
};
export default StudentLearningAssesment;
