import { createContext } from "react";
var componentLoaded = {
    institution: false,
    membership: false,
    openPreviewModal: false
};
export var AppContext = createContext({
    institution: null,
    selectedTab: "",
    onWmTabSelectedEvent: function (event) { },
    sysUser: null,
    componentLoaded: componentLoaded,
    leads: [],
    setleads: function (result) { },
    setComponentLoaded: function (component) { },
    membership: null,
    setMembership: function (result) { },
    closePreviewModal: function () { },
    integratedProducts: null
});
