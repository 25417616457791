var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useReducer, useContext } from "react";
import { sendRequest } from "src/common/http";
import { AppContext } from "src/contexts/AppContext";
import translate from "src/i18n/translate";
import ReportingYears, { dateWithoutTimezone } from "../common/elements/ReportingYears";
import { WmButton } from "@watermarkinsights/ripple-react";
import { formatDate } from "../common/helper";
import WidgetLoader from "../common/elements/WidgetLoader";
import { getLoaderStatus } from "../common/helper";
import ApiError from "../common/elements/ApiError";
import { AccomplishmentsChart } from "./AccomplishmentsChart";
var REDIRECT_URI = runConfig.REDIRECT_URI;
var setScholarshipSummary = function (state, scholarshipSummary) {
    if (scholarshipSummary && scholarshipSummary.scholarshipSummary) {
        var total = scholarshipSummary.scholarshipSummary.find(function (acc) { return acc.category === "Total"; });
        var accomplishments = scholarshipSummary.scholarshipSummary
            .filter(function (acc) { return acc.category !== "Total"; })
            .sort(function (acc1, acc2) {
            return acc1.order > acc2.order ? 1 : -1;
        });
        return __assign(__assign({}, state), { accomplishments: accomplishments, totalAccomplishments: total && total.count, loader: false });
    }
    else {
        return __assign(__assign({}, state), { loader: false, summaryError: true });
    }
};
var FacultySuccess = function (_a) {
    var reportingYears = _a.reportingYears, defaultReportingYear = _a.defaultReportingYear, ryApiError = _a.ryApiError;
    var _b = useContext(AppContext), membership = _b.membership, institution = _b.institution;
    var initialState = {
        showScholarshipSummaryWidget: false,
        selectedReportingYear: defaultReportingYear,
        accomplishments: [],
        mappingId: null,
        totalAccomplishments: null,
        loader: true,
        summaryError: false
    };
    var defaultFacultySuccessAction = {
        type: "",
        year: defaultReportingYear,
        institutionMapping: {
            mappingId: "",
            showFsTab: false
        },
        scholarshipSummary: {
            scholarshipSummary: [],
            showFsTab: false
        }
    };
    var stateReducer = function (state, action) {
        switch (action.type) {
            case "showLoader":
                return __assign(__assign({}, state), { loader: true, summaryError: false });
            case "setScholarshipSummary":
                return setScholarshipSummary(state, action.scholarshipSummary);
            case "selectReportingYear":
                return __assign(__assign({}, state), { selectedReportingYear: action.year });
            case "setMappingId":
                return __assign(__assign({}, state), { mappingId: action.institutionMapping.mappingId, showScholarshipSummaryWidget: action.institutionMapping.showFsTab });
            case "setSummaryError":
                return __assign(__assign({}, state), { loader: false, summaryError: true });
            default:
                return state;
        }
    };
    var _c = useReducer(stateReducer, initialState), state = _c[0], dispatch = _c[1];
    var getURL = function () {
        var queryString = window.location.search;
        var param = new URLSearchParams(queryString);
        var locale = param.get("locale");
        var url = "/insights/faculty_success/reporting_year/".concat(state.selectedReportingYear.uuid, "/mapping_id/").concat(state.mappingId, "/html");
        if (locale !== null) {
            url = "".concat(url, "?locale=").concat(locale);
        }
        return url;
    };
    var openFacultySuccessHTMLViewLink = function () {
        return window.open(getURL(), "_blank");
    };
    useEffect(function () {
        if (institution && institution.salesforceId !== "") {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultFacultySuccessAction), { type: "setMappingId", institutionMapping: result }));
            };
            sendRequest({
                uri: "/faculty_success/institution_mapping?sfid=".concat(institution.salesforceId),
                camelize: true
            }, applyResult);
        }
    }, [institution && institution.salesforceId]);
    useEffect(function () {
        var _a, _b;
        if (state.mappingId &&
            state.selectedReportingYear &&
            state.selectedReportingYear.startDate) {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultFacultySuccessAction), { type: "setScholarshipSummary", scholarshipSummary: result }));
            };
            var onError = function () {
                dispatch(__assign(__assign({}, defaultFacultySuccessAction), { type: "setSummaryError" }));
            };
            sendRequest({
                uri: "/faculty_success/scholarship_summary" +
                    "?mapping_id=" +
                    state.mappingId +
                    "&start_date=" +
                    dateWithoutTimezone((_a = state.selectedReportingYear) === null || _a === void 0 ? void 0 : _a.startDate) +
                    "&end_date=" +
                    dateWithoutTimezone((_b = state.selectedReportingYear) === null || _b === void 0 ? void 0 : _b.endDate),
                camelize: true
            }, applyResult, onError);
            dispatch(__assign(__assign({}, defaultFacultySuccessAction), { type: "showLoader" }));
        }
    }, [state.mappingId, state.selectedReportingYear]);
    useEffect(function () {
        var liveRegion = document.querySelector(".faculty-loader-status");
        getLoaderStatus(state.loader, liveRegion);
    }, [state.loader]);
    var selectReportingYear = function (year) {
        dispatch(__assign(__assign({}, defaultFacultySuccessAction), { type: "selectReportingYear", year: year }));
    };
    useEffect(function () {
        selectReportingYear(defaultReportingYear);
    }, [defaultReportingYear]);
    var ScholarshipSummary = function () {
        return (_jsxs("div", { className: "wm-grid-row", children: [_jsx("span", { className: "sr-only faculty-loader-status", role: "status", "aria-live": "assertive" }), _jsx("div", { className: "grid-container wm-grid-col-12 wm-grid-col-md-10", children: _jsxs("div", { className: "paper-card paper-card-height", children: [_jsxs("div", { className: "header", children: [_jsxs("div", { className: "info", children: [_jsx("h3", { className: "title", children: translate("app.faculty.widgetLabel") }), _jsx("p", { className: "description", children: translate("app.faculty.widgetSummary") })] }), state.totalAccomplishments && state.totalAccomplishments > 0 ? (_jsx("div", { children: _jsx(WmButton, { id: "export-button", "aria-controls": "acc-listbox", "aria-expanded": "false", tabIndex: 0, onClick: openFacultySuccessHTMLViewLink, "button-type": "secondary", children: translate("app.exportHtml") }) })) : ("")] }), state.loader && (_jsx(WidgetLoader, { loaderContainerClasses: "loader-container", loaderClasses: "widget-loader" })), !state.loader && !state.summaryError && (_jsx("div", { className: "body fs-body", children: state.totalAccomplishments &&
                                    state.totalAccomplishments > 0 ? (_jsx("div", { className: "wm-grid", children: _jsx(Accomplishments, {}) })) : (_jsx(NoAccomplishments, {})) })), state.summaryError && _jsx(ApiError, {})] }) })] }));
    };
    var Accomplishments = function () {
        var _a, _b;
        return (_jsxs("div", { className: "flex-row accomplishments-container", children: [_jsxs("div", { className: "", children: [_jsx("div", { className: "accomplishments-heading", children: translate("app.faculty.totalAccomplishments") }), _jsx("div", { className: "accomplishments-count", children: state.totalAccomplishments }), state.selectedReportingYear &&
                            state.selectedReportingYear.startDate && (_jsx("div", { className: "accomplishments-years", children: translate("app.faculty.accomplishmentsYear", {
                                startDate: "".concat(formatDate((_a = state.selectedReportingYear) === null || _a === void 0 ? void 0 : _a.startDate)),
                                endDate: "".concat(formatDate((_b = state.selectedReportingYear) === null || _b === void 0 ? void 0 : _b.endDate))
                            }) }))] }), _jsx("div", { className: "chart", id: "scholarship-summary", children: _jsx(AccomplishmentsChart, { accomplishments: state.accomplishments, forHtml: false }) })] }));
    };
    var showScholarshipSummary = function () {
        return reportingYears && reportingYears.length > 0 ? (_jsx(ScholarshipSummary, {})) : (membership && reportingYearEmptyState(membership));
    };
    return (_jsx(_Fragment, { children: state.showScholarshipSummaryWidget && (_jsx("section", { className: (institution === null || institution === void 0 ? void 0 : institution.navigatedFrom) === "planning"
                ? "body wm-grid faculty-tab-body"
                : "body wm-grid tab-body-home", id: "sse-widget", children: _jsxs("section", { className: "insights", children: [_jsx(Header, { selectReportingYear: selectReportingYear, reportingYears: reportingYears, selectedReportingYear: state.selectedReportingYear, ryApiError: ryApiError }), ryApiError && _jsx(ApiError, {}), !ryApiError && reportingYears !== null ? (_jsx(_Fragment, { children: showScholarshipSummary() })) : ("")] }) })) }));
};
var Header = function (props) {
    return (_jsxs("div", { className: "institution-name -bottom flex-row -justified sse-desc", children: [_jsx("div", { className: "helper-label", children: translate("app.faculty.facultyInsightsLabel") }), !props.ryApiError &&
                props.reportingYears &&
                props.reportingYears.length > 0 &&
                props.selectedReportingYear.uuid && (_jsx(ReportingYears, { reportingYears: props.reportingYears, selectedReportingYear: props.selectedReportingYear, selectReportingYear: props.selectReportingYear }))] }));
};
var NoAccomplishments = function () {
    return (_jsxs("div", { className: "no-plans", children: [_jsx("div", { className: "no-plans-text", children: translate("app.faculty.noAccomplishments") }), _jsx("div", { className: "no-plans-text-select", children: translate("app.faculty.noAccomplishmentsSubMsg") })] }));
};
var reportingYearEmptyState = function (membership) {
    return (_jsx("div", { className: "academic-yr-empty-state flex-row", children: _jsxs("div", { children: [_jsx("div", { className: "empty-state-title", children: translate("app.continuousImprovement.noAcademicYearTitle") }), membership.userRole === "admin" && adminEmptyState(), membership.userRole === "contributor" && contributorEmptyState()] }) }));
};
function contributorEmptyState() {
    return (_jsx("div", { className: "empty-state-text", children: translate("app.faculty.contactAdmin") }));
}
function adminEmptyState() {
    return (_jsxs("div", { className: "empty-state-text", children: [_jsx("a", { className: "empty-state-link", href: "".concat(REDIRECT_URI, "/admin/configuration_settings/reporting_year"), children: translate("app.faculty.createReportingYear") }), translate("app.faculty.ryEmptyMessage")] }));
}
export default FacultySuccess;
