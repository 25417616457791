var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmButton, WmOption, WmSelect } from "@watermarkinsights/ripple-react";
import { AppContext } from "../../contexts/AppContext";
import React, { useContext, useEffect } from "react";
import { useReducer } from "react";
import translate from "../../i18n/translate";
import { closeFlyout } from "../../static/js/commons";
import { useIntl } from "react-intl";
import { NoResults, Table, TableBody, TableHeader, formatDateFullYear } from "../common/helper";
var ORGANIZATION_MISSION = "organization_mission";
var ORGANIZATION_LEARNING_OUTCOME = "organization_learning_outcome";
var ORGANIZATION_SUCCESS_OUTCOME = "organization_success_outcome";
var ORGANIZATION_ILO_MAPPING = "organization_ilo_mapping";
var PROGRAM_ILO = "programILO";
var ASSESSMENT_PLANNING_ORGANIZATIONS = "assessment_planning_organizations";
var ASSESSMENT_PLANNING_PROGRAMS = "assessment_planning_programs";
var PROGRAM_MISSION = "program_mission";
var PROGRAM_LEARNING_OUTCOMES = "program_learning_outcomes";
var PROGRAM_SUCCESS_OUTCOMES = "program_success_outcomes";
var PROGRAM_CMAP = "program_cmap";
var PROGRAM_ILO_MAPPING = "program_ilo_mapping";
var COURSE_LEARNING_OUTCOMES = "course_learning_outcomes";
var COURSE_ILO_MAPPING = "course_ilo_mapping";
var getDrilldownName = function (type) {
    switch (type) {
        case ORGANIZATION_MISSION:
            return translate("app.drilldown.missionStatementByOrg");
        case ORGANIZATION_LEARNING_OUTCOME:
            return translate("app.drilldown.learningOutcomes");
        case ORGANIZATION_SUCCESS_OUTCOME:
            return translate("app.drilldown.successOutcomes");
        case ORGANIZATION_ILO_MAPPING:
            return translate("app.drilldown.ILOMapping");
        case PROGRAM_MISSION:
            return translate("app.drilldown.missionStatementByProgram");
        case PROGRAM_LEARNING_OUTCOMES:
            return translate("app.drilldown.learningOutcomesByProgram");
        case PROGRAM_SUCCESS_OUTCOMES:
            return translate("app.drilldown.successOutcomesByProgram");
        case PROGRAM_CMAP:
            return translate("app.drilldown.curriculumMap");
        case PROGRAM_ILO_MAPPING:
            return translate("app.drilldown.ILOMappingByProgram");
        case COURSE_LEARNING_OUTCOMES:
            return translate("app.drilldown.learningOutcomesByCourse");
        case COURSE_ILO_MAPPING:
            return translate("app.drilldown.ILOMappingByCourse");
        case ASSESSMENT_PLANNING_ORGANIZATIONS:
            return translate("app.drilldown.ParticipatingOrgByOrg");
        case ASSESSMENT_PLANNING_PROGRAMS:
            return translate("app.drilldown.ParticipatingOrgByProgram");
        default:
            return "";
    }
};
var getDrilldownColumns = function (segment) {
    if ([
        ORGANIZATION_MISSION,
        PROGRAM_MISSION,
        PROGRAM_CMAP,
        ASSESSMENT_PLANNING_ORGANIZATIONS,
        ASSESSMENT_PLANNING_PROGRAMS
    ].includes(segment)) {
        return [
            translate("app.drilldown.organization"),
            translate("app.drilldown.status"),
            translate("app.drilldown.lastUpdated"),
            translate("app.drilldown.lead")
        ];
    }
    if ([
        ORGANIZATION_LEARNING_OUTCOME,
        ORGANIZATION_SUCCESS_OUTCOME,
        PROGRAM_LEARNING_OUTCOMES,
        PROGRAM_SUCCESS_OUTCOMES
    ].includes(segment)) {
        return [
            translate("app.drilldown.organization"),
            translate("app.drilldown.status"),
            translate("app.drilldown.numberOfOutcomes"),
            translate("app.drilldown.lead")
        ];
    }
    if ([ORGANIZATION_ILO_MAPPING, PROGRAM_ILO_MAPPING].includes(segment)) {
        return [
            translate("app.drilldown.organization"),
            translate("app.drilldown.status"),
            translate("app.drilldown.ILOMappedTo"),
            translate("app.drilldown.lead")
        ];
    }
    if (PROGRAM_ILO === segment) {
        return [
            translate("app.drilldown.organization"),
            translate("app.drilldown.status"),
            translate("app.drilldown.outcomesMapped"),
            translate("app.drilldown.lead")
        ];
    }
    return [];
};
var filterHierarchyByMode = function (drilldownType, status, completeHierarchy) {
    if ([ASSESSMENT_PLANNING_ORGANIZATIONS, ASSESSMENT_PLANNING_PROGRAMS].includes(drilldownType) ||
        !Array.isArray(completeHierarchy)) {
        var hierarchy = filterHierarchyByStatus(status, completeHierarchy);
        return hierarchy != null ? hierarchy : {};
    }
    else {
        return completeHierarchy
            .map(function (node) {
            return filterHierarchyByStatus(status, node);
        })
            .filter(function (node) {
            return node != null;
        });
    }
};
var filterHierarchyByStatus = function (status, completeHierarchy) {
    if (status === "any") {
        return completeHierarchy;
    }
    else {
        var hierarchy = filterHierarchy(completeHierarchy, status);
        return hierarchy ? hierarchy : null;
    }
};
var filterHierarchy = function (node, status) {
    if (node.showStats && node.status === status) {
        return node;
    }
    else if (node.showStats && node.status !== status) {
        return null;
    }
    else {
        var children = node.children
            .map(function (child) {
            return filterHierarchy(child, status);
        })
            .filter(function (node) {
            return node != null;
        });
        if (children.length > 0) {
            return __assign(__assign({}, node), { children: children });
        }
        else {
            return null;
        }
    }
};
var reducer = function (state, action) {
    switch (action.type) {
        case "filterHierarchyByStatus":
            var filteredHierarchy = filterHierarchyByMode(state.drilldownType, state.selectedStatus, state.completeHierarchy);
            return __assign(__assign({}, state), { hierarchy: filteredHierarchy, showTable: action.showTable });
        case "setSelectedStatus":
            return __assign(__assign({}, state), { selectedStatus: action.status });
        case "setCompleteHierarchy":
            return __assign(__assign({}, state), { completeHierarchy: action.data });
        default:
            return state;
    }
};
export default function OpenDrillDownView(_a) {
    var setIsDrilldownOpen = _a.setIsDrilldownOpen, drilldownName = _a.drilldownName, drilldownType = _a.drilldownType, drilldownStatus = _a.drilldownStatus, total = _a.total, showTable = _a.showTable, completeHierarchy = _a.completeHierarchy, filters = _a.filters, subHeader = _a.subHeader, title = _a.title, leads = _a.leads, planStatus = _a.planStatus;
    var initialState = {
        hierarchy: null,
        drilldownType: drilldownType,
        selectedStatus: drilldownStatus,
        completeHierarchy: null,
        showTable: Boolean,
        leads: leads
    };
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var CloseDrillDownView = function () {
        setIsDrilldownOpen(false);
        closeFlyout();
    };
    var institution = useContext(AppContext).institution;
    var statusChangeHandler = function (status) {
        dispatch({ type: "setSelectedStatus", status: status });
    };
    useEffect(function () {
        state.completeHierarchy &&
            dispatch({ type: "filterHierarchyByStatus", showTable: showTable });
    }, [state.selectedStatus, state.completeHierarchy]);
    useEffect(function () {
        dispatch({ type: "setCompleteHierarchy", data: completeHierarchy });
    }, [completeHierarchy]);
    var renderHierarchy = function (node) {
        return (_jsxs(React.Fragment, { children: [_jsx(Node, { node: node, type: drilldownType, leads: state.leads, planStatus: planStatus }), node.children.map(function (child) {
                    return renderHierarchy(child);
                })] }, "".concat(node.uuid, "-hierarchy")));
    };
    var renderHierarchyTable = function () {
        return (_jsxs(Table, { children: [_jsx(TableHeader, { columns: getDrilldownColumns(drilldownType) }), _jsx(TableBody, { children: Array.isArray(state.hierarchy)
                        ? state.hierarchy.map(function (node) {
                            return renderHierarchy(node);
                        })
                        : renderHierarchy(state.hierarchy) })] }));
    };
    var hierarchyTableBody = function () {
        return (_jsx("div", { className: "org-table", children: !Object.keys(state.hierarchy).length ? (_jsx(NoResults, {})) : (renderHierarchyTable()) }));
    };
    return (_jsxs("div", { className: "flyout-panel half-flyout show", role: "dialog", id: "drill-down-view", tabIndex: -1, "aria-label": "Drill down view", children: [_jsx("div", { className: "overlay" }), _jsxs("div", { className: "container-box", children: [_jsx("div", { className: "paper-card", children: _jsxs("div", { className: "overlay-content", children: [_jsx(WmButton, { buttonType: "navigational", icon: "f156", tooltip: "Close", tooltipPosition: "bottom", id: "drill-down-close-btn", class: "hydrated", onClick: CloseDrillDownView }), _jsxs("div", { className: "helper-text", children: [_jsx("h2", { className: "title", children: subHeader }), _jsx("div", { className: "description", children: drilldownName
                                                ? drilldownName
                                                : getDrilldownName(drilldownType) })] })] }) }), _jsxs("section", { className: "body", children: [_jsxs("div", { className: "flex-row -justified -verticallycentered", children: [_jsxs("div", { children: [_jsx("div", { className: "title -title", children: title !== null && title !== void 0 ? title : (institution && institution.name) }), _jsx("div", { className: "count", children: total }), _jsxs("div", { className: "count", children: [translate("app.drilldown.leads"), " ", institution && institution.orgLeads] })] }), state.showTable && filters ? (_jsx(StatusSelector, { filters: filters, changeHandler: statusChangeHandler, selected: state.selectedStatus })) : ("")] }), state.showTable && state.hierarchy ? (hierarchyTableBody()) : (_jsx("div", { className: "loader -halfscreen", children: _jsx("div", { className: "content content-loader", children: translate("app.loading") }) }))] })] })] }));
}
var Node = function (props) {
    var node = props.node, type = props.type, leads = props.leads, planStatus = props.planStatus;
    return (_jsx(_Fragment, { children: node.showStats ? (_jsx(CompleteRow, { node: node, type: type, lead: leads[node["uuid"]], planStatus: planStatus })) : (_jsx(NoStatsRow, { node: node })) }));
};
var CompleteRow = function (props) {
    var node = props.node, type = props.type, lead = props.lead, planStatus = props.planStatus;
    var leadName = function () {
        if (typeof lead != "undefined") {
            return lead.leads.map(function (l, index) {
                return _jsx("div", { children: l }, "lead-".concat(index, "-").concat(node.uuid));
            });
        }
    };
    var leadEmail = function () {
        if (typeof lead != "undefined") {
            return (_jsx("a", { className: "email_link", tabIndex: -1, href: "mailto: ".concat(lead.leadEmail), children: _jsx(WmButton, { buttonType: "textonly", icon: "f1ee", children: translate("app.drilldown.emailLeads") }) }));
        }
    };
    return (_jsxs("tr", { className: "datarow", children: [_jsx("td", { className: "datacell level-".concat(node.level), children: node.name }), _jsx(StatusCell, { status: node.status, planStatus: planStatus }), _jsx(StatsCell, { type: type, node: node }), _jsx("td", { className: "datacell", children: leadName() }), _jsx("td", { className: "datacell", children: leadEmail() })] }, "".concat(node.uuid, "-datarow")));
};
var StatsCell = function (props) {
    var node = props.node, type = props.type;
    var stats = function (type) {
        if ([
            ORGANIZATION_MISSION,
            PROGRAM_MISSION,
            PROGRAM_CMAP,
            ASSESSMENT_PLANNING_ORGANIZATIONS,
            ASSESSMENT_PLANNING_PROGRAMS
        ].includes(type)) {
            return node.updatedAt
                ? formatDateFullYear(node.updatedAt)
                : translate("app.na");
        }
        if ([
            ORGANIZATION_LEARNING_OUTCOME,
            ORGANIZATION_SUCCESS_OUTCOME,
            PROGRAM_LEARNING_OUTCOMES,
            PROGRAM_SUCCESS_OUTCOMES
        ].includes(type)) {
            return node.outcomes;
        }
        if ([ORGANIZATION_ILO_MAPPING, PROGRAM_ILO_MAPPING].includes(type)) {
            return node.iloMapped;
        }
        if (PROGRAM_ILO === type) {
            return "".concat(node.totalMappings, " of ").concat(node.totalOutcomes);
        }
        return "";
    };
    return _jsx("td", { className: "datacell", children: stats(type) });
};
var StatusCell = function (props) {
    var content = function () {
        switch (props.status) {
            case "not_mapped":
                return (_jsx("div", { className: "not-started-status", children: translate("app.drilldown.notMapped") }));
            case "not_started":
                return (_jsx("div", { className: "not-started-status", children: translate("app.drilldown.notStarted") }));
            case "mapped":
                return (_jsx("div", { className: "success-status -mapped", children: _jsx("div", { className: "title-text", children: translate("app.drilldown.mapped") }) }));
            case "submitted":
                return (_jsx("div", { className: "success-status -completed", children: _jsx("div", { className: "title-text", children: translate("app.drilldown.submitted") }) }));
            case "completed":
                return (_jsx("div", { className: "success-status -completed", children: _jsx("div", { className: "title-text", children: translate("app.drilldown.completed") }) }));
            case "in_progress":
                var status = props.planStatus == 1
                    ? translate("app.drilldown.inComplete")
                    : translate("app.drilldown.inProgress");
                return (_jsx("div", { className: "in-progress-status-export", children: _jsx("div", { className: "title-text", children: status }) }));
        }
    };
    return _jsx("td", { className: "datacell", children: content() });
};
var NoStatsRow = function (props) {
    var node = props.node;
    return (_jsxs("tr", { className: "datarow", children: [_jsx("td", { className: "datacell level-".concat(node.level), children: node.name }), _jsx("td", { className: "datacell" }), _jsx("td", { className: "datacell" }), _jsx("td", { className: "datacell" }), _jsx("td", { className: "datacell" })] }, "".concat(node.uuid, "-datarow")));
};
var StatusSelector = function (props) {
    var statusText = useIntl().formatMessage({
        id: "app.drilldown.status"
    });
    return (_jsx("div", { children: _jsxs(WmSelect, { label: statusText, labelPosition: "left", id: "select-program-status", children: [_jsx(WmOption, { onClick: props.changeHandler.bind(null, "any"), selected: "any" === props.selected, children: translate("app.drilldown.any") }), props.filters.map(function (filter) {
                    return (_jsx(WmOption, { onClick: props.changeHandler.bind(null, filter.value), selected: filter.value === props.selected, children: filter.name }, "status-".concat(filter.value)));
                })] }) }));
};
