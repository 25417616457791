import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "../common/elements/Header.scss";
import translate from "src/i18n/translate";
import { AppContext } from "src/contexts/AppContext";
import { useContext } from "react";
export default function IncorrectURLComponent() {
    var institution = useContext(AppContext).institution;
    var url = (institution === null || institution === void 0 ? void 0 : institution.navigatedFrom) === "planning"
        ? "/insights/planning/home"
        : "/insights/home";
    return (_jsxs("main", { id: "maincontent", className: "main-content-home unauth-component", children: [_jsx("div", { className: "unauthorized-title", children: translate("app.incorrect.title") }), _jsxs("div", { className: "unauth-desc", children: [translate("app.incorrect.goto"), _jsx("a", { href: url, children: translate("app.incorrect.home") })] })] }));
}
