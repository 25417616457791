var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useReducer, useEffect, useContext } from "react";
import { WmButton, WmProgressMonitor, WmProgressSlice, WmProgressIndicator } from "@watermarkinsights/ripple-react";
import OpenDrillDownView from "../common/OpenDrillDownView";
import { openFlyout } from "../../static/js/commons";
import { sendRequest } from "../../common/http";
import { AppContext } from "../../contexts/AppContext";
import translate from "src/i18n/translate";
import { useIntl } from "react-intl";
import ChartIconSvg from "../common/chartIconSvg";
import WidgetLoader from "../common/elements/WidgetLoader";
import ApiError from "../common/elements/ApiError";
var drilldownFilters = [
    { value: "mapped", name: translate("app.drilldown.mapped") },
    { value: "not_mapped", name: translate("app.drilldown.notMapped") }
];
var popover = function (count, total) { return "".concat(count, "/").concat(total); };
var initialState = {
    drilldownName: "",
    drilldownType: "programILO",
    drilldownStatus: "any",
    isDrilldownOpen: false,
    programMappings: [],
    totalPrograms: 0,
    hierarchy: [],
    showTable: false,
    showLoader: true,
    apiError: false
};
var defaultAction = {
    type: "",
    hierarchy: [],
    drilldownName: "",
    drilldownStatus: "",
    programMapping: { totalPrograms: 0, programMappings: [] },
    apiError: false,
    showLoader: false
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setProgramMapping":
            return __assign(__assign({}, state), { programMappings: action.programMapping.programMappings, totalPrograms: action.programMapping.totalPrograms });
        case "openDrilldown":
            return __assign(__assign({}, state), { isDrilldownOpen: true });
        case "closeDrilldown":
            return __assign(__assign({}, state), { isDrilldownOpen: false, showTable: false, hierarchy: [] });
        case "SetDrilldownData":
            return __assign(__assign({}, state), { drilldownName: action.drilldownName, drilldownStatus: action.drilldownStatus });
        case "setHierarchy":
            return __assign(__assign({}, state), { hierarchy: action.hierarchy, showTable: true });
        case "setShowLoader":
            return __assign(__assign({}, state), { showLoader: action.showLoader });
        case "setApiError":
            return __assign(__assign({}, state), { apiError: action.apiError });
        default:
            return state;
    }
};
export default function ProgramILOMappings(_a) {
    var onComponentLoad = _a.onComponentLoad;
    var _b = useContext(AppContext), leads = _b.leads, institution = _b.institution;
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    useEffect(function () {
        var uri = "/program_ilo_mappings";
        if (institution &&
            institution.reportingYear &&
            institution.reportingYear.uuid)
            uri += "?reporting_year_uuid=".concat(institution.reportingYear.uuid);
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setProgramMapping", programMapping: result }));
            dispatch(__assign(__assign({}, defaultAction), { type: "setShowLoader", showLoader: false }));
            onComponentLoad("programILOMappings");
        };
        var onError = function () {
            dispatch(__assign(__assign({}, defaultAction), { type: "setApiError", apiError: true }));
            dispatch(__assign(__assign({}, defaultAction), { type: "setShowLoader", showLoader: false }));
            onComponentLoad("programILOMappings");
        };
        sendRequest({ uri: uri, camelize: true }, applyResult, onError);
    }, [institution]);
    var getDrilldownHierarchy = function (uuid) {
        openDrilldownView();
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setHierarchy", hierarchy: result }));
        };
        sendRequest({ uri: "/hierarchy/programs_mapped_ilo/".concat(uuid), camelize: true }, applyResult);
    };
    var openDrilldownView = function () {
        dispatch(__assign(__assign({}, defaultAction), { type: "openDrilldown" }));
        setTimeout(function () {
            return openFlyout("drill-down-view", "program-ilo-mapping", "drill-down-close-btn");
        }, 100);
    };
    var closeDrilldown = function () {
        dispatch(__assign(__assign({}, defaultAction), { type: "closeDrilldown" }));
    };
    var openDrillDown = function (name, uuid, status) {
        getDrilldownHierarchy(uuid);
        dispatch(__assign(__assign({}, defaultAction), { type: "SetDrilldownData", drilldownName: name, drilldownStatus: status }));
    };
    var drilldownSubHeader = useIntl().formatMessage({
        id: "app.programILOMapping.programsMappedILO"
    });
    var exportMissionOutcomes = useIntl().formatMessage({
        id: "app.programILOMapping.exportMissionOutcomes"
    });
    var totalPrograms = useIntl().formatMessage({ id: "app.programProgressCard.totalPrograms" }, { totalPrograms: state.totalPrograms });
    var showData = function () { return !state.showLoader && !state.apiError; };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: state.apiError ? "paper-card" : "paper-card program-ilo-widget", children: [_jsxs("div", { className: "header", children: [_jsxs("div", { className: "info", children: [_jsx("h3", { className: "title", children: translate("app.programILOMapping.programsMappedILO") }), _jsx("p", { className: "description", children: translate("app.programILOMapping.reviewPrograms") })] }), _jsx(WmButton, { hidden: true, "button-type": "secondary", "label-for-identical-buttons": exportMissionOutcomes, children: translate("app.programILOMapping.export") })] }), _jsxs("div", { className: "body", children: [state.showLoader && (_jsx("div", { className: "loader-position", children: _jsx(WidgetLoader, { loaderContainerClasses: "loader-container", loaderClasses: "widget-loader" }) })), state.apiError && _jsx(ApiError, { containerClasses: "api-error" }), showData() && (_jsx(Body, { programMappings: state.programMappings, totalPrograms: state.totalPrograms, openDrillDown: openDrillDown }))] })] }), state.isDrilldownOpen ? (_jsxs(Fragment, { children: [_jsx("div", { className: "sr-only", id: "program-ilo-dialog-wrapper-flyout-top", tabIndex: 0 }), _jsx(OpenDrillDownView, { setIsDrilldownOpen: closeDrilldown, drilldownName: state.drilldownName, drilldownType: state.drilldownType, drilldownStatus: state.drilldownStatus, total: totalPrograms, showTable: state.showTable, completeHierarchy: state.hierarchy, filters: drilldownFilters, subHeader: drilldownSubHeader, leads: leads }), _jsx("div", { className: "sr-only", id: "program-ilo-dialog-wrapper-flyout-bottom", tabIndex: 0 })] })) : ("")] }));
}
var Body = function (props) {
    if (props.programMappings.length === 0) {
        return (_jsxs("div", { id: "ilo-map-empty-state", children: [_jsx("div", { className: "ilo-empty-state", children: _jsx(ChartIconSvg, {}) }), _jsx("h3", { className: "no-outcomes-text", children: translate("app.programILOMapping.noOutcomes") })] }));
    }
    else {
        return (_jsxs("div", { id: "ilo-chart-container", children: [_jsx("h4", { className: "title", children: translate("app.programILOMapping.programMappingStatusILO") }), _jsx("div", { className: "legend-wrapper", children: _jsxs("div", { className: "legend", "aria-hidden": "true", children: [_jsxs("div", { className: "legend-item", children: [_jsx("div", { className: "legend-color", style: { backgroundColor: "rgb(107, 107, 107)" } }), _jsx("div", { className: "legend-text", children: translate("app.drilldown.notMapped") })] }), _jsxs("div", { className: "legend-item", children: [_jsx("div", { className: "legend-color", style: { backgroundColor: "rgb(8, 128, 0)" } }), _jsx("div", { className: "legend-text", children: translate("app.drilldown.mapped") })] })] }) }), _jsx("div", { className: "charts ilo-charts", id: "program-ilo-mapping", children: _jsx(ProgramIloMappingChart, { programMappings: props.programMappings, totalPrograms: props.totalPrograms, openDrillDown: props.openDrillDown }) })] }));
    }
};
var ProgramIloMappingChart = function (props) {
    var programsNotMapped = useIntl().formatMessage({
        id: "app.programILOMapping.programsNotMapped"
    });
    var programsMapped = useIntl().formatMessage({
        id: "app.programILOMapping.programsMapped"
    });
    var mappedText = useIntl().formatMessage({
        id: "app.drilldown.mapped"
    });
    var notMapped = useIntl().formatMessage({
        id: "app.drilldown.notMapped"
    });
    var drilldownByProgram = useIntl().formatMessage({
        id: "app.programILOMapping.drilldownByProgram"
    });
    return (_jsx(WmProgressMonitor, { id: "ilo-progress", breakpoint: "10px", children: props.programMappings.map(function (programMapping) {
            var iloTitle = programMapping.title, mapped = programMapping.mappedPrograms, isGenEd = programMapping.isGeneralEducation, uuid = programMapping.uuid;
            return (_jsxs(Fragment, { children: [_jsxs(WmProgressIndicator, { label: iloTitle, children: [_jsx(WmProgressSlice, { amount: mapped.toString(), "popover-title": popover(mapped, props.totalPrograms), "popover-text": programsMapped, legend: mappedText, "popover-button-text": drilldownByProgram, onClick: function () { return props.openDrillDown(iloTitle, uuid, "mapped"); } }, "".concat(uuid, "-mapped")), _jsx(WmProgressSlice, { amount: (props.totalPrograms - mapped).toString(), "popover-title": popover(props.totalPrograms - mapped, props.totalPrograms), "popover-text": programsNotMapped, legend: notMapped, "popover-button-text": drilldownByProgram, onClick: function () {
                                    return props.openDrillDown(iloTitle, uuid, "not_mapped");
                                } }, "".concat(uuid, "-not-mapped"))] }), isGenEd && (_jsx("div", { id: "gened-".concat(uuid), className: "gened-tag", children: translate("app.programILOMapping.genEd") }))] }, "fragment-".concat(uuid)));
        }) }));
};
