var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useEffect, useReducer, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { sendRequest } from "src/common/http";
import translate from "src/i18n/translate";
import "../Lightcast.scss";
import ApiError from "../../common/elements/ApiError";
var defaultPopulationByAgeResultset = {
    currentYear: 0,
    pastYear: 0,
    populationByAge: []
};
var defaultPopulationByAgeAction = {
    type: "",
    changeInPopulationRange: [],
    changePercentages: [],
    populationByAge: defaultPopulationByAgeResultset,
    flag: false
};
var reducer = function (state, action) {
    var _a, _b, _c, _d;
    switch (action.type) {
        case "setChangeInPopulationRange":
            return __assign(__assign({}, state), { changeInPopulationRange: action.changeInPopulationRange });
        case "setPopulationChangePercentages":
            var changePercentages = (_a = action.populationByAge) === null || _a === void 0 ? void 0 : _a.populationByAge.map(function (population) { return population.changePercentage; });
            return __assign(__assign({}, state), { changePercentages: changePercentages });
        case "setBarsFlag":
            return __assign(__assign({}, state), { setBars: action.flag });
        case "setPopulationByAgeError":
            return __assign(__assign({}, state), { currentYear: 0, pastYear: 0, populationByAge: [], changePercentages: [], apiError: true });
        case "setPopulationByAge":
            return __assign(__assign({}, state), { currentYear: (_b = action.populationByAge) === null || _b === void 0 ? void 0 : _b.currentYear, pastYear: (_c = action.populationByAge) === null || _c === void 0 ? void 0 : _c.pastYear, populationByAge: (_d = action.populationByAge) === null || _d === void 0 ? void 0 : _d.populationByAge, apiError: action.populationByAge ? false : true });
        default:
            return state;
    }
};
export default function PopulationByAgeResultset(_a) {
    var setTooglePosition = _a.setTooglePosition, onTabLoad = _a.onTabLoad, onComponentLoad = _a.onComponentLoad, showIntegratedHeader = _a.showIntegratedHeader;
    var initialState = {
        changeInPopulationRange: [],
        changePercentages: null,
        setBars: true,
        currentYear: null,
        pastYear: null,
        populationByAge: [],
        apiError: false
    };
    var _b = useState(false), openPopoverState = _b[0], setOpenPopoverState = _b[1];
    var _c = useState(""), selectedRowId = _c[0], setSelectedRowId = _c[1];
    var _d = useReducer(reducer, initialState), state = _d[0], dispatch = _d[1];
    var _e = useState(Number), maxTicks = _e[0], setMaxTicks = _e[1];
    var _f = useState(false), isMoveLeftBtnDisabled = _f[0], setMoveLeftBtnDisable = _f[1];
    var _g = useState(true), isMoveRightBtnDisabled = _g[0], setMoveRightBtnDisable = _g[1];
    var timeframe = {
        pastYear: state.pastYear,
        currentYear: state.currentYear
    };
    var populationChangeByAge = state.populationByAge;
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, defaultPopulationByAgeAction), { type: "setPopulationByAge", populationByAge: result }));
            dispatch(__assign(__assign({}, defaultPopulationByAgeAction), { type: "setPopulationChangePercentages", populationByAge: result }));
            onTabLoad("ageCohort");
            onComponentLoad("populationByAge");
        };
        var onError = function (error) {
            dispatch(__assign(__assign({}, defaultPopulationByAgeAction), { type: "setPopulationByAgeError" }));
            onTabLoad("ageCohort");
            onComponentLoad("populationByAge");
        };
        sendRequest({ uri: "/lightcast/population_by_age", camelize: true }, applyResult, onError);
    }, []);
    var getChangeInPopulationRange = function (changePercentages) {
        var hasNegativeVal = changePercentages.some(function (val) { return val < 0; });
        var hasPositiveVal = changePercentages.some(function (val) { return val >= 0; });
        var absPercentageChangeValArr = absoluteValueArray(changePercentages);
        var getIntervalRangeVal = getInterval(Math.max.apply(Math, absPercentageChangeValArr), 2);
        if (hasNegativeVal && !hasPositiveVal) {
            setMaxTicks(2);
            setBarClassList("negative");
            return [
                0,
                -getIntervalRangeVal / 2,
                -getIntervalRangeVal,
                -(getIntervalRangeVal + getIntervalRangeVal / 2),
                -getIntervalRangeVal * 2
            ];
        }
        else if (hasPositiveVal && !hasNegativeVal) {
            setMaxTicks(2);
            setBarClassList("positive");
            return [
                getIntervalRangeVal * 2,
                getIntervalRangeVal + getIntervalRangeVal / 2,
                getIntervalRangeVal,
                getIntervalRangeVal / 2,
                0
            ];
        }
        else {
            setMaxTicks(2);
            return [
                getIntervalRangeVal * 2,
                getIntervalRangeVal,
                0,
                -getIntervalRangeVal,
                -getIntervalRangeVal * 2
            ];
        }
    };
    var setBars = function (changePercentages) {
        var absPercentageChangeValArr = absoluteValueArray(changePercentages);
        var ele = document.querySelectorAll(".barss");
        ele.forEach(getBarsHeightVal);
        function getBarsHeightVal(item, index) {
            var n = parseFloat(changePercentages[index]) * 1;
            var getHeight = function (n) {
                return ((parseFloat(changePercentages[index]) /
                    (getInterval(Math.max.apply(Math, absPercentageChangeValArr), maxTicks) *
                        maxTicks)) *
                    100);
            };
            var numbersFull = getHeight(n);
            item.style.height = Math.round(Math.abs(numbersFull)) + "%";
            if (n >= 0) {
                addClassListToElement(item, "positive");
            }
            else {
                addClassListToElement(item, "negative");
            }
        }
        if (ele && ele.length > 0)
            dispatch(__assign(__assign({}, defaultPopulationByAgeAction), { type: "setBarsFlag", flag: false }));
        return _jsx(_Fragment, {});
    };
    useEffect(function () {
        state.changePercentages &&
            dispatch(__assign(__assign({}, defaultPopulationByAgeAction), { type: "setChangeInPopulationRange", changeInPopulationRange: getChangeInPopulationRange(state.changePercentages) }));
    }, [state.changePercentages]);
    var openPopover = function (event, population) {
        setTooglePosition(event, "cohort-chart");
        setOpenPopoverState(!openPopoverState);
        setSelectedRowId(String(population.id));
    };
    function useClosePopoverClick(ref) {
        useEffect(function () {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpenPopoverState(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return function () {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    var wrappersRef = useRef(null);
    useClosePopoverClick(wrappersRef);
    var closePopoverArea = function () {
        setOpenPopoverState(false);
    };
    var moveLeftBtn = useIntl().formatMessage({
        id: "app.changeInPopulation.moveLeftBtn"
    });
    var moveRightBtn = useIntl().formatMessage({
        id: "app.changeInPopulation.moveRightBtn"
    });
    var chartAriaLabel = useIntl().formatMessage({
        id: "app.chartAriaLabel"
    });
    var addBoxShadowOnBothSides = function (element) {
        element && element.classList.add("-box-shadows");
    };
    var removeBoxShadowOnBothSides = function (element) {
        element && element.classList.remove("-box-shadows");
    };
    var addBoxShadowOnLeft = function (element) {
        element && element.classList.add("-box-shadow-left");
    };
    var removeBoxShadowOnLeft = function (element) {
        element && element.classList.remove("-box-shadow-left");
    };
    var addBoxShadowOnRight = function (element) {
        element && element.classList.add("-box-shadow-right");
    };
    var removeBoxShadowOnRight = function (element) {
        element && element.classList.remove("-box-shadow-right");
    };
    var setMoveLeftRightBtnDisability = function (ref, state) {
        var element = ref === null || ref === void 0 ? void 0 : ref.current;
        if ((element === null || element === void 0 ? void 0 : element.scrollLeft) === 0) {
            setMoveLeftBtnDisable(true);
            removeBoxShadowOnLeft(element);
            removeBoxShadowOnBothSides(element);
        }
        else {
            setMoveLeftBtnDisable(false);
            addBoxShadowOnLeft(element);
            removeBoxShadowOnBothSides(element);
        }
        if ((element === null || element === void 0 ? void 0 : element.scrollLeft) !== 0 &&
            (element === null || element === void 0 ? void 0 : element.scrollLeft) < (element === null || element === void 0 ? void 0 : element.scrollWidth) - (element === null || element === void 0 ? void 0 : element.offsetWidth) - 1) {
            removeBoxShadowOnRight(element);
            removeBoxShadowOnLeft(element);
            addBoxShadowOnBothSides(element);
        }
        switch (state) {
            case "onload":
                if ((element === null || element === void 0 ? void 0 : element.scrollLeft) >
                    (element === null || element === void 0 ? void 0 : element.scrollWidth) - (element === null || element === void 0 ? void 0 : element.offsetWidth) - 1) {
                    return (setMoveRightBtnDisable(false),
                        addBoxShadowOnRight(element),
                        removeBoxShadowOnBothSides(element));
                }
                else {
                    return setMoveRightBtnDisable(false), setMoveLeftBtnDisable(true);
                }
            default:
                if ((element === null || element === void 0 ? void 0 : element.scrollLeft) >
                    (element === null || element === void 0 ? void 0 : element.scrollWidth) - (element === null || element === void 0 ? void 0 : element.offsetWidth) - 1) {
                    return setMoveRightBtnDisable(true), removeBoxShadowOnRight(element);
                }
                else {
                    return setMoveRightBtnDisable(false), addBoxShadowOnRight(element);
                }
        }
    };
    function useSetMoveLeftRightValue(ref) {
        useEffect(function () {
            setTimeout(function () {
                setMoveLeftRightBtnDisability(ref, "onload");
            }, 200);
        }, [ref]);
    }
    var scrollWrapper = useRef(null);
    useSetMoveLeftRightValue(scrollWrapper);
    var setChartScroll = function (element, speed, distance, direction) {
        var scrollAmount = 0;
        var slideTimer = setInterval(function () {
            var step = direction === "left" ? -(element === null || element === void 0 ? void 0 : element.offsetWidth) : element === null || element === void 0 ? void 0 : element.offsetWidth;
            element.scrollLeft += step;
            scrollAmount += Math.abs(step);
            if (scrollAmount >= distance) {
                clearInterval(slideTimer);
            }
            setMoveLeftRightBtnDisability(scrollWrapper, "button-click");
        }, speed);
    };
    var getPopoverAmountData = function (population) {
        return population.changePercentage >= 0 ? "-green" : "-red";
    };
    var getNumericSignValue = function (changeInPercentageVal) {
        return changeInPercentageVal >= 0 ? "+" : "";
    };
    return (_jsxs(_Fragment, { children: [!state.apiError && state.changePercentages && (_jsxs("div", { className: "population-by-age", children: [_jsxs("div", { className: "age-cohort-chart flex-row -justified", children: [!showIntegratedHeader && (_jsx("div", { className: "header", children: _jsxs("div", { className: "info", id: "change-population-info", children: [_jsx("h3", { className: "title change-population-title", children: translate("app.changeInPopulation.ageCohort.changePopulationByAge") }), _jsx("div", { className: "description -descrip html-desc", children: translate("app.changeInPopulation.helperText") })] }) })), _jsx("div", {}), showIntegratedHeader && (_jsx("div", { className: "age-cohort-label", children: translate("app.changeInPopulation.ageCohort.changePopulationByAge") })), _jsxs("div", { className: "flex-row", children: [_jsx(WmButton, { buttonType: "icononly", tooltip: moveLeftBtn, icon: "f04d", tooltipPosition: "bottom-left", disabled: isMoveLeftBtnDisabled, onClick: function () {
                                            return setChartScroll(scrollWrapper.current, 25, 100, "left");
                                        } }), _jsx(WmButton, { buttonType: "icononly", tooltip: moveRightBtn, icon: "f054", tooltipPosition: "bottom-left", disabled: isMoveRightBtnDisabled, onClick: function () {
                                            return setChartScroll(scrollWrapper.current, 25, 100, "right");
                                        } })] })] }), _jsxs("div", { className: "flex-row -verticallycentered", children: [_jsx("div", { className: "percentage-change-label", children: translate("app.changeInPopulation.ageCohort.percentageChange") }), _jsx("div", { className: "cohort-chart ", id: "cohort-chart-container", tabIndex: -1, ref: scrollWrapper, children: _jsx("div", { className: "bar-chart", id: "bar-chart", children: _jsxs("div", { className: "row bars", children: [_jsx("div", { className: "y-axis one", children: state.changeInPopulationRange.map(function (populChange) {
                                                    return (_jsx("div", { className: "segment", children: _jsx("span", { className: "label", children: populChange }) }, populChange));
                                                }) }), _jsx("div", { className: "x-axis", id: "x-axis", children: populationChangeByAge.map(function (population) {
                                                    var changePercentage = population.changePercentage;
                                                    var populationAgeRange = getPopulationAgeRange(population);
                                                    var changeState = changePercentage >= 0
                                                        ? translate("app.trends.increased")
                                                        : translate("app.trends.decreased");
                                                    return (_jsxs("div", { className: "year wrap", children: [_jsx("div", { className: "col", children: _jsx("div", { className: "barss tooltip", onClick: function (event) {
                                                                        return openPopover(event, population);
                                                                    }, onBlur: closePopoverArea, ref: wrappersRef, onKeyDown: function (event) {
                                                                        return openPopover(event, population);
                                                                    }, tabIndex: 0, "aria-label": chartAriaLabel, role: "button", children: _jsx("div", { className: "population-tooltiptext tooltiptext-years ".concat(openPopoverState &&
                                                                            String(population.id) === selectedRowId
                                                                            ? "-visible"
                                                                            : "-hidden"), role: "alert", "aria-live": "polite", "aria-atomic": true, children: _jsxs("div", { className: "tooltip-text", children: [_jsx("div", { className: "population-popover-amount \n                              ".concat(getPopoverAmountData(population)), children: getNumericSignValue(population.changePercentage) +
                                                                                        population.changePercentage +
                                                                                        "%" }), _jsx("div", { className: "population-popover-text", children: translate("app.changeInPopulation.popoverLabelText", {
                                                                                        populationAgeRange: populationAgeRange,
                                                                                        changeState: changeState,
                                                                                        changePercentage: Math.abs(changePercentage),
                                                                                        pastYear: timeframe.pastYear,
                                                                                        currentYear: timeframe.currentYear
                                                                                    }) })] }) }) }) }), _jsx("span", { className: "label", children: displayAgeGroup(population) })] }, population.id));
                                                }) })] }) }) })] }), state.setBars ? setBars(state.changePercentages) : "", _jsx("div", { className: "pop-age-year", children: translate("app.changeInPopulation.ageCohort.populationAgeYears") })] })), state.apiError && _jsx(ApiError, {})] }));
}
var displayAgeGroup = function (population) {
    if (population.ageFrom === "0")
        return translate("app.changeInPopulation.ageCohort.lowestAgeGroup");
    if (population.ageFrom === "85")
        return translate("app.changeInPopulation.ageCohort.highestAgeGroup");
    return translate("app.changeInPopulation.ageCohort.ageGroup", {
        ageFrom: population.ageFrom,
        ageTo: population.ageTo
    });
};
var getPopulationAgeRange = function (population) {
    if (population.ageFrom === "0")
        return "> 5";
    if (population.ageFrom === "85")
        return "85 <";
    return population.ageFrom + " to " + population.ageTo;
};
var addClassListToElement = function (item, classesToAdd) {
    item.classList.add(classesToAdd);
};
var getElementListAndSetClassList = function (elementTofetch, classToAdd) {
    var element = document.querySelectorAll(elementTofetch);
    element.forEach(setClassList);
    function setClassList(item, index) {
        addClassListToElement(item, classToAdd);
    }
};
var getNegativeValBarView = function () {
    getElementListAndSetClassList(".col", "col-height");
    var barElement = document.querySelectorAll(".barss");
    barElement.forEach(removeBarClassList);
    function removeBarClassList(item, index) {
        item.classList.remove("negative");
    }
    getElementListAndSetClassList(".label", "-label-tranform");
    getElementListAndSetClassList(".x-axis", "-transform");
    getElementListAndSetClassList(".segment", "-border-top");
    getElementListAndSetClassList(".tooltip-text", "-transform-tooltip");
    getElementListAndSetClassList(".cohort-chart", "-negative-space");
};
var getPostiveValBarView = function () {
    getElementListAndSetClassList(".col", "col-height");
    var barElement = document.querySelectorAll(".barss");
    barElement.forEach(removeBarClassList);
    function removeBarClassList(item) {
        item.classList.remove("negative");
    }
};
var setBarClassList = function (valStatus) {
    switch (valStatus) {
        case "negative":
            return getNegativeValBarView();
        default:
            return getPostiveValBarView();
    }
};
var absoluteValueArray = function (percentages) {
    return percentages.map(Math.abs);
};
var getInterval = function (largest, ticks) {
    var minimum = largest / ticks;
    var magnitude = Math.pow(10, Math.floor(Math.log10(minimum)));
    var residual = minimum / magnitude;
    var interval;
    if (residual > 5) {
        interval = 10 * magnitude;
    }
    else if (residual > 3) {
        interval = 5 * magnitude;
    }
    else if (residual > 2) {
        interval = 3 * magnitude;
    }
    else if (residual > 1.5) {
        interval = 2 * magnitude;
    }
    else if (residual > 1) {
        interval = 1.5 * magnitude;
    }
    else {
        interval = magnitude;
    }
    return interval;
};
