var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useReducer } from "react";
import translate from "src/i18n/translate";
import { closeFlyout } from "../../../static/js/commons";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
var allProgramsAction = {
    type: "",
    sortOrder: ""
};
var initialState = {
    sortOrder: "desc"
};
var reducer = function (state, action) {
    switch (action.type) {
        case "toggleSortingOrder":
            return __assign(__assign({}, state), { sortOrder: state.sortOrder === "asc" ? "desc" : "asc" });
        default:
            return state;
    }
};
export default function AllProgramsFlyout(_a) {
    var setIsDrilldownOpen = _a.setIsDrilldownOpen, programDetails = _a.programDetails;
    var closeDrillDownView = function () {
        setIsDrilldownOpen(false);
        closeFlyout();
    };
    var closeTooltip = useIntl().formatMessage({
        id: "app.ces.responseRate.drilldownTooltipClose"
    });
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var toggleSortingOrder = function () {
        dispatch(__assign(__assign({}, allProgramsAction), { type: "toggleSortingOrder" }));
    };
    return (_jsxs("div", { className: "flyout-panel half-flyout show", role: "dialog", id: "drill-down-view", tabIndex: -1, "aria-label": "All Programs flyout ", children: [_jsx("div", { className: "overlay" }), _jsxs("div", { className: "container-box all-programs-container", children: [_jsx("div", { className: "paper-card", children: _jsxs("div", { className: "overlay-content", children: [_jsxs("div", { className: "helper-text", children: [_jsx("h2", { className: "title", children: translate("app.strategy.topProgramsByCatalog.flyoutHeading") }), _jsx("div", { className: "description", id: "all-programs-description", children: translate("app.strategy.topProgramsByCatalog.flyoutDescription") })] }), _jsx(WmButton, { buttonType: "navigational", icon: "f156", tooltip: closeTooltip, tooltipPosition: "bottom", id: "all-programs-close-btn", class: "hydrated", iconSize: "18px", onClick: closeDrillDownView })] }) }), _jsx("section", { id: "org-table-catalog", className: "all-programs-table", children: _jsxs(Table, { children: [_jsx(TableHeader, { columns: [
                                        translate("app.strategy.topProgramsByCatalog.program"),
                                        translate("app.strategy.topProgramsByCatalog.pageViews")
                                    ], toggleSortingOrder: toggleSortingOrder, sortOrder: state.sortOrder }), _jsx(TableBody, { programDetails: programDetails, sortOrder: state.sortOrder })] }) })] })] }));
}
var TableBody = function (_a) {
    var programDetails = _a.programDetails, sortOrder = _a.sortOrder;
    var sortedProgramDetails = __spreadArray([], programDetails, true).sort(function (a, b) {
        if (sortOrder === "asc") {
            return a.pageViews - b.pageViews;
        }
        else {
            return b.pageViews - a.pageViews;
        }
    });
    return (_jsx("tbody", { className: "databody", children: sortedProgramDetails.map(function (programDetail, index) {
            return (_jsxs("tr", { className: "datarow", children: [_jsx("td", { className: "datacell", children: _jsx("div", { children: programDetail.programName }) }), _jsx("td", { className: "datacell", children: programDetail.pageViews })] }, "".concat(index, "-").concat(programDetail.programName)));
        }) }));
};
var Table = function (props) {
    return (_jsx("table", { id: "flyout-table", className: "data-table -shadow", children: props.children }));
};
export var TableHeader = function (props) {
    var handleKeyDown = function (e) {
        if (e.key === "ArrowUp") {
            props.toggleSortingOrder();
        }
        else if (e.key === "ArrowDown") {
            props.toggleSortingOrder();
        }
    };
    return (_jsx("thead", { className: "dataheader", children: _jsxs("tr", { className: "dataheaderrow", children: [_jsx("th", { className: "dataheadercell", "data-th": props.columns[0], children: _jsx("span", { children: props.columns[0] }) }), _jsx("th", { className: "sortable-table-header dataheadercell", "data-th": props.columns[1], onClick: function () { return props.toggleSortingOrder(); }, onKeyDown: handleKeyDown, children: _jsxs("div", { className: "pointer-cursor", tabIndex: 0, "aria-sort": props.sortOrder === "asc" ? "ascending" : "descending", role: "columnheader", children: [_jsx("span", { children: props.columns[1] }), _jsx("div", { className: "arrow-position", children: _jsx("span", { className: props.sortOrder === "asc"
                                        ? "mdi mdi-arrow-up sort-arrow"
                                        : "mdi mdi-arrow-down sort-arrow" }) })] }) })] }) }));
};
