var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmChart, WmChartSlice } from "@watermarkinsights/ripple-react";
import translate from "src/i18n/translate";
import { useIntl } from "react-intl";
import React, { useReducer } from "react";
import { openFlyout } from "../../static/js/commons";
import OpenDrillDownViewSll from "../sll/OpenDrillDownViewSll";
var defaultAssessmentProgressChartAction = {
    type: "",
    drilldownName: "",
    termUuid: ""
};
var reducer = function (state, action) {
    switch (action.type) {
        case "ToggleDrilldown":
            return __assign(__assign({}, state), { isDrilldownOpen: !state.isDrilldownOpen });
        case "SetDrilldownData":
            return __assign(__assign({}, state), { drilldownName: action.drilldownName, termUuid: action.termUuid });
        default:
            return state;
    }
};
export default function AssessmentProgressChart(_a) {
    var assessmentProgress = _a.assessmentProgress, noActivityTerms = _a.noActivityTerms;
    var initialState = {
        isDrilldownOpen: false,
        drilldownType: "",
        drilldownName: "",
        termUuid: ""
    };
    var awaitingSubmission = useIntl().formatMessage({
        id: "app.assessmentProgressChart.awaitingSubmission"
    });
    var awaitingAssessment = useIntl().formatMessage({
        id: "app.assessmentProgressChart.awaitingAssessment"
    });
    var done = useIntl().formatMessage({
        id: "app.done"
    });
    var POPOVER_BUTTON_TEXT = useIntl().formatMessage({
        id: "app.assessmentProgressChart.drilldownCommonAssessment"
    });
    var DD_SUB_HEADER = useIntl().formatMessage({
        id: "app.assessmentProgressChart.studentAssessmentByTerm"
    });
    var setIsDrilldownOpen = function () {
        dispatch(__assign(__assign({}, defaultAssessmentProgressChartAction), { type: "ToggleDrilldown" }));
    };
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var openDrilldownView = function (name, termUuuid) {
        setIsDrilldownOpen();
        dispatch(__assign(__assign({}, defaultAssessmentProgressChartAction), { type: "SetDrilldownData", drilldownName: name, termUuid: termUuuid }));
        setTimeout(function () {
            return openFlyout("drill-down-view", "open-drilldown-btn", "drill-down-close-btn");
        }, 400);
    };
    function useStudentAwaitingSubmission(asstProgress) {
        return useIntl().formatMessage({ id: "app.assessmentProgressChart.popoverStatusText" }, { statusLabel: awaitingSubmission, labelText: asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.name });
    }
    var StudentAwaitingSubmission = function (asstProgress) { return useStudentAwaitingSubmission(asstProgress); };
    function useTotalText(asstProgress, total) {
        return useIntl().formatMessage({ id: "app.assessmentProgressChart.total" }, { asstProgressName: asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.name, total: total });
    }
    var TotalText = function (asstProgress, total) { return useTotalText(asstProgress, total); };
    function useStudentAssessmentDone(asstProgress) {
        return useIntl().formatMessage({ id: "app.assessmentProgressChart.popoverStatusText" }, { statusLabel: done, labelText: asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.name });
    }
    var StudentAssessmentDone = function (asstProgress) { return useStudentAssessmentDone(asstProgress); };
    function useStudentAssessmentAwaiting(asstProgress) {
        return useIntl().formatMessage({ id: "app.assessmentProgressChart.popoverStatusText" }, { statusLabel: awaitingAssessment, labelText: asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.name });
    }
    var StudentAssessmentAwaiting = function (asstProgress) { return useStudentAssessmentAwaiting(asstProgress); };
    return (_jsxs("div", { className: "chart chart-section", id: "assessment-progress-chart", children: [_jsx("div", { className: "progress-term-label", children: translate("app.assessmentProgressChart.studentAssessmentProgress") }), _jsx("div", { className: "legend-wrapper", children: _jsxs("div", { className: "legend", "aria-hidden": "true", children: [_jsxs("div", { className: "legend-item", children: [_jsx("div", { className: "legend-color legend1 -legend-wrap" }), _jsx("div", { className: "legend-text", children: translate("app.assessmentProgressChart.awaitingSubmission") })] }), _jsxs("div", { className: "legend-item", children: [_jsx("div", { className: "legend-color legend2 -legend-wrap" }), _jsx("div", { className: "legend-text", children: translate("app.assessmentProgressChart.awaitingAssessment") })] }), _jsxs("div", { className: "legend-item", children: [_jsx("div", { className: "legend-color legend3" }), _jsx("div", { className: "legend-text", children: translate("app.done") })] })] }) }), assessmentProgress.map(function (asstProgress) {
                var total = Number(Object.values(asstProgress.studentAssessments).reduce(function (newVal, oldVal) { return (newVal = newVal + oldVal); }, 0));
                return (_jsx("div", { children: _jsx("div", { className: "chart-space", children: _jsxs(WmChart, { "chart-type": "bar4", "value-format": "amount", "show-legend": "false", label: String(TotalText(asstProgress, String(total))), children: [_jsx(WmChartSlice, { legend: awaitingSubmission, amount: String(asstProgress.studentAssessments.awaitingSubmission), popoverText: String(StudentAwaitingSubmission(asstProgress)), "popover-title": asstProgress.studentAssessments.awaitingSubmission +
                                        "/" +
                                        total, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () {
                                        return openDrilldownView(String(asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.name), String(asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.uuid));
                                    } }), _jsx(WmChartSlice, { legend: awaitingAssessment, amount: String(asstProgress.studentAssessments.awaitingAssessment), popoverText: String(StudentAssessmentAwaiting(asstProgress)), "popover-title": asstProgress.studentAssessments.awaitingAssessment +
                                        "/" +
                                        total, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () {
                                        return openDrilldownView(String(asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.name), String(asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.uuid));
                                    } }), _jsx(WmChartSlice, { legend: done, amount: String(asstProgress.studentAssessments.done), popoverText: String(StudentAssessmentDone(asstProgress)), "popover-title": asstProgress.studentAssessments.done + "/" + total, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () {
                                        return openDrilldownView(String(asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.name), String(asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.uuid));
                                    } })] }) }) }, String(asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.name)));
            }), _jsx("div", { className: "no-activity-label", children: noActivityTerms === "" ? ("") : (_jsx("div", { className: "-label", children: translate("app.assessmentProgressChart.NoActivities", {
                        noActivityTerms: noActivityTerms
                    }) })) }), state.isDrilldownOpen ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "sr-only", id: "program-ilo-dialog-wrapper-flyout-top", tabIndex: 0 }), _jsx(OpenDrillDownViewSll, { setIsDrilldownOpen: setIsDrilldownOpen, drilldownName: state.drilldownName, subHeader: DD_SUB_HEADER, termUuid: state.termUuid }), _jsx("div", { className: "sr-only", id: "program-ilo-dialog-wrapper-flyout-bottom", tabIndex: 0 })] })) : ("")] }));
}
