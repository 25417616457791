var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useReducer } from "react";
import { sendRequest } from "../../common/http";
import MissionAndOutcomes from "./mission-and-outcomes/MissionAndOutcomes";
import "../common/Insights.scss";
import ProgramILOMappings from "./ProgramILOMappings";
import TopProgramsByCatalog from "./top-programs-by-catalog/TopProgramsByCatalog";
import translate from "src/i18n/translate";
import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import { getLoaderStatus } from "../common/helper";
import CurriculumProposals from "./CurriculumProposals";
var allComponentsLoaded = function (loadedComponents) {
    return (loadedComponents.programs &&
        loadedComponents.organizations &&
        loadedComponents.courses &&
        loadedComponents.programILOMappings &&
        loadedComponents.curriculumProposals &&
        loadedComponents.topProgramsByCatalog);
};
var defaultInstitutionAction = {
    type: "",
    component: "",
    csMappingId: null,
    data: {}
};
export default function Strategy(_a) {
    var reportingYears = _a.reportingYears, defaultReportingYear = _a.defaultReportingYear, ryApiError = _a.ryApiError, setStrategyTab = _a.setStrategyTab;
    var _b = useContext(AppContext), membership = _b.membership, setleads = _b.setleads, institution = _b.institution;
    var initialState = {
        loadedComponents: {
            programs: false,
            organizations: false,
            courses: false,
            programILOMappings: false,
            curriculumProposals: false,
            topProgramsByCatalog: false
        },
        csMappingId: null,
        showCurriculumProposals: false
    };
    var reducer = function (state, action) {
        switch (action.type) {
            case "setLoadedComponent":
                var component = action.component;
                var loadedComponents = state.loadedComponents;
                loadedComponents[component] = true;
                return __assign(__assign({}, state), { loadedComponents: loadedComponents });
            case "setCsMappingId":
                return __assign(__assign({}, state), { csMappingId: action.csMappingId });
            case "setShowCurriculumProposals":
                return __assign(__assign({}, state), { showCurriculumProposals: action.data.showCurriculumProposals });
            case "resetLoadedComponent":
                var newComponent = action.component;
                var newloadedComponents = state.loadedComponents;
                newloadedComponents[newComponent] = false;
                return __assign(__assign({}, state), { loadedComponents: newloadedComponents });
            default:
                return state;
        }
    };
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    useEffect(function () {
        if (institution === null || institution === void 0 ? void 0 : institution.isPssIntegrated) {
            var applyLeadsResult = function (result) {
                setleads(result);
            };
            sendRequest({ uri: "/leads", camelize: true }, applyLeadsResult);
        }
    }, [institution]);
    var onComponentLoad = function (component) {
        dispatch(__assign(__assign({}, defaultInstitutionAction), { type: "setLoadedComponent", component: component }));
    };
    var resetLoadedComponent = function (component) {
        dispatch(__assign(__assign({}, defaultInstitutionAction), { type: "resetLoadedComponent", component: component }));
    };
    useEffect(function () {
        var liveRegion = document.querySelector(".institution-status");
        getLoaderStatus(!allComponentsLoaded(state.loadedComponents), liveRegion);
    }, [allComponentsLoaded(state.loadedComponents)]);
    useEffect(function () {
        if (institution && institution.salesforceId !== "") {
            var applyResult = function (result) {
                if (!institution.isPssIntegrated && result.mappingId) {
                    setStrategyTab();
                }
                dispatch(__assign(__assign({}, defaultInstitutionAction), { type: "setCsMappingId", csMappingId: result.mappingId ? result.mappingId : null }));
            };
            sendRequest({
                uri: "/cs/institution_mapping?sfid=".concat(institution.salesforceId),
                camelize: true
            }, applyResult);
        }
    }, [institution && institution.salesforceId]);
    useEffect(function () {
        if (state.csMappingId) {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultInstitutionAction), { type: "setShowCurriculumProposals", data: result }));
            };
            sendRequest({
                uri: "/cs/institution_products?mapping_id=".concat(state.csMappingId),
                camelize: true
            }, applyResult);
        }
    }, [state.csMappingId]);
    if ((institution === null || institution === void 0 ? void 0 : institution.isPssIntegrated) || state.csMappingId) {
        return (_jsx(StrategyWidgets, { institution: institution, csMappingId: state.csMappingId, showCurriculumProposals: state.showCurriculumProposals, reportingYears: reportingYears, defaultReportingYear: defaultReportingYear, onComponentLoad: onComponentLoad, resetLoadedComponent: resetLoadedComponent, ryApiError: ryApiError }));
    }
    else {
        return _jsx(_Fragment, {});
    }
}
var StrategyWidgets = function (props) {
    var _a, _b;
    return (_jsx("section", { className: ((_a = props.institution) === null || _a === void 0 ? void 0 : _a.navigatedFrom) === "planning"
            ? "body wm-grid tab-body"
            : "body wm-grid tab-body-home", id: "section_insights", children: _jsxs("div", { children: [_jsx("span", { className: "sr-only institution-status", role: "status", "aria-live": "assertive" }), _jsxs("section", { className: "insights", children: [_jsx("div", { className: "institution-name", children: translate("app.strategyLabel") }), _jsxs("div", { className: "wm-grid-row", children: [((_b = props.institution) === null || _b === void 0 ? void 0 : _b.isPssIntegrated) ? (_jsxs(_Fragment, { children: [_jsx("div", { className: props.csMappingId
                                                ? "grid-container wm-grid-col-6 wm-grid-col-md-10"
                                                : "grid-container wm-grid-col-12 wm-grid-col-md-10", children: _jsx(MissionAndOutcomes, { onComponentLoad: props.onComponentLoad }) }), _jsx("div", { className: props.csMappingId
                                                ? "grid-container wm-grid-col-6 wm-grid-col-md-10"
                                                : "grid-container wm-grid-col-12 wm-grid-col-md-10", children: _jsx(ProgramILOMappings, { onComponentLoad: props.onComponentLoad }) })] })) : (_jsx(_Fragment, {})), props.csMappingId && props.showCurriculumProposals && (_jsx("div", { className: "grid-container wm-grid-col-6 wm-grid-col-md-10", children: _jsx(CurriculumProposals, { reportingYears: props.reportingYears, defaultReportingYear: props.defaultReportingYear, mappingId: props.csMappingId, onComponentLoad: props.onComponentLoad, resetLoadedComponent: props.resetLoadedComponent, ryApiError: props.ryApiError }) })), props.csMappingId && (_jsx("div", { className: props.showCurriculumProposals
                                        ? "grid-container wm-grid-col-6 wm-grid-col-md-10"
                                        : "grid-container wm-grid-col-12 wm-grid-col-md-10", children: _jsx(TopProgramsByCatalog, { mappingId: props.csMappingId, onComponentLoad: props.onComponentLoad }) }))] })] })] }) }));
};
