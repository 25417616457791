var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import translate from "src/i18n/translate";
import "./Header.scss";
import { useContext, useReducer, useEffect } from "react";
import { AppContext } from "../../../contexts/AppContext";
import { defaultOrganization } from "../interfaces/Interface";
import { sendRequest } from "src/common/http";
var REDIRECT_URI = runConfig.REDIRECT_URI;
function getOrganizationType(orgType) {
    return orgType ? orgType[0].toUpperCase() + orgType.slice(1) : "Institution";
}
function getPlanningUrl(organization, institution) {
    var orgUuid = (organization && organization.uuid) || (institution === null || institution === void 0 ? void 0 : institution.rootOrgUuid);
    return orgUuid
        ? "".concat(REDIRECT_URI, "/institution/").concat(institution === null || institution === void 0 ? void 0 : institution.pssUuid, "/org_identity/").concat(orgUuid)
        : "".concat(REDIRECT_URI);
}
function getInsightsUrl(organization, institution) {
    return institution && organization
        ? "/insights/planning/institution/".concat(institution.platformUuid, "/organization/").concat(organization.uuid)
        : "/";
}
var defaultAction = {
    type: "",
    pssOrganization: defaultOrganization
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setOrganization":
            return __assign(__assign({}, state), { pssOrganization: action.pssOrganization });
        default:
            return state;
    }
};
var initialState = {
    pssOrganization: defaultOrganization
};
export default function PssSideNav(_a) {
    var organization = _a.organization;
    var institution = useContext(AppContext).institution;
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    useEffect(function () {
        if (organization && organization.uuid !== "") {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setOrganization", pssOrganization: result }));
            };
            sendRequest({
                uri: "/org_insights/pss/organization/".concat(organization.uuid),
                camelize: true
            }, applyResult);
        }
    }, [organization]);
    var planningURI = state.pssOrganization || institution
        ? getPlanningUrl(state.pssOrganization, institution)
        : "";
    var sideNavBody = function () {
        var orgType = getOrganizationType(state.pssOrganization.type);
        var insightsUrl = getInsightsUrl(organization, institution);
        return (_jsx("div", { id: "new-side-nav", className: "org-side-nav", children: _jsx("div", { className: "nav-main", children: _jsx("nav", { id: "sidemainnav", className: "main-nav-org side-navs item-9", children: _jsxs("ul", { id: "sidenavmenu", className: "menu", children: [_jsx("li", { className: "menuitem active-parent", children: _jsx("a", { href: insightsUrl, id: "insights-nav", tabIndex: 0, className: "navlinks active", children: _jsxs("div", { className: "flex-row -verticallycentered -option", children: [_jsx("div", { className: "-icons mdi mdi-chart-bar" }), _jsx("div", { className: "menuname -name ", children: "Insights" })] }) }) }), _jsx("li", { className: "menuitem", children: _jsx("a", { href: planningURI + "?tab=inProgress", id: "in-progress-nav", tabIndex: 0, className: "navlinks", children: _jsxs("div", { className: "flex-row -verticallycentered -option", children: [_jsx("div", { className: "-icons mdi mdi-folder-edit-outline ", "aria-hidden": true }), _jsx("div", { className: "menuname -name ", children: translate("app.sidenav.inProgress") })] }) }) }), _jsx("li", { className: "menuitem", children: _jsx("a", { href: planningURI + "?tab=outcomes", id: "insitution-info-nav", tabIndex: 0, className: "navlinks", children: _jsxs("div", { className: "flex-row -verticallycentered -option", children: [_jsx("div", { className: "-icons mdi mdi-information-outline" }), _jsx("div", { className: "menuname -name ", children: translate("app.sidenav.orgTypeInformation", {
                                                    orgType: orgType
                                                }) })] }) }) }), institution &&
                                renderCurriculumMenu(institution.showCurriculumTab, planningURI, organization === null || organization === void 0 ? void 0 : organization.type), _jsx("li", { className: "menuitem", children: _jsx("a", { href: planningURI + "?tab=actions", id: "actions-nav", tabIndex: 0, className: "navlinks", children: _jsxs("div", { className: "flex-row -verticallycentered -option", children: [_jsx("div", { className: "-icons mdi mdi-checkbox-marked-circle-outline" }), _jsx("div", { className: "menuname -name ", children: translate("app.sidenav.actions") })] }) }) }), _jsx("li", { className: "menuitem", children: _jsx("a", { href: planningURI + "?tab=docs", id: "docs-reports-nav", tabIndex: 0, className: "navlinks", children: _jsxs("div", { className: "flex-row -verticallycentered -option", children: [_jsx("div", { className: "-icons mdi mdi-file-document-box-multiple-outline", "aria-hidden": true }), _jsx("div", { className: "menuname -name ", children: translate("app.sidenav.docs&Reports") })] }) }) })] }) }) }) }));
    };
    var renderCurriculumMenu = function (showCurriculumMenu, planningURI, organizationType) {
        if (showCurriculumMenu || organizationType === "program") {
            return (_jsx("li", { className: "menuitem", children: _jsx("a", { href: planningURI + "?tab=curriculum", id: "curriculum-nav", tabIndex: 0, className: "navlinks", children: _jsxs("div", { className: "flex-row -verticallycentered -option", children: [_jsx("div", { className: "-icons mdi mdi-book-outline", "aria-hidden": true }), _jsx("div", { className: "menuname -name ", children: translate("app.sidenav.curriculum") })] }) }) }));
        }
    };
    return _jsx(_Fragment, { children: sideNavBody() });
}
