var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useReducer } from "react";
import "../../../../app/App.scss";
import { applyPolyfills, defineCustomElements } from "@watermarkinsights/ripple/dist/loader";
import { IntlProvider } from "react-intl";
import ENGLISH from "../../../../i18n/languages/en-US.json";
import { pig } from "../../../../static/js/pig.js";
import Header from "../export/Header";
import OrganizationChart from "../export/OrganizationChart";
import { sendRequest } from "src/common/http";
import MissionAndOutcomesTable from "../export/MissionAndOutcomesTable";
import translate from "src/i18n/translate";
import Loader from "../../../common/elements/Loader";
import ProgramChart from "../export/ProgramsChart";
import ProgramsTable from "../export/ProgramsTable";
import CoursesChart from "../export/CoursesChart";
import CoursesTable from "../export/CoursesTable";
import UnauthorizedComponent from "src/features/insights/UnauthorizedComponent";
var defaultHierarchy = {
    level: 0,
    name: "",
    show_stats: false,
    status: "",
    type: "",
    uuid: "",
    children: [],
    outcomes: 0,
    ilo_mapped: 0
};
var missionAndOutcomesHtmlAction = {
    type: "",
    locale: "en-US",
    orgData: {
        totalOrgs: null,
        organizationMissionIncomplete: 0,
        organizationMissionComplete: 0,
        learningOutcomes: 0,
        learningOutcomesIncomplete: 0,
        otherOutcomes: 0,
        otherOutcomesIncomplete: 0,
        iloMappings: 0,
        iloMappingsIncomplete: 0
    },
    programData: {
        totalPrograms: null,
        programMissionComplete: 0,
        programMissionIncomplete: 0,
        programLearningOutcomes: 0,
        programLearningOutcomesIncomplete: 0,
        programSuccessOutcomes: 0,
        programSuccessOutcomesIncomplete: 0,
        programCurriculumMapsComplete: 0,
        programCurriculumMapsIncomplete: 0,
        iloProgramMappingsComplete: 0,
        iloProgramMappingsIncomplete: 0
    },
    missionHierarchy: {
        level: 0,
        name: "",
        show_stats: false,
        status: "",
        type: "",
        uuid: "",
        updated_at: "",
        children: []
    },
    successHierarchy: defaultHierarchy,
    learningHierarchy: defaultHierarchy,
    iloHierarchy: defaultHierarchy,
    programMissionHierarchy: null,
    programSuccessHierarchy: null,
    programLearningHierarchy: null,
    programIloHierarchy: null,
    programCurriculumHierarchy: null,
    leads: [],
    courseData: {
        courseIloComplete: 0,
        courseIloNotStarted: 0,
        courseLearningOutcomesComplete: 0,
        courseLearningOutcomesNotStarted: 0,
        totalCourses: null
    }
};
var initialState = {
    locale: "en-US",
    messages: null,
    totalOrgs: null,
    organizationMissionIncomplete: 0,
    organizationMissionComplete: 0,
    learningOutcomes: 0,
    learningOutcomesIncomplete: 0,
    otherOutcomes: 0,
    otherOutcomesIncomplete: 0,
    iloMappings: 0,
    iloMappingsIncomplete: 0,
    totalPrograms: null,
    programMissionComplete: 0,
    programMissionIncomplete: 0,
    programLearningOutcomes: 0,
    programLearningOutcomesIncomplete: 0,
    programSuccessOutcomes: 0,
    programSuccessOutcomesIncomplete: 0,
    programCurriculumMapsComplete: 0,
    programCurriculumMapsIncomplete: 0,
    iloProgramMappingsComplete: 0,
    iloProgramMappingsIncomplete: 0,
    missionHierarchy: {
        level: 0,
        name: "",
        show_stats: false,
        status: "",
        type: "",
        uuid: "",
        updated_at: "",
        children: []
    },
    successHierarchy: defaultHierarchy,
    learningHierarchy: defaultHierarchy,
    iloHierarchy: defaultHierarchy,
    programMissionHierarchy: null,
    programSuccessHierarchy: null,
    programLearningHierarchy: null,
    programIloHierarchy: null,
    programCurriculumHierarchy: [],
    leads: [],
    courseData: {
        courseIloComplete: 0,
        courseIloNotStarted: 0,
        courseLearningOutcomesComplete: 0,
        courseLearningOutcomesNotStarted: 0,
        totalCourses: null
    }
};
export default function MissionAndOutcomesExportView(_a) {
    var _b, _c, _d, _e, _f;
    var auth = _a.auth;
    applyPolyfills().then(function () {
        defineCustomElements();
    });
    var reducer = function (state, action) {
        switch (action.type) {
            case "setLocale":
                return setLocale(state, action.locale);
            case "setOrgData":
                return __assign(__assign({}, state), { totalOrgs: action.orgData.totalOrgs, organizationMissionIncomplete: action.orgData.organizationMissionIncomplete, organizationMissionComplete: action.orgData.organizationMissionComplete, learningOutcomes: action.orgData.learningOutcomes, learningOutcomesIncomplete: action.orgData.learningOutcomesIncomplete, otherOutcomes: action.orgData.otherOutcomes, otherOutcomesIncomplete: action.orgData.otherOutcomesIncomplete, iloMappings: action.orgData.iloMappings, iloMappingsIncomplete: action.orgData.iloMappingsIncomplete });
            case "setHierarchyMission":
                return __assign(__assign({}, state), { missionHierarchy: action.missionHierarchy });
            case "setHierarchyLearning":
                return __assign(__assign({}, state), { learningHierarchy: action.learningHierarchy });
            case "setHierarchySuccess":
                return __assign(__assign({}, state), { successHierarchy: action.successHierarchy });
            case "setHierarchyIlo":
                return __assign(__assign({}, state), { iloHierarchy: action.iloHierarchy });
            case "setLeads":
                return __assign(__assign({}, state), { leads: action.leads });
            case "setProgramData":
                return __assign(__assign({}, state), { totalPrograms: action.programData.totalPrograms, programMissionComplete: action.programData.programMissionComplete, programMissionIncomplete: action.programData.programMissionIncomplete, programLearningOutcomes: action.programData.programLearningOutcomes, programLearningOutcomesIncomplete: action.programData.programLearningOutcomesIncomplete, programSuccessOutcomes: action.programData.programSuccessOutcomes, programSuccessOutcomesIncomplete: action.programData.programSuccessOutcomesIncomplete, programCurriculumMapsComplete: action.programData.programCurriculumMapsComplete, programCurriculumMapsIncomplete: action.programData.programCurriculumMapsIncomplete, iloProgramMappingsComplete: action.programData.iloProgramMappingsComplete, iloProgramMappingsIncomplete: action.programData.iloProgramMappingsIncomplete });
            case "setCourseData":
                return __assign(__assign({}, state), { courseData: action.courseData });
            case "setHierarchyProgramMission":
                return __assign(__assign({}, state), { programMissionHierarchy: action.programMissionHierarchy });
            case "setHierarchyProgramLearning":
                return __assign(__assign({}, state), { programLearningHierarchy: action.programLearningHierarchy });
            case "setHierarchyProgramSuccess":
                return __assign(__assign({}, state), { programSuccessHierarchy: action.programSuccessHierarchy });
            case "setHierarchyProgramIlo":
                return __assign(__assign({}, state), { programIloHierarchy: action.programIloHierarchy });
            case "setHierarchyProgramCurriculum":
                return __assign(__assign({}, state), { programCurriculumHierarchy: action.programCurriculumHierarchy });
            default:
                return state;
        }
    };
    var setLocale = function (state, locale) {
        switch (locale) {
            case "pig": {
                return __assign(__assign({}, state), { locale: "en-US", messages: pig(ENGLISH) });
            }
            default: {
                return __assign(__assign({}, state), { locale: "en-US", messages: ENGLISH });
            }
        }
    };
    var _g = useReducer(reducer, initialState), state = _g[0], dispatch = _g[1];
    useEffect(function () {
        var queryString = window.location.search;
        var param = new URLSearchParams(queryString);
        var locale = param.get("locale");
        dispatch(__assign(__assign({}, missionAndOutcomesHtmlAction), { type: "setLocale", locale: locale }));
    }, []);
    useEffect(function () {
        var applyLeadsResult = function (result) {
            dispatch(__assign(__assign({}, missionAndOutcomesHtmlAction), { type: "setLeads", leads: result }));
        };
        auth && sendRequest({ uri: "/leads", camelize: true }, applyLeadsResult);
    }, [auth]);
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, missionAndOutcomesHtmlAction), { type: "setOrgData", orgData: result }));
        };
        auth &&
            sendRequest({ uri: "/mission_outcome/organizations", camelize: true }, applyResult);
    }, [auth]);
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, missionAndOutcomesHtmlAction), { type: "setProgramData", programData: result }));
        };
        auth &&
            sendRequest({ uri: "/mission_outcome/programs", camelize: true }, applyResult);
    }, [auth]);
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, missionAndOutcomesHtmlAction), { type: "setCourseData", courseData: result }));
        };
        auth &&
            sendRequest({ uri: "/mission_outcome/courses", camelize: true }, applyResult);
    }, [auth]);
    function getHierarchy(segment) {
        var applyResult = function (result) {
            switch (segment) {
                case "organization_mission": {
                    return dispatch(__assign(__assign({}, missionAndOutcomesHtmlAction), { type: "setHierarchyMission", missionHierarchy: result }));
                }
                case "organization_learning_outcome": {
                    return dispatch(__assign(__assign({}, missionAndOutcomesHtmlAction), { type: "setHierarchyLearning", learningHierarchy: result }));
                }
                case "organization_success_outcome": {
                    return dispatch(__assign(__assign({}, missionAndOutcomesHtmlAction), { type: "setHierarchySuccess", successHierarchy: result }));
                }
                case "organization_ilo_mapping": {
                    return dispatch(__assign(__assign({}, missionAndOutcomesHtmlAction), { type: "setHierarchyIlo", iloHierarchy: result }));
                }
            }
        };
        sendRequest({ uri: "/hierarchy?segment=".concat(segment) }, applyResult);
    }
    function getHierarchyProgram(segment) {
        var applyResult = function (result) {
            switch (segment) {
                case "program_mission": {
                    return dispatch(__assign(__assign({}, missionAndOutcomesHtmlAction), { type: "setHierarchyProgramMission", programMissionHierarchy: result }));
                }
                case "program_learning_outcomes": {
                    return dispatch(__assign(__assign({}, missionAndOutcomesHtmlAction), { type: "setHierarchyProgramLearning", programLearningHierarchy: result }));
                }
                case "program_success_outcomes": {
                    return dispatch(__assign(__assign({}, missionAndOutcomesHtmlAction), { type: "setHierarchyProgramSuccess", programSuccessHierarchy: result }));
                }
                case "program_ilo_mapping": {
                    return dispatch(__assign(__assign({}, missionAndOutcomesHtmlAction), { type: "setHierarchyProgramIlo", programIloHierarchy: result }));
                }
                case "program_cmap": {
                    return dispatch(__assign(__assign({}, missionAndOutcomesHtmlAction), { type: "setHierarchyProgramCurriculum", programCurriculumHierarchy: result }));
                }
            }
        };
        sendRequest({ uri: "/hierarchy?segment=".concat(segment) }, applyResult);
    }
    useEffect(function () {
        getHierarchy("organization_mission");
        getHierarchy("organization_learning_outcome");
        getHierarchy("organization_success_outcome");
        getHierarchy("organization_ilo_mapping");
    }, []);
    useEffect(function () {
        getHierarchyProgram("program_mission");
        getHierarchyProgram("program_learning_outcomes");
        getHierarchyProgram("program_success_outcomes");
        getHierarchyProgram("program_ilo_mapping");
        getHierarchyProgram("program_cmap");
    }, []);
    var showLoader = function () {
        return (!state.missionHierarchy.name ||
            !state.successHierarchy.name ||
            !state.learningHierarchy.name ||
            !state.iloHierarchy.name ||
            state.programMissionHierarchy === null ||
            state.programSuccessHierarchy == null ||
            state.programLearningHierarchy === null ||
            state.programIloHierarchy === null ||
            state.programCurriculumHierarchy === null ||
            state.totalOrgs === null ||
            state.totalPrograms === null ||
            state.courseData.totalCourses === null);
    };
    return (_jsx(_Fragment, { children: auth ? (_jsx("div", { className: "pagewrapper faculty-html-container", children: _jsx("div", { className: "insights-hub-body flex-row", children: _jsx("div", { className: "nav-main", children: _jsx("div", { className: "contentwrapper", children: _jsx("main", { id: "maincontent", className: "main-container", children: state.messages && (_jsx(IntlProvider, { locale: state.locale, messages: state.messages, children: _jsxs("div", { className: "faculty-html-container", children: [_jsx(Header, {}), showLoader() ? _jsx(Loader, {}) : "", _jsxs("section", { className: "-mainheader -withbreadcrumbs only-breadcrumbs body-padding", id: "page-header", "data-export-id": "mission-outcome-export", children: [state.totalOrgs ? (_jsxs(_Fragment, { children: [_jsx(OrganizationChart, { organizationData: state }), _jsxs("div", { className: "organization-border", children: [_jsx("div", { className: "title organization-heading", children: translate("app.strategy.exportHtmlSubHeaderTable", {
                                                                        type: "Organization"
                                                                    }) }), state.missionHierarchy.name &&
                                                                    state.successHierarchy.name &&
                                                                    state.learningHierarchy.name &&
                                                                    state.iloHierarchy.name && (_jsx("div", { className: "table-border", children: _jsx(MissionAndOutcomesTable, { missionHierarchy: state.missionHierarchy, successHierarchy: state.successHierarchy, learningHierarchy: state.learningHierarchy, iloHierarchy: state.iloHierarchy, leads: state.leads }) }))] })] })) : (""), state.totalPrograms ? (_jsxs(_Fragment, { children: [_jsx("div", { className: state.totalOrgs ? "program-border" : "", children: _jsx(ProgramChart, { programData: state }) }), _jsxs("div", { className: "organization-border", children: [_jsx("div", { className: "title organization-heading", children: translate("app.strategy.exportHtmlSubHeaderTable", {
                                                                        type: "Program"
                                                                    }) }), ((_b = state.programMissionHierarchy) === null || _b === void 0 ? void 0 : _b.length) &&
                                                                    ((_c = state.programSuccessHierarchy) === null || _c === void 0 ? void 0 : _c.length) &&
                                                                    ((_d = state.programLearningHierarchy) === null || _d === void 0 ? void 0 : _d.length) &&
                                                                    ((_e = state.programIloHierarchy) === null || _e === void 0 ? void 0 : _e.length) &&
                                                                    ((_f = state.programCurriculumHierarchy) === null || _f === void 0 ? void 0 : _f.length) ? (_jsx("div", { className: "table-border", children: _jsx(ProgramsTable, { missionHierarchy: state.programMissionHierarchy, successHierarchy: state.programSuccessHierarchy, learningHierarchy: state.programLearningHierarchy, iloHierarchy: state.programIloHierarchy, curriculumMap: state.programCurriculumHierarchy, leads: state.leads }) })) : ("")] })] })) : (""), state.courseData.totalCourses ? (_jsxs(_Fragment, { children: [_jsx("div", { className: state.totalOrgs || state.totalPrograms
                                                                ? "program-border"
                                                                : "", children: _jsx(CoursesChart, { courseData: state.courseData }) }), _jsxs("div", { className: "organization-border", children: [_jsx("div", { className: "title organization-heading", children: translate("app.strategy.exportHtmlSubHeaderTable", {
                                                                        type: "Course"
                                                                    }) }), _jsx("div", { className: "table-border courses-table margin-bottom-20", children: _jsx(CoursesTable, {}) })] })] })) : ("")] })] }) })) }) }) }) }) })) : (_jsx(UnauthorizedComponent, {})) }));
}
