var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useReducer, Fragment } from "react";
import "../../common/Insights.scss";
import OpenDrillDownView from "../../common/OpenDrillDownView";
import { openFlyout } from "../../../static/js/commons";
import { sendRequest } from "../../../common/http";
import { WmProgressIndicator, WmProgressMonitor, WmProgressSlice } from "@watermarkinsights/ripple-react";
import translate from "../../../i18n/translate";
import { useIntl } from "react-intl";
import ApiError from "src/features/common/elements/ApiError";
var popover = function (amount, total) {
    return "".concat(amount, "/").concat(total);
};
var initialState = {
    isDrilldownOpen: false,
    drilldownType: "",
    apiError: false,
    courseData: {
        totalCourses: 0,
        courseIloNotStarted: 0,
        courseIloComplete: 0,
        courseLearningOutcomesNotStarted: 0,
        courseLearningOutcomesComplete: 0
    }
};
var defaultCourseProgressCardAction = {
    type: "",
    segment: "",
    missionOutcomeCourse: {
        courseIloComplete: 0,
        courseIloNotStarted: 0,
        courseLearningOutcomesComplete: 0,
        courseLearningOutcomesNotStarted: 0,
        totalCourses: 0
    },
    apiError: false
};
var reducer = function (state, action) {
    switch (action.type) {
        case "ToggleDrilldown":
            return __assign(__assign({}, state), { isDrilldownOpen: !state.isDrilldownOpen });
        case "SetDrilldownType":
            return __assign(__assign({}, state), { drilldownType: action.segment });
        case "SetCourseData":
            return __assign(__assign({}, state), { courseData: action.missionOutcomeCourse });
        case "setApiError":
            return __assign(__assign({}, state), { apiError: action.apiError });
        default:
            return state;
    }
};
export default function CourseProgressCard(_a) {
    var onTabLoad = _a.onTabLoad, onComponentLoad = _a.onComponentLoad;
    var POPOVER_BUTTON_TEXT = useIntl().formatMessage({
        id: "app.courseCard.progressDrilldownCourse"
    });
    var DD_SUB_HEADER = useIntl().formatMessage({
        id: "app.orgProgressCard.missionOutcome"
    });
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var setIsDrilldownOpen = function () {
        dispatch(__assign(__assign({}, defaultCourseProgressCardAction), { type: "ToggleDrilldown" }));
    };
    var openDrilldownView = function (segment) {
        setTimeout(function () {
            return openFlyout("drill-down-view", "course-charts", "drill-down-close-btn");
        }, 400);
        setIsDrilldownOpen();
        dispatch(__assign(__assign({}, defaultCourseProgressCardAction), { type: "SetDrilldownType", segment: segment }));
    };
    useEffect(function () {
        var applyResult = function (missionOutcomeCourse) {
            dispatch(__assign(__assign({}, defaultCourseProgressCardAction), { type: "SetCourseData", missionOutcomeCourse: missionOutcomeCourse }));
            onTabLoad("courses");
            onComponentLoad("courses");
        };
        var onError = function () {
            dispatch(__assign(__assign({}, defaultCourseProgressCardAction), { type: "setApiError", apiError: true }));
            onTabLoad("courses");
            onComponentLoad("courses");
        };
        sendRequest({ uri: "/mission_outcome/courses", camelize: true }, applyResult, onError);
    }, []);
    var CourseProgressCardBody = function (courseData) {
        var created = useIntl().formatMessage({
            id: "app.drilldown.created"
        });
        var notCreated = useIntl().formatMessage({
            id: "app.drilldown.notCreated"
        });
        var mapped = useIntl().formatMessage({
            id: "app.drilldown.mapped"
        });
        var notMapped = useIntl().formatMessage({
            id: "app.drilldown.notMapped"
        });
        var completedPopoverText = useIntl().formatMessage({
            id: "app.courseCard.coursesLO"
        });
        var notStartedPopoverText = useIntl().formatMessage({
            id: "app.courseCard.coursesWithoutLO"
        });
        var learningOutcomes = useIntl().formatMessage({
            id: "app.orgProgressCard.learningOutcomes"
        });
        var iLOMapped = useIntl().formatMessage({
            id: "app.orgProgressCard.ILOMapped"
        });
        var courseChart = function () {
            return (_jsxs(WmProgressMonitor, { id: "course-progress", "bar-labels-width": "5rem", children: [_jsxs(WmProgressIndicator, { label: learningOutcomes, children: [_jsx(WmProgressSlice, { legend: created, amount: courseData.courseLearningOutcomesComplete.toString(), popoverTitle: popover(courseData.courseLearningOutcomesComplete, courseData.totalCourses), popoverText: completedPopoverText, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () { return openDrilldownView("course_learning_outcomes"); } }), _jsx(WmProgressSlice, { legend: notCreated, amount: courseData.courseLearningOutcomesNotStarted.toString(), popoverTitle: popover(courseData.courseLearningOutcomesNotStarted, courseData.totalCourses), popoverText: notStartedPopoverText, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () { return openDrilldownView("course_learning_outcomes"); } })] }), _jsxs(WmProgressIndicator, { label: iLOMapped, children: [_jsx(WmProgressSlice, { legend: mapped, amount: courseData.courseIloComplete.toString(), popoverTitle: popover(courseData.courseIloComplete, courseData.totalCourses), popoverText: completedPopoverText, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () { return openDrilldownView("course_ilo_mapping"); } }), _jsx(WmProgressSlice, { legend: notMapped, amount: courseData.courseIloNotStarted.toString(), popoverTitle: popover(courseData.courseIloNotStarted, courseData.totalCourses), popoverText: notStartedPopoverText, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () { return openDrilldownView("course_ilo_mapping"); } })] })] }));
        };
        return (_jsxs("div", { children: [_jsx("h4", { className: "title", children: translate("app.courseCard.courseProgress") }), _jsx("p", { className: "course totals", children: translate("app.courseCard.courseTotal", {
                        totalCourses: courseData.totalCourses
                    }) }), _jsx("div", { id: "course-charts", children: courseChart() })] }));
    };
    var coursesTotal = useIntl().formatMessage({ id: "app.courseCard.courseTotal" }, { totalCourses: state.courseData.totalCourses.toString() });
    return (_jsxs(_Fragment, { children: [state.apiError ? (_jsx(ApiError, { containerClasses: "api-error" })) : (CourseProgressCardBody(state.courseData)), state.isDrilldownOpen ? (_jsxs(Fragment, { children: [_jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-top", tabIndex: 0 }), _jsx(OpenDrillDownView, { setIsDrilldownOpen: setIsDrilldownOpen, drilldownType: state.drilldownType, total: coursesTotal, showTable: false, completeHierarchy: [], drilldownStatus: "any", subHeader: DD_SUB_HEADER, leads: [] }), _jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-top", tabIndex: 0 })] })) : (""), _jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-bottom", tabIndex: 0 })] }));
}
