import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import translate from "src/i18n/translate";
import { Table, formatDateFullYear } from "../../common/helper";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
var getTableColumns = function () {
    return [
        translate("app.settings.reportingYear"),
        translate("app.settings.dateRange"),
        translate("app.settings.Terms")
    ];
};
export var TableHeader = function (props) {
    return (_jsx("thead", { className: "dataheader", children: _jsxs("tr", { className: "dataheaderrow", children: [props.columns.map(function (columnName, index) {
                    return (_jsxs("th", { className: "dataheadercell", "data-th": columnName, children: [_jsx("span", { children: columnName }), index === 1 && (_jsx("span", { className: "mdi mdi-arrow-down sort-arrow-down" }))] }, "".concat(index, " ").concat(columnName)));
                }), _jsx("th", { className: "dataheadercell" })] }) }));
};
var TableBody = function (_a) {
    var reportingYears = _a.reportingYears, editReportingYearModal = _a.editReportingYearModal;
    var edit = useIntl().formatMessage({
        id: "app.settings.edit"
    });
    return (_jsx("tbody", { className: "databody", children: reportingYears === null || reportingYears === void 0 ? void 0 : reportingYears.map(function (reportingYear, index) {
            return (_jsxs("tr", { className: "datarow", children: [_jsxs("td", { className: "datacell", children: [_jsx("div", { children: reportingYear.name }), _jsx("div", { children: translate("app.settings.code", { code: reportingYear.code }) })] }), _jsx("td", { className: "datacell", children: "".concat(formatDateFullYear(reportingYear.startDate), " - ").concat(formatDateFullYear(reportingYear.endDate)) }), _jsx("td", { className: "datacell", children: reportingYear.terms.map(function (term) {
                            return _jsx(RenderTerms, { term: term }, "".concat(term.uuid));
                        }) }), _jsx("td", { className: "datacell datacell-icon", children: _jsx(WmButton, { "button-type": "icononly", tooltip: "".concat(edit, " ").concat(reportingYear.name), icon: "f3eb", "icon-size": "18px", onClick: function () { return editReportingYearModal(reportingYear); } }) })] }, "reporting year - ".concat(reportingYear.uuid)));
        }) }));
};
function RenderTerms(_a) {
    var term = _a.term;
    return (_jsx("div", { children: "".concat(term.name, " ( ").concat(formatDateFullYear(term.startDate), " - ").concat(formatDateFullYear(term.endDate), " )") }));
}
export default function ReportingYearTable(_a) {
    var reportingYears = _a.reportingYears, editReportingYearModal = _a.editReportingYearModal;
    return (_jsxs(Table, { children: [_jsx(TableHeader, { columns: getTableColumns() }), _jsx(TableBody, { reportingYears: reportingYears, editReportingYearModal: editReportingYearModal })] }));
}
