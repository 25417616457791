var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useReducer, useContext, Fragment } from "react";
import "./../common/Insights.scss";
import { AppContext } from "../../contexts/AppContext";
import translate from "src/i18n/translate";
import { sendRequest } from "../../common/http";
import { WmButton, WmOption, WmSelect } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import { formatDateFullYear } from "../common/helper";
import { openFlyout } from "../../../src/static/js/commons";
import { WmProgressMonitor, WmProgressSlice, WmProgressIndicator } from "@watermarkinsights/ripple-react";
import OpenDrillDownViewResponseRate from "./OpenDrillDownViewResponseRate";
import WidgetLoader from "../common/elements/WidgetLoader";
import ApiError from "../common/elements/ApiError";
var defaultCourseEvaluation = function () {
    return {
        endDate: "",
        projectStatus: null,
        startDate: "",
        title: "",
        uuid: null,
        surveyId: null,
        daysRemaining: 0,
        default: false
    };
};
var responseRateDefaultAction = {
    type: "",
    selectedCourseEvaluation: defaultCourseEvaluation(),
    isDrilldownOpen: false,
    courseEvaluations: [],
    awaitingResponseRate: 0,
    respondedResponseRate: 0,
    averageResponseRate: null,
    averageDifference: 0,
    isAverageDifferencePositive: true,
    previousProject: null,
    hierarchy: {},
    showTable: false,
    loader: true,
    projectsApiError: false,
    responseRateApiError: false
};
export default function ResponseRate(_a) {
    var selectedReportingYear = _a.selectedReportingYear, reportingYears = _a.reportingYears, onComponentLoad = _a.onComponentLoad, resetLoadedComponent = _a.resetLoadedComponent;
    var getSelectedCourseEvaluation = function (courseEvaluations) {
        if (courseEvaluations[selectedReportingYear["uuid"]] !== undefined &&
            courseEvaluations[selectedReportingYear["uuid"]].length > 0) {
            return courseEvaluations[selectedReportingYear["uuid"]].filter(function (ce) { return ce.default; })[0];
        }
        else {
            return defaultCourseEvaluation();
        }
    };
    var institution = useContext(AppContext).institution;
    var initialState = {
        selectedCourseEvaluation: defaultCourseEvaluation(),
        isDrilldownOpen: false,
        courseEvaluations: [],
        awaitingResponseRate: 0,
        respondedResponseRate: 0,
        averageResponseRate: null,
        averageDifference: 0,
        isAverageDifferencePositive: true,
        previousProject: null,
        hierarchy: {},
        showTable: false,
        loader: true,
        projectsApiError: false,
        responseRateApiError: false
    };
    var reducer = function (state, action) {
        switch (action.type) {
            case "setCourseEvaluations":
                return __assign(__assign({}, state), { courseEvaluations: action.courseEvaluations, selectedCourseEvaluation: getSelectedCourseEvaluation(action.courseEvaluations) });
            case "setResponseRate":
                return __assign(__assign({}, state), { loader: false, awaitingResponseRate: action.awaitingResponseRate, respondedResponseRate: action.respondedResponseRate, averageResponseRate: action.averageResponseRate, averageDifference: action.averageDifference, isAverageDifferencePositive: action.isAverageDifferencePositive, previousProject: action.previousProject });
            case "showLoader":
                return __assign(__assign({}, state), { loader: true });
            case "selectCourseEvaluation":
                return __assign(__assign({}, state), { selectedCourseEvaluation: action.selectedCourseEvaluation });
            case "ToggleDrilldown":
                return __assign(__assign({}, state), { isDrilldownOpen: !state.isDrilldownOpen });
            case "setHierarchy":
                return __assign(__assign({}, state), { hierarchy: action.hierarchy, showTable: true });
            case "showTable":
                return __assign(__assign({}, state), { showTable: action.showTable });
            case "setResponseRateApiError":
                return __assign(__assign({}, state), { responseRateApiError: action.responseRateApiError, loader: false });
            case "setProjectsApiError":
                return __assign(__assign({}, state), { projectsApiError: action.projectsApiError, loader: false });
            default:
                return state;
        }
    };
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var filterReportingYear = function (reportingYears) {
        return reportingYears === null || reportingYears === void 0 ? void 0 : reportingYears.map(function (reportingYear) {
            return {
                end_date: reportingYear.endDate,
                uuid: reportingYear.uuid,
                start_date: reportingYear.startDate
            };
        });
    };
    var setIsDrilldownOpen = function () {
        dispatch(__assign(__assign({}, responseRateDefaultAction), { type: "ToggleDrilldown" }));
    };
    var openDrilldownView = function () {
        setTimeout(function () {
            return openFlyout("drill-down-view", "course-charts", "drill-down-close-btn");
        }, 400);
        setIsDrilldownOpen();
    };
    var resetApiErrors = function () {
        dispatch(__assign(__assign({}, responseRateDefaultAction), { type: "setResponseRateApiError", responseRateApiError: false }));
        dispatch(__assign(__assign({}, responseRateDefaultAction), { type: "setProjectsApiError", projectsApiError: false }));
    };
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, responseRateDefaultAction), { type: "setCourseEvaluations", courseEvaluations: result }));
        };
        var onError = function () {
            dispatch(__assign(__assign({}, responseRateDefaultAction), { type: "setProjectsApiError", projectsApiError: true }));
            onComponentLoad("responseRate");
        };
        sendRequest({
            uri: "/ces/projects?reporting_years=".concat(JSON.stringify(filterReportingYear(reportingYears))),
            camelize: true
        }, applyResult, onError);
    }, []);
    useEffect(function () {
        var _a, _b;
        if (((_a = state.selectedCourseEvaluation) === null || _a === void 0 ? void 0 : _a.surveyId) != null &&
            ((_b = state.selectedCourseEvaluation) === null || _b === void 0 ? void 0 : _b.uuid) != null) {
            resetApiErrors();
            dispatch(__assign(__assign({}, responseRateDefaultAction), { type: "showLoader" }));
            resetLoadedComponent("responseRate");
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, responseRateDefaultAction), { type: "setResponseRate", awaitingResponseRate: result["awaiting"], respondedResponseRate: result["responded"], averageResponseRate: result["average"], averageDifference: result["averageDifference"], isAverageDifferencePositive: result["isAverageDifferencePositive"], previousProject: result["previousProject"] }));
                onComponentLoad("responseRate");
            };
            var onError = function () {
                dispatch(__assign(__assign({}, responseRateDefaultAction), { type: "setResponseRateApiError", responseRateApiError: true }));
                onComponentLoad("responseRate");
            };
            sendRequest({
                uri: "/ces/response_rate/survey/".concat(state.selectedCourseEvaluation.surveyId, "/project/").concat(state.selectedCourseEvaluation.uuid),
                camelize: true
            }, applyResult, onError);
        }
        else {
            onComponentLoad("responseRate");
        }
    }, [state.selectedCourseEvaluation]);
    var getDrilldownHierarchy = function () {
        dispatch(__assign(__assign({}, responseRateDefaultAction), { type: "showTable", showTable: false }));
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, responseRateDefaultAction), { type: "setHierarchy", hierarchy: result }));
        };
        openDrilldownView();
        sendRequest({
            uri: "/ces/node_response_rate/project/".concat(state.selectedCourseEvaluation.uuid),
            camelize: true
        }, applyResult);
    };
    var openDrilldown = function () {
        getDrilldownHierarchy();
    };
    useEffect(function () {
        dispatch(__assign(__assign({}, responseRateDefaultAction), { type: "selectCourseEvaluation", selectedCourseEvaluation: getSelectedCourseEvaluation(state.courseEvaluations) }));
    }, [selectedReportingYear]);
    var filter = useIntl().formatMessage({
        id: "app.ces.responseRate.filter"
    });
    var openHtmlView = function () {
        var locale = new URLSearchParams(window.location.search).get("locale");
        var url = "/insights/ces/survey/".concat(state.selectedCourseEvaluation.surveyId, "/project/").concat(state.selectedCourseEvaluation.uuid, "/html");
        url = locale ? "".concat(url, "?locale=").concat(locale) : url;
        return window.open(url, "_blank");
    };
    var CourseEvaluationSelect = function (_a) {
        var courseEvaluations = _a.courseEvaluations, selectedCourseEvaluation = _a.selectedCourseEvaluation;
        return (_jsx(WmSelect, { label: filter, labelPosition: "none", id: "filter-by-course-evaluation", search: true, children: courseEvaluations.map(function (ce) {
                var _a;
                return (_jsx(WmOption, { selected: ce.uuid === selectedCourseEvaluation.uuid, onClick: function () {
                        return dispatch(__assign(__assign({}, responseRateDefaultAction), { type: "selectCourseEvaluation", selectedCourseEvaluation: ce }));
                    }, id: (_a = ce["uuid"]) === null || _a === void 0 ? void 0 : _a.toString(), children: ce["title"] }, ce["uuid"]));
            }) }));
    };
    var noCourseBody = function () {
        return state.courseEvaluations[selectedReportingYear["uuid"]].length === 0
            ? "no-course-body"
            : "";
    };
    var CourseEvaluationStatus = function (_a) {
        var status = _a.status;
        switch (status) {
            case 3:
                return (_jsx("div", { className: "in-progress-status", children: _jsx("div", { className: "title-text", children: translate("app.drilldown.inProgress") }) }));
            case 4:
                return (_jsx("div", { className: "ended-status -completed", children: _jsx("div", { className: "title-text", children: translate("app.drilldown.ended") }) }));
            default:
                return _jsx(_Fragment, {});
        }
    };
    var respondedText = useIntl().formatMessage({
        id: "app.ces.responseRate.responded"
    });
    var popoverRespondedText = useIntl().formatMessage({
        id: "app.ces.responseRate.respondedPopover"
    });
    var popoverAwaitingText = useIntl().formatMessage({
        id: "app.ces.responseRate.respondedAwaitingPopover"
    });
    var popoverButtonText = useIntl().formatMessage({
        id: "app.ces.responseRate.popoverButtonText"
    });
    var awaitingText = useIntl().formatMessage({
        id: "app.ces.responseRate.awaiting"
    });
    var respondedAwaiting = useIntl().formatMessage({
        id: "app.ces.responseRate.respondedAwaiting"
    });
    var responseRate = useIntl().formatMessage({
        id: "app.ces.responseRate.responseRate"
    });
    var responseRateFullWidthLabel = useIntl().formatMessage({
        id: "app.ces.responseRate.overallResponseRate"
    });
    var responseRatesBody = function () {
        var _a, _b;
        return (_jsxs("div", { className: "paper-card", children: [_jsxs("div", { className: "header", children: [_jsxs("div", { className: "info", children: [_jsx("h3", { className: "title", children: translate("app.ces.responseRate.courseEvaluationResponseRate") }), _jsx("p", { className: "description", children: translate("app.ces.responseRate.description") })] }), state.courseEvaluations[selectedReportingYear["uuid"]] &&
                            state.courseEvaluations[selectedReportingYear["uuid"]].length > 0 &&
                            !state.loader &&
                            !state.responseRateApiError ? (_jsx("div", { children: _jsx(WmButton, { id: "export-button", "aria-controls": "acc-listbox", "aria-expanded": "false", tabIndex: 0, onClick: openHtmlView, "button-type": "secondary", children: translate("app.exportHtml") }) })) : ("")] }), state.courseEvaluations[selectedReportingYear["uuid"]] !==
                    undefined && (_jsxs("div", { className: "body ".concat(noCourseBody()), children: [state.courseEvaluations[selectedReportingYear["uuid"]].length >
                            0 &&
                            state.selectedCourseEvaluation &&
                            state.selectedCourseEvaluation["startDate"] && (_jsxs("div", { className: "", children: [_jsxs("div", { className: "plan-dropdown-container-ces flex-row", children: [_jsx("span", { children: translate("app.ces.responseRate.courseEvaluation") }), _jsx("div", { className: "plan-dropdown-ces", children: _jsx(CourseEvaluationSelect, { courseEvaluations: state.courseEvaluations[selectedReportingYear["uuid"]], selectedCourseEvaluation: state.selectedCourseEvaluation }) })] }), _jsxs(_Fragment, { children: [_jsxs("div", { className: (institution === null || institution === void 0 ? void 0 : institution.isPssIntegrated)
                                                ? "flex-row -justified plan-status-container"
                                                : "flex-row status-margin plan-status-container", children: [_jsx("div", { className: "plan-title", children: state.selectedCourseEvaluation["title"] }), _jsx(CourseEvaluationStatus, { status: state.selectedCourseEvaluation["projectStatus"] })] }), _jsxs("div", { className: "ces-duration", children: [_jsx("div", { className: "ces-details", children: translate("app.ces.responseRate.details") }), _jsxs("div", { className: "ces-dates", children: [_jsx("span", { className: "mdi mdi-calendar-clock" }), "".concat(formatDateFullYear(state.selectedCourseEvaluation["startDate"]), " -\n                    ").concat(formatDateFullYear(state.selectedCourseEvaluation["endDate"]))] }), state.selectedCourseEvaluation["daysRemaining"] > 0 && (_jsxs("div", { className: "ces-days", children: [_jsx("span", { className: "mdi mdi-timer-sand" }), translate("app.ces.responseRate.daysRemaining", {
                                                            daysRemaining: state.selectedCourseEvaluation["daysRemaining"]
                                                        })] }))] }), state.loader && (_jsx("div", { className: "loader", children: _jsx("div", { className: "content content-loader" }) })), !state.loader && !state.responseRateApiError && (_jsxs("div", { className: (institution === null || institution === void 0 ? void 0 : institution.isPssIntegrated)
                                                ? "response-rate-container"
                                                : "full-width-container flex-row", children: [(institution === null || institution === void 0 ? void 0 : institution.isPssIntegrated) && (_jsx("div", { className: "response-rate-label", children: translate("app.ces.responseRate.overallResponseRate") })), _jsx("div", { className: "response-rate-chart", children: _jsx("div", { className: "organization-progress-chart", children: _jsx(WmProgressMonitor, { id: "organization-progress", "group-legend": respondedAwaiting, breakpoint: (institution === null || institution === void 0 ? void 0 : institution.isPssIntegrated) ? "10px" : "0", children: _jsxs(WmProgressIndicator, { label: (institution === null || institution === void 0 ? void 0 : institution.isPssIntegrated)
                                                                    ? responseRate
                                                                    : responseRateFullWidthLabel, children: [_jsx(WmProgressSlice, { amount: (_a = state.respondedResponseRate) === null || _a === void 0 ? void 0 : _a.toString(), "popover-text": popoverRespondedText, legend: respondedText, "popover-button-text": popoverButtonText, "popover-title": "".concat(state.respondedResponseRate, "%"), onClick: function () { return openDrilldown(); }, title: state.selectedCourseEvaluation["title"] }), _jsx(WmProgressSlice, { amount: (_b = state.awaitingResponseRate) === null || _b === void 0 ? void 0 : _b.toString(), "popover-text": popoverAwaitingText, legend: awaitingText, "popover-button-text": popoverButtonText, "popover-title": "".concat(state.awaitingResponseRate, "%"), onClick: function () { return openDrilldown(); }, title: state.selectedCourseEvaluation["title"] })] }) }) }) }), _jsx("div", { className: "response-rate-divider", children: _jsxs("div", { className: "response-rate-trends", children: [_jsx("div", { className: "response-rate-trends-heading", children: translate("app.ces.responseRate.responseRateTrends") }), state.averageResponseRate !== null && (_jsxs("div", { className: "response-rate-trends-container", children: [_jsxs("div", { className: "response-rate-all-courses", children: [_jsxs("div", { className: "flex-row", children: [_jsx("span", { className: "-icons mdi ".concat(arrow(state.isAverageDifferencePositive)) }), _jsxs("div", { className: "comparison-rate-all-courses", children: [state.averageDifference, "%"] })] }), _jsx("div", { className: "response-rate-trends-desc", children: translate("app.ces.responseRate.responseRateTrendsDescriptionAllCourses", {
                                                                                    comparisonRate: state.averageResponseRate
                                                                                }) })] }), _jsxs("div", { className: "response-rate-previous-course", children: [state.previousProject && (_jsxs("div", { className: "flex-row", children: [_jsx("span", { className: "-icons mdi ".concat(arrow(state.previousProject.isDiffPositive)) }), _jsxs("div", { className: "comparison-rate-all-courses", children: [state.previousProject
                                                                                                .diffInResponseRate, "%"] })] })), state.previousProject && (_jsx("div", { className: "response-rate-trends-desc", children: translate("app.ces.responseRate.responseRateTrendsDescriptionPreviousCourse", {
                                                                                    previousProjectTitle: state.previousProject.title,
                                                                                    comparisonRate: state.previousProject.responseRate
                                                                                }) }))] })] })), state.averageResponseRate === null && (_jsxs("div", { className: "flex-row no-response-rate", children: [_jsx("span", { className: "-icons mdi mdi-timer-sand" }), _jsx("div", { className: "no-response-rate-text", children: translate("app.ces.responseRate.NoResponseRateText") })] }))] }) })] })), !state.loader && state.responseRateApiError && (_jsx(ApiError, {}))] })] })), state.courseEvaluations[selectedReportingYear["uuid"]].length ===
                            0 && NoCourseEvaluations()] })), state.courseEvaluations.length === 0 && !state.projectsApiError && (_jsx(WidgetLoader, { loaderContainerClasses: "loader", loaderClasses: "content" })), state.projectsApiError && _jsx(ApiError, {}), state.courseEvaluations["course_evaluation"] === null && (_jsx("h3", { className: "title", children: _jsx(ApiError, {}) }))] }));
    };
    return (_jsxs(_Fragment, { children: [(institution === null || institution === void 0 ? void 0 : institution.showCourseEvaluation) && responseRatesBody(), state.isDrilldownOpen ? (_jsxs(Fragment, { children: [_jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-top", tabIndex: 0 }), _jsx(OpenDrillDownViewResponseRate, { setIsDrilldownOpen: setIsDrilldownOpen, title: state.selectedCourseEvaluation["title"], remainingDays: state.selectedCourseEvaluation["daysRemaining"], startDate: state.selectedCourseEvaluation["startDate"], endDate: state.selectedCourseEvaluation["endDate"], status: state.selectedCourseEvaluation["projectStatus"], showTable: state.showTable, completeHierarchy: state.hierarchy }), _jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-top", tabIndex: 0 })] })) : (""), _jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-bottom", tabIndex: 0 })] }));
}
var NoCourseEvaluations = function () {
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "no-plans-container", children: [_jsx("div", { className: "no-plans-ces", children: _jsx("div", { className: "no-plans-text-ces", children: translate("app.ces.responseRate.noCourseEvaluations") }) }), _jsx("div", { className: "no-plans-ces", children: _jsx("div", { className: "no-plans-text-select-ces", children: translate("app.ces.responseRate.SelectDifferentAcademicYear") }) })] }) }));
};
export var arrow = function (isDiffPositive) {
    return isDiffPositive ? "mdi-arrow-up" : "mdi-arrow-down";
};
