var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmDatepicker, WmInput } from "@watermarkinsights/ripple-react";
import { forwardRef, useEffect, useImperativeHandle, useReducer } from "react";
import { useIntl } from "react-intl";
import { sendRequestWithPayload, sendRequest } from "src/common/http";
import { formatDateIso, formatDateUsToIso } from "src/features/common/helper";
var defaultFieldsErrorMessages = {
    titleError: "",
    codeError: "",
    startDateError: "",
    endDateError: ""
};
var initialState = {
    reportingYearFields: {
        title: "",
        code: "",
        startDate: "",
        endDate: "",
        reportingYearType: "academic"
    },
    fieldsErrorMessages: defaultFieldsErrorMessages,
    token: ""
};
var defaultAction = {
    type: "",
    title: "",
    titleError: "",
    code: "",
    codeError: "",
    startDate: "",
    startDateError: "",
    endDate: "",
    endDateError: "",
    token: "",
    updateReportingYear: null
};
var formatDate = function (date) {
    var d = date.split("-");
    return d[1] + "/" + d[2] + "/" + d[0];
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setTitle":
            return __assign(__assign({}, state), { reportingYearFields: __assign(__assign({}, state.reportingYearFields), { title: action.title }), fieldsErrorMessages: __assign(__assign({}, state.fieldsErrorMessages), { titleError: "" }) });
        case "setTitleError":
            return __assign(__assign({}, state), { fieldsErrorMessages: __assign(__assign({}, state.fieldsErrorMessages), { titleError: action.titleError }) });
        case "setCode":
            return __assign(__assign({}, state), { reportingYearFields: __assign(__assign({}, state.reportingYearFields), { code: action.code }), fieldsErrorMessages: __assign(__assign({}, state.fieldsErrorMessages), { codeError: "" }) });
        case "setCodeError":
            return __assign(__assign({}, state), { fieldsErrorMessages: __assign(__assign({}, state.fieldsErrorMessages), { codeError: action.codeError }) });
        case "setStartDate":
            return __assign(__assign({}, state), { reportingYearFields: __assign(__assign({}, state.reportingYearFields), { startDate: action.startDate }), fieldsErrorMessages: __assign(__assign({}, state.fieldsErrorMessages), { startDateError: "" }) });
        case "setStartDateError":
            return __assign(__assign({}, state), { fieldsErrorMessages: __assign(__assign({}, state.fieldsErrorMessages), { startDateError: action.startDateError }) });
        case "setEndDate":
            return __assign(__assign({}, state), { reportingYearFields: __assign(__assign({}, state.reportingYearFields), { endDate: action.endDate }), fieldsErrorMessages: __assign(__assign({}, state.fieldsErrorMessages), { endDateError: "" }) });
        case "setEndDateError":
            return __assign(__assign({}, state), { fieldsErrorMessages: __assign(__assign({}, state.fieldsErrorMessages), { endDateError: action.endDateError }) });
        case "resetFormInputs":
            return {
                reportingYearFields: {
                    title: "",
                    code: "",
                    startDate: "",
                    endDate: ""
                },
                fieldsErrorMessages: {
                    titleError: "",
                    codeError: "",
                    startDateError: "",
                    endDateError: ""
                }
            };
        case "setReportingYearFields":
            return {
                reportingYearFields: {
                    title: action.updateReportingYear
                        ? action.updateReportingYear.name
                        : "",
                    code: action.updateReportingYear
                        ? action.updateReportingYear.code
                        : "",
                    startDate: action.updateReportingYear
                        ? formatDate(action.updateReportingYear.startDate)
                        : "",
                    endDate: action.updateReportingYear
                        ? formatDate(action.updateReportingYear.endDate)
                        : ""
                },
                fieldsErrorMessages: {
                    titleError: "",
                    codeError: "",
                    startDateError: "",
                    endDateError: ""
                }
            };
        case "setToken":
            return __assign(__assign({}, state), { token: action.token });
        default:
            return state;
    }
};
var CreateReportingYearForm = forwardRef(function (_a, ref) {
    var reportingYears = _a.reportingYears, openReportingYearModalState = _a.openReportingYearModalState, closeReportingYearModal = _a.closeReportingYearModal, fetchReportingYears = _a.fetchReportingYears, updateReportingYear = _a.updateReportingYear, addSnackBar = _a.addSnackBar;
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    useImperativeHandle(ref, function () { return ({
        getCsrfToken: getCsrfToken
    }); });
    var reportingYearFields = state.reportingYearFields, fieldsErrorMessages = state.fieldsErrorMessages;
    var titleLabel = useIntl().formatMessage({
        id: "app.settings.reportingYearForm.titleLabel"
    });
    var startDateLabel = useIntl().formatMessage({
        id: "app.settings.reportingYearForm.startDateLabel"
    });
    var endDateLabel = useIntl().formatMessage({
        id: "app.settings.reportingYearForm.endDateLabel"
    });
    var codeLabel = useIntl().formatMessage({
        id: "app.settings.reportingYearForm.codeLabel"
    });
    var titleInfo = useIntl().formatMessage({
        id: "app.settings.reportingYearForm.titleInfo"
    });
    var codeInfo = useIntl().formatMessage({
        id: "app.settings.reportingYearForm.codeInfo"
    });
    var titleRequired = useIntl().formatMessage({
        id: "app.settings.reportingYearForm.titleRequired"
    });
    var uniqueTitle = useIntl().formatMessage({
        id: "app.settings.reportingYearForm.uniqueTitle"
    });
    var codeRequired = useIntl().formatMessage({
        id: "app.settings.reportingYearForm.codeRequired"
    });
    var uniqueCode = useIntl().formatMessage({
        id: "app.settings.reportingYearForm.uniqueCode"
    });
    var dateRequired = useIntl().formatMessage({
        id: "app.settings.reportingYearForm.dateRequired"
    });
    var invalidDate = useIntl().formatMessage({
        id: "app.settings.reportingYearForm.invalidDate"
    });
    var overlappedDateError = useIntl().formatMessage({
        id: "app.settings.reportingYearForm.overlappedDateError"
    });
    var startDateGreater = useIntl().formatMessage({
        id: "app.settings.reportingYearForm.startDateGreater"
    });
    var endDateLesser = useIntl().formatMessage({
        id: "app.settings.reportingYearForm.endDateLesser"
    });
    var handleTitleChange = function (event) {
        dispatch(__assign(__assign({}, defaultAction), { type: "setTitle", title: event.target.value }));
    };
    var handleCodeChange = function (event) {
        dispatch(__assign(__assign({}, defaultAction), { type: "setCode", code: event.target.value }));
    };
    var handleStartDateChange = function (event, selectedBy) {
        if (selectedBy === "datePicker") {
            resetOverlappedEndDateError();
            dispatch(__assign(__assign({}, defaultAction), { type: "setStartDate", startDate: formatDateIso(event.detail.date) }));
            if (reportingYearFields.endDate) {
                validateReportingYearStartDate(formatDateIso(event.detail.date), reportingYearFields.endDate);
            }
        }
        else {
            dispatch(__assign(__assign({}, defaultAction), { type: "setStartDate", startDate: event.target.value }));
        }
    };
    var handleEndDateChange = function (event, selectedBy) {
        if (selectedBy === "datePicker") {
            resetOverlappedStartDateError();
            dispatch(__assign(__assign({}, defaultAction), { type: "setEndDate", endDate: formatDateIso(event.detail.date) }));
            if (reportingYearFields.startDate) {
                validateReportingYearEndDate(formatDateIso(event.detail.date), reportingYearFields.startDate);
            }
        }
        else {
            dispatch(__assign(__assign({}, defaultAction), { type: "setEndDate", endDate: event.target.value }));
        }
    };
    function validateReportingYearTitle(title) {
        if (title.trim() === "") {
            dispatch(__assign(__assign({}, defaultAction), { type: "setTitleError", titleError: titleRequired }));
        }
        else {
            var isDuplicate = reportingYears === null || reportingYears === void 0 ? void 0 : reportingYears.filter(function (year) { return year.uuid !== (updateReportingYear === null || updateReportingYear === void 0 ? void 0 : updateReportingYear.uuid); }).some(function (item) {
                return item.name.toUpperCase().trim() === title.toUpperCase().trim();
            });
            if (isDuplicate) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setTitleError", titleError: uniqueTitle }));
            }
        }
    }
    function validateReportingYearCode(code) {
        if (code.trim() === "") {
            dispatch(__assign(__assign({}, defaultAction), { type: "setCodeError", codeError: codeRequired }));
        }
        else {
            var isDuplicate = reportingYears === null || reportingYears === void 0 ? void 0 : reportingYears.filter(function (year) { return year.uuid !== (updateReportingYear === null || updateReportingYear === void 0 ? void 0 : updateReportingYear.uuid); }).some(function (item) {
                return item.code.toUpperCase().trim() === code.toUpperCase().trim();
            });
            if (isDuplicate) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setCodeError", codeError: uniqueCode }));
            }
        }
    }
    function validateReportingYearStartDate(startDate, endDate) {
        if (fieldsErrorMessages.endDateError === endDateLesser) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setEndDateError", endDateError: "" }));
        }
        if (startDate.trim() === "") {
            dispatch(__assign(__assign({}, defaultAction), { type: "setStartDateError", startDateError: dateRequired }));
        }
        else if (!isValidDateFormat(startDate)) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setStartDateError", startDateError: invalidDate }));
        }
        else if (startDate &&
            endDate &&
            isValidDateFormat(endDate) &&
            isValidDateFormat(startDate) &&
            new Date(startDate) >= new Date(endDate)) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setStartDateError", startDateError: startDateGreater }));
        }
        else if (startDate &&
            endDate &&
            isValidDateFormat(endDate) &&
            isValidDateFormat(startDate) &&
            (reportingYears === null || reportingYears === void 0 ? void 0 : reportingYears.filter(function (year) { return year.uuid !== (updateReportingYear === null || updateReportingYear === void 0 ? void 0 : updateReportingYear.uuid); }).some(function (year) {
                return isDateRangeOverlap(year.startDate, year.endDate, startDate, endDate);
            }))) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setStartDateError", startDateError: overlappedDateError }));
            dispatch(__assign(__assign({}, defaultAction), { type: "setEndDateError", endDateError: overlappedDateError }));
        }
    }
    function validateReportingYearEndDate(endDate, startDate) {
        if (fieldsErrorMessages.startDateError === startDateGreater) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setStartDateError", startDateError: "" }));
        }
        if (endDate.trim() === "") {
            dispatch(__assign(__assign({}, defaultAction), { type: "setEndDateError", endDateError: dateRequired }));
        }
        else if (!isValidDateFormat(endDate)) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setEndDateError", endDateError: invalidDate }));
        }
        else if (startDate &&
            endDate &&
            isValidDateFormat(endDate) &&
            isValidDateFormat(startDate) &&
            new Date(startDate) >= new Date(endDate)) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setEndDateError", endDateError: endDateLesser }));
        }
        else if (startDate &&
            endDate &&
            isValidDateFormat(endDate) &&
            isValidDateFormat(startDate) &&
            (reportingYears === null || reportingYears === void 0 ? void 0 : reportingYears.filter(function (year) { return year.uuid !== (updateReportingYear === null || updateReportingYear === void 0 ? void 0 : updateReportingYear.uuid); }).some(function (year) {
                return isDateRangeOverlap(year.startDate, year.endDate, startDate, endDate);
            }))) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setEndDateError", endDateError: overlappedDateError }));
            dispatch(__assign(__assign({}, defaultAction), { type: "setStartDateError", startDateError: overlappedDateError }));
        }
    }
    var handleTitleBlur = function () {
        validateReportingYearTitle(reportingYearFields.title);
    };
    var handleCodeBlur = function () {
        validateReportingYearCode(reportingYearFields.code);
    };
    function resetOverlappedEndDateError() {
        if (state.fieldsErrorMessages.endDateError === overlappedDateError) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setEndDateError", endDateError: "" }));
        }
    }
    function resetOverlappedStartDateError() {
        if (state.fieldsErrorMessages.startDateError === overlappedDateError) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setStartDateError", startDateError: "" }));
        }
    }
    var handleStartDateBlur = function () {
        resetOverlappedEndDateError();
        validateReportingYearStartDate(reportingYearFields.startDate, reportingYearFields.endDate);
    };
    var handleEndDateBlur = function () {
        resetOverlappedStartDateError();
        validateReportingYearEndDate(reportingYearFields.endDate, reportingYearFields.startDate);
    };
    var isDateRangeOverlap = function (existingStartDate, existingEndDate, newStartDate, newEndDate) {
        var existingStart = new Date(existingStartDate).getTime();
        var existingEnd = new Date(existingEndDate).getTime();
        var newStart = new Date(newStartDate).getTime();
        var newEnd = new Date(newEndDate).getTime();
        return ((existingStart <= newStart && newStart <= existingEnd) ||
            (existingStart <= newEnd && newEnd <= existingEnd) ||
            (newStart <= existingStart && existingStart <= newEnd) ||
            (newStart <= existingEnd && existingEnd <= newEnd));
    };
    var isValidDateFormat = function (dateString) {
        var pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
        return pattern.test(dateString);
    };
    useEffect(function () {
        dispatch(__assign(__assign({}, defaultAction), { type: "resetFormInputs" }));
    }, [openReportingYearModalState]);
    useEffect(function () {
        dispatch(__assign(__assign({}, defaultAction), { type: "setReportingYearFields", updateReportingYear: updateReportingYear }));
    }, [updateReportingYear]);
    function validateAllFields() {
        if (reportingYearFields.title.trim() === "") {
            dispatch(__assign(__assign({}, defaultAction), { type: "setTitleError", titleError: titleRequired }));
        }
        if (reportingYearFields.code.trim() === "") {
            dispatch(__assign(__assign({}, defaultAction), { type: "setCodeError", codeError: codeRequired }));
        }
        if (reportingYearFields.startDate.trim() === "") {
            dispatch(__assign(__assign({}, defaultAction), { type: "setStartDateError", startDateError: dateRequired }));
        }
        if (reportingYearFields.endDate.trim() === "") {
            dispatch(__assign(__assign({}, defaultAction), { type: "setEndDateError", endDateError: dateRequired }));
        }
        return (reportingYearFields.title &&
            reportingYearFields.code &&
            reportingYearFields.startDate &&
            reportingYearFields.endDate &&
            !fieldsErrorMessages.titleError &&
            !fieldsErrorMessages.codeError &&
            !fieldsErrorMessages.startDateError &&
            !fieldsErrorMessages.endDateError);
    }
    function getCsrfToken() {
        if (validateAllFields()) {
            var applyResultToken = function (result) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setToken", token: result.token }));
            };
            var onError = function (error) {
                // Log error in console for debugging
                console.log("API Error:", error);
                dispatch(__assign(__assign({}, defaultAction), { type: "setToken", token: null }));
            };
            sendRequest({
                uri: "/export/token",
                camelize: true
            }, applyResultToken, onError);
        }
        else {
            if (fieldsErrorMessages.titleError ||
                reportingYearFields.title.trim() === "") {
                focusField("#title-input");
            }
            else if (fieldsErrorMessages.codeError ||
                reportingYearFields.code.trim() === "") {
                focusField("#code-input");
            }
            else if (fieldsErrorMessages.startDateError ||
                reportingYearFields.startDate === "") {
                focusField("#start-date-input");
            }
            else if (fieldsErrorMessages.endDateError ||
                reportingYearFields.endDate === "") {
                focusField("#end-date-input");
            }
        }
    }
    function createUpdateReportingYear() {
        var formData = new FormData();
        formData.append("name", reportingYearFields.title);
        formData.append("code", reportingYearFields.code);
        if (reportingYearFields.reportingYearType) {
            formData.append("type", reportingYearFields.reportingYearType);
        }
        formData.append("start_date", formatDateUsToIso(reportingYearFields.startDate));
        formData.append("end_date", formatDateUsToIso(reportingYearFields.endDate));
        formData.append("_csrf_token", state.token ? state.token : "");
        var applyResult = function () {
            closeReportingYearModal();
            fetchReportingYears();
            setTimeout(function () {
                updateReportingYear
                    ? addSnackBar(reportingYearFields.title, "update")
                    : addSnackBar(reportingYearFields.title, "create");
            }, 1000);
        };
        var ryCreateUpdateUrl = "/sa/reporting_years";
        if (updateReportingYear) {
            ryCreateUpdateUrl = ryCreateUpdateUrl + "/".concat(updateReportingYear.uuid);
        }
        sendRequestWithPayload({
            uri: ryCreateUpdateUrl,
            body: formData
        }, applyResult);
    }
    function focusField(elementId) {
        var inputElement = document.querySelector(elementId);
        setTimeout(function () {
            inputElement.focus();
        }, 300);
    }
    useEffect(function () {
        if (state.token) {
            createUpdateReportingYear();
        }
    }, [state.token]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "input-margin", children: _jsx(WmInput, { label: titleLabel, type: "text", value: reportingYearFields.title, "required-field": true, "error-message": fieldsErrorMessages.titleError, info: fieldsErrorMessages.titleError ? "" : titleInfo, onInput: function (e) { return handleTitleChange(e); }, onBlur: handleTitleBlur, "character-limit": 50, id: "titleinput" }) }), _jsx("div", { className: "input-margin", children: _jsx(WmInput, { label: codeLabel, value: reportingYearFields.code, type: "text", "required-field": true, "error-message": fieldsErrorMessages.codeError, info: fieldsErrorMessages.codeError ? "" : codeInfo, onInput: function (e) { return handleCodeChange(e); }, onBlur: handleCodeBlur, "character-limit": 25, id: "code-input" }) }), _jsxs("div", { className: "flex-row date-inputs", children: [_jsx("div", { className: "date", children: _jsx(WmDatepicker, { "date-format": "mm/dd/yyyy", label: startDateLabel, "required-field": true, value: reportingYearFields.startDate, onInput: function (e) { return handleStartDateChange(e, "typed"); }, errorMessage: fieldsErrorMessages.startDateError, onWmDatepickerDateSelected: function (e) {
                                handleStartDateChange(e, "datePicker");
                            }, onWmDatepickerInputBlurred: handleStartDateBlur, id: "start-date-input" }) }), _jsx("div", { className: "date", children: _jsx(WmDatepicker, { "date-format": "mm/dd/yyyy", label: endDateLabel, value: reportingYearFields.endDate, "required-field": true, onInput: function (e) { return handleEndDateChange(e, "typed"); }, errorMessage: fieldsErrorMessages.endDateError, onWmDatepickerInputBlurred: handleEndDateBlur, onWmDatepickerDateSelected: function (e) {
                                handleEndDateChange(e, "datePicker");
                            }, id: "end-date-input" }) })] })] }));
});
export default CreateReportingYearForm;
