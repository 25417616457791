import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmModal, WmModalFooter, WmModalHeader } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import translate from "../../i18n/translate";
import { useContext } from "react";
import { AppContext } from "src/contexts/AppContext";
export default function PreviewModal() {
    var _a = useContext(AppContext), closePreviewModal = _a.closePreviewModal, componentLoaded = _a.componentLoaded;
    var previewModalPrimaryBtn = useIntl().formatMessage({
        id: "app.lightcast.previewModalPrimaryBtn"
    });
    var previewModalHeading = useIntl().formatMessage({
        id: "app.lightcast.previewModalHeading"
    });
    return (_jsx("div", { id: "preview-modal", children: componentLoaded.openPreviewModal ? (_jsxs(WmModal, { open: componentLoaded.openPreviewModal, id: "my-preview-modal", elementToFocus: "primary", "aria-label": previewModalHeading, modalType: "dialog", uid: "my-preview-modal", onWmModalCloseTriggered: closePreviewModal, onWmModalPrimaryTriggered: closePreviewModal, children: [_jsx(WmModalHeader, { heading: previewModalHeading }), _jsx("div", { className: "modal-content body", id: "content-preview-model", children: _jsxs("div", { className: "preview-content", children: [_jsx("div", { className: "para-text", children: translate("app.lightcast.previewModalPara1") }), _jsx("div", { className: "para-text", children: translate("app.lightcast.previewModalPara2") })] }) }), _jsx(WmModalFooter, { primaryText: previewModalPrimaryBtn, deleteStyle: false })] })) : ("") }));
}
