import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmChart, WmChartSlice } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
export default function BarChart(_a) {
    var notStartedCount = _a.notStartedCount, inProgressCount = _a.inProgressCount, submittedCount = _a.submittedCount, totalCount = _a.totalCount, notStartedText = _a.notStartedText, inProgressText = _a.inProgressText, inProgressLegend = _a.inProgressLegend, completedText = _a.completedText;
    var notStarted = useIntl().formatMessage({
        id: "app.drilldown.notStarted"
    });
    var submitted = useIntl().formatMessage({
        id: "app.drilldown.submitted"
    });
    return (_jsx("div", { className: "chart", children: _jsxs(WmChart, { chartType: "bar4", label: " ", valueFormat: "amount", "print-mode": true, children: [_jsx(WmChartSlice, { amount: notStartedCount, legend: notStarted, "popover-title": notStartedCount + "/" + totalCount, "popover-text": notStartedText }, "not_started_count"), _jsx(WmChartSlice, { amount: inProgressCount, legend: inProgressLegend, "popover-title": inProgressCount + "/" + totalCount, "popover-text": inProgressText }, "in_progress_count"), _jsx(WmChartSlice, { amount: submittedCount, legend: submitted, "popover-title": submittedCount + "/" + totalCount, "popover-text": completedText }, "Completed")] }, "bar4-chart") }));
}
