import { jsx as _jsx } from "react/jsx-runtime";
import { WmChart, WmChartSlice } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
function useFormatMessage(msgId) {
    return useIntl().formatMessage({
        id: msgId
    });
}
export var AccomplishmentsChart = function (_a) {
    var accomplishments = _a.accomplishments, forHtml = _a.forHtml;
    var chartLabel = useFormatMessage("app.faculty.accomplishmentbyScholarship");
    var contracts = useFormatMessage("app.faculty.legends.contracts");
    var performanceExhibits = useFormatMessage("app.faculty.legends.performanceExhibits");
    var presentations = useFormatMessage("app.faculty.legends.presentations");
    var publications = useFormatMessage("app.faculty.legends.publications");
    var intellectualProperty = useFormatMessage("app.faculty.legends.intellectualProperty");
    var contractsPopoverText = useFormatMessage("app.faculty.popover.contracts");
    var performanceExhibitsPopoverText = useFormatMessage("app.faculty.popover.performanceExhibits");
    var presentationsPopoverText = useFormatMessage("app.faculty.popover.presentations");
    var publicationsPopoverText = useFormatMessage("app.faculty.popover.publications");
    var intellectualPropertyPopoverText = useFormatMessage("app.faculty.popover.intellectualProperty");
    var getLegendAndPopoverText = function (category) {
        switch (category) {
            case "CONGRANT":
                return [contracts, contractsPopoverText];
            case "PERFORM_EXHIBIT":
                return [performanceExhibits, performanceExhibitsPopoverText];
            case "PRESENT":
                return [presentations, presentationsPopoverText];
            case "INTELLCONT":
                return [publications, publicationsPopoverText];
            case "INTELLPROP":
                return [intellectualProperty, intellectualPropertyPopoverText];
            default:
                return [category, category];
        }
    };
    return (_jsx(WmChart, { chartType: "bar5", label: forHtml ? " " : chartLabel, valueFormat: "amount", id: "scholarship-summary-chart", children: accomplishments.map(function (acc) {
            var _a = getLegendAndPopoverText(acc.category), legend = _a[0], popoverText = _a[1];
            return (_jsx(WmChartSlice, { amount: "".concat(acc.count), legend: legend, "popover-title": "".concat(acc.count), "popover-text": popoverText }, acc.category));
        }) }, "scholarship-summary-chart"));
};
