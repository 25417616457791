import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmChart, WmChartSlice } from "@watermarkinsights/ripple-react";
import translate from "src/i18n/translate";
import { useIntl } from "react-intl";
import { decode } from "html-entities";
export default function AssessmentProgressChart(_a) {
    var assessmentProgress = _a.assessmentProgress, noActivityTerms = _a.noActivityTerms;
    var awaitingSubmission = useIntl().formatMessage({
        id: "app.assessmentProgressChart.awaitingSubmission"
    });
    var awaitingAssessment = useIntl().formatMessage({
        id: "app.assessmentProgressChart.awaitingAssessment"
    });
    var done = useIntl().formatMessage({
        id: "app.done"
    });
    function useStudentAwaitingSubmission(asstProgress) {
        return useIntl().formatMessage({ id: "app.assessmentProgressChart.popoverStatusText" }, { statusLabel: awaitingSubmission, labelText: asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.name });
    }
    var StudentAwaitingSubmission = function (asstProgress) { return useStudentAwaitingSubmission(asstProgress); };
    function useTotalText(asstProgress, total) {
        return useIntl().formatMessage({ id: "app.assessmentProgressChart.total" }, { asstProgressName: asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.name, total: total });
    }
    var TotalText = function (asstProgress, total) { return useTotalText(asstProgress, total); };
    function useStudentAssessmentDone(asstProgress) {
        return useIntl().formatMessage({ id: "app.assessmentProgressChart.popoverStatusText" }, { statusLabel: done, labelText: asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.name });
    }
    var StudentAssessmentDone = function (asstProgress) { return useStudentAssessmentDone(asstProgress); };
    function useStudentAssessmentAwaiting(asstProgress) {
        return useIntl().formatMessage({ id: "app.assessmentProgressChart.popoverStatusText" }, { statusLabel: awaitingAssessment, labelText: asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.name });
    }
    var StudentAssessmentAwaiting = function (asstProgress) { return useStudentAssessmentAwaiting(asstProgress); };
    var studentAssessmentByStatus = useIntl().formatMessage({
        id: "app.openDrillDownViewSll.studentAssessmentsbyStatus"
    });
    var totalActivities = useIntl().formatMessage({
        id: "app.openDrillDownViewSll.totalActivities"
    });
    var organizationLabel = useIntl().formatMessage({
        id: "app.openDrillDownViewSll.organization"
    });
    var commonAssessmentVal = useIntl().formatMessage({
        id: "app.openDrillDownViewSll.commonAssessmentVal"
    });
    var getColumnName = function () {
        return [
            commonAssessmentVal,
            organizationLabel,
            totalActivities,
            studentAssessmentByStatus
        ];
    };
    var Table = function (props) {
        return _jsx("table", { className: "data-table -shadow", children: props.children });
    };
    var TableHeader = function (props) {
        return (_jsx("thead", { className: "dataheader", children: _jsx("tr", { className: "dataheaderrow", children: props.columns.map(function (column, index) {
                    return (_jsx("th", { className: "dataheadercell", "data-th": column, children: column }, "column-".concat(index)));
                }) }) }));
    };
    var TableBody = function (props) {
        return _jsx("tbody", { className: "databody", children: props.children });
    };
    var RenderStudentAssessmentByTermChart = function (props) {
        var awaitingSubmission = useIntl().formatMessage({
            id: "app.assessmentProgressChart.awaitingSubmission"
        });
        var awaitingAssessment = useIntl().formatMessage({
            id: "app.assessmentProgressChart.awaitingAssessment"
        });
        var done = useIntl().formatMessage({
            id: "app.done"
        });
        function usePopTextByStatus(status, labelText) {
            return useIntl().formatMessage({
                id: "app.assessmentProgressChart.popoverStatusText"
            }, { statusLabel: status, labelText: labelText });
        }
        function useGetLabelNameVal(labelText) {
            return useIntl().formatMessage({
                id: "app.assessmentProgressChart.labelNameText"
            }, { labelName: labelText });
        }
        var total = Number(Object.values(props.stuAssessment).reduce(function (newVal, oldVal) { return (newVal = newVal + oldVal); }, 0));
        return (_jsxs(WmChart, { "chart-type": "bar4", "value-format": "amount", "show-legend": "false", label: useGetLabelNameVal(props.labelText), "print-mode": true, children: [_jsx(WmChartSlice, { legend: awaitingSubmission, amount: String(props.stuAssessment.awaitingSubmission), popoverText: usePopTextByStatus(awaitingSubmission, props.labelText), "popover-title": props.stuAssessment.awaitingSubmission + "/" + total }), _jsx(WmChartSlice, { legend: awaitingAssessment, amount: String(props.stuAssessment.awaitingAssessment), popoverText: usePopTextByStatus(awaitingAssessment, props.labelText), "popover-title": props.stuAssessment.awaitingAssessment + "/" + total }), _jsx(WmChartSlice, { legend: done, amount: String(props.stuAssessment.done), popoverText: usePopTextByStatus(done, props.labelText), "popover-title": props.stuAssessment.done + "/" + total })] }));
    };
    var renderSingleOrganization = function (organizations) {
        return organizations.map(function (name) {
            return _jsx("div", { children: decode(name) }, name);
        });
    };
    var hierarchyTableBody = function (hierarchy) {
        return (_jsx("div", { className: "org-table sll-table-export", children: _jsxs(Table, { children: [_jsx(TableHeader, { columns: getColumnName() }), _jsx(TableBody, { children: hierarchy.map(function (node, index) {
                            return (_jsxs("tr", { className: "datarow", children: [_jsx("td", { className: "datacell", children: decode(node === null || node === void 0 ? void 0 : node.name) }), _jsx("td", { className: "datacell", children: renderSingleOrganization(node === null || node === void 0 ? void 0 : node.orgs) }), _jsx("td", { className: "datacell", children: node === null || node === void 0 ? void 0 : node.totalActivities }), _jsx("td", { className: "datacell", children: _jsx(RenderStudentAssessmentByTermChart, { stuAssessment: node === null || node === void 0 ? void 0 : node.studentAssessments, labelText: decode(node === null || node === void 0 ? void 0 : node.name) }) })] }, index));
                        }) })] }) }));
    };
    return (_jsxs("div", { className: "sll-chart-html", id: "assessment-progress-chart", children: [_jsx("div", { className: "progress-term-label", children: translate("app.assessmentProgressChart.studentAssessmentProgress") }), _jsx("div", { className: "legend-wrapper", children: _jsxs("div", { className: "legend", "aria-hidden": "true", children: [_jsxs("div", { className: "legend-item legend-item-html", children: [_jsx("div", { className: "legend-color legend1 -legend-wrap" }), _jsx("div", { className: "legend-text", children: translate("app.assessmentProgressChart.awaitingSubmission") })] }), _jsxs("div", { className: "legend-item legend-item-html", children: [_jsx("div", { className: "legend-color legend2 -legend-wrap" }), _jsx("div", { className: "legend-text", children: translate("app.assessmentProgressChart.awaitingAssessment") })] }), _jsxs("div", { className: "legend-item", children: [_jsx("div", { className: "legend-color legend3" }), _jsx("div", { className: "legend-text", children: translate("app.done") })] })] }) }), _jsxs("div", { children: [assessmentProgress.map(function (asstProgress) {
                        var total = Number(Object.values(asstProgress.studentAssessments).reduce(function (newVal, oldVal) { return (newVal = newVal + oldVal); }, 0));
                        return (_jsxs("div", { className: "sll-chart-container", children: [_jsx("div", { className: "chart-space", children: _jsxs(WmChart, { "chart-type": "bar4", "value-format": "amount", "show-legend": "false", label: String(TotalText(asstProgress, String(total))), "print-mode": true, children: [_jsx(WmChartSlice, { legend: awaitingSubmission, amount: String(asstProgress.studentAssessments.awaitingSubmission), popoverText: String(StudentAwaitingSubmission(asstProgress)), "popover-title": asstProgress.studentAssessments.awaitingSubmission +
                                                    "/" +
                                                    total }), _jsx(WmChartSlice, { legend: awaitingAssessment, amount: String(asstProgress.studentAssessments.awaitingAssessment), popoverText: String(StudentAssessmentAwaiting(asstProgress)), "popover-title": asstProgress.studentAssessments.awaitingAssessment +
                                                    "/" +
                                                    total }), _jsx(WmChartSlice, { legend: done, amount: String(asstProgress.studentAssessments.done), popoverText: String(StudentAssessmentDone(asstProgress)), "popover-title": asstProgress.studentAssessments.done + "/" + total })] }) }), _jsx("div", { children: _jsxs("section", { className: "body", children: [_jsx("div", { className: "flex-row -justified -verticallycentered", children: _jsxs("div", { id: "organisation-details-html", children: [_jsx("div", { className: "count", children: translate("app.openDrillDownViewSll.orgProgCount", {
                                                                orgCount: asstProgress.activityDetails.orgsCount
                                                            }) }), _jsx("div", { className: "count", children: translate("app.openDrillDownViewSll.commonAssessmentCount", {
                                                                commonAssessmentCount: asstProgress.activityDetails.commonAssessments
                                                                    .length
                                                            }) })] }) }), hierarchyTableBody(asstProgress.activityDetails.commonAssessments)] }) })] }, String(asstProgress === null || asstProgress === void 0 ? void 0 : asstProgress.name)));
                    }), noActivityTerms === "" ? ("") : (_jsx("div", { className: "no-activity-label no-activity-label-html", children: _jsx("div", { className: "-label", children: translate("app.assessmentProgressChart.NoActivities", {
                                noActivityTerms: noActivityTerms
                            }) }) }))] })] }));
}
