var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import translate from "src/i18n/translate";
import "./Lightcast.scss";
import { formatNumber } from "../../../src/static/js/commons";
import { useEffect, useReducer } from "react";
import { sendRequest } from "src/common/http";
import WidgetLoader from "../common/elements/WidgetLoader";
import ApiError from "../common/elements/ApiError";
var getIncreaseDecrease = function (growthState) {
    return growthState
        ? translate("app.trends.increased")
        : translate("app.trends.decreased");
};
var getIncreasingDeclining = function (inclined) {
    return inclined
        ? translate("app.trends.increasing")
        : translate("app.trends.declining");
};
var getArrowIcon = function (growthIncrease) {
    return growthIncrease ? "mdi-arrow-up" : "mdi-arrow-down";
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setLaborForceBreakdownAndTrends":
            if (action.lft) {
                return __assign(__assign({}, state), { populationTrends: action.lft.populationTrends, jobTrends: action.lft.jobTrends, laborForceTrends: action.lft.laborForceTrends, timeframe: action.lft.timeframe, loader: false });
            }
            else {
                return __assign(__assign({}, state), { btError: true, loader: false });
            }
        default:
            return state;
    }
};
var initialState = {
    populationTrends: null,
    jobTrends: null,
    laborForceTrends: null,
    timeframe: null,
    loader: true,
    btError: false
};
export default function Trends(_a) {
    var setLaborForceBreakdown = _a.setLaborForceBreakdown, onComponentLoad = _a.onComponentLoad;
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    useEffect(function () {
        var applyResult = function (result) {
            dispatch({ type: "setLaborForceBreakdownAndTrends", lft: result });
            var lfb = result ? result.laborForceBreakdown : null;
            setLaborForceBreakdown(lfb);
            onComponentLoad("trendsAndLaborforce");
        };
        var onError = function () {
            dispatch({
                type: "setLaborForceBreakdownAndTrends",
                lft: null
            });
            onComponentLoad("regionalContext");
        };
        sendRequest({ uri: "/lightcast/labor_force_breakdown_and_trends", camelize: true }, applyResult, onError);
    }, []);
    return (_jsxs("div", { className: "paper-card paper-card-height", children: [_jsx("div", { className: "header", children: _jsxs("div", { className: "info", children: [_jsx("h3", { className: "title", children: translate("app.trends.trendsWidgetTitle") }), _jsx("p", { className: "description -des", children: translate("app.trends.trendsWidgetDescription") })] }) }), !state.loader && !state.btError && (_jsxs("div", { className: "body wm-grid-row trends-widget", children: [_jsx(PopulationTrends, { populationTrends: state.populationTrends, timeframe: state.timeframe }), _jsx(JobTrends, { jobTrends: state.jobTrends, timeframe: state.timeframe }), _jsx(LaborForceTrends, { laborForceTrends: state.laborForceTrends, timeframe: state.timeframe }), _jsx(UnemploymentTrends, { laborForceTrends: state.laborForceTrends, timeframe: state.timeframe })] })), state.loader && (_jsx(WidgetLoader, { loaderContainerClasses: "loader", loaderClasses: "content" })), state.btError && _jsx(ApiError, {})] }));
}
export var PopulationTrends = function (_a) {
    var populationTrends = _a.populationTrends, timeframe = _a.timeframe;
    return (_jsx("div", { className: "wm-grid-col wm-grid-col-3 wm-grid-col-lg-3 wm-grid-col-md-10 wm-grid-col-sm-8 -trend", children: populationTrends && (_jsxs("div", { className: "-trend-value", children: [_jsx("div", { className: "label-name", children: translate("app.trends.population") }), _jsxs("div", { className: "-percentage flex-row", children: [_jsx("i", { className: "-icon mdi -midnight ".concat(getArrowIcon(populationTrends.inclined)) }), _jsx("div", { children: "".concat(populationTrends.changePercentage, "%") })] }), _jsx("div", { children: timeframe &&
                        translate("app.trends.populationTrendDescription", {
                            populationFromDate: timeframe.pastYear,
                            populationToDate: timeframe.currentYear,
                            populationGrowthIncrease: getIncreaseDecrease(populationTrends.inclined),
                            populationChangePercentage: populationTrends.changePercentage,
                            overallStatus: getIncreasingDeclining(populationTrends.inclined),
                            changeInPopulation: formatNumber(populationTrends.change)
                        }) })] })) }));
};
export var JobTrends = function (_a) {
    var jobTrends = _a.jobTrends, timeframe = _a.timeframe;
    return (_jsx("div", { className: "wm-grid-col wm-grid-col-3 wm-grid-col-lg-3 wm-grid-col-md-10 wm-grid-col-sm-8 -trend", children: jobTrends && (_jsxs("div", { className: "-trend-value", children: [_jsx("div", { className: "label-name", children: translate("app.trends.jobs") }), _jsxs("div", { className: "-percentage flex-row", children: [_jsx("i", { className: "-icon mdi -midnight ".concat(getArrowIcon(jobTrends.jobsGrowthIncrease)) }), _jsx("div", { children: "".concat(jobTrends.jobsPercentage, "%") })] }), _jsx("div", { children: timeframe &&
                        translate("app.trends.jobsTrendDescription", {
                            jobsFromDate: timeframe.pastYear,
                            jobsToDate: timeframe.currentYear,
                            jobsGrowthIncrease: getIncreaseDecrease(jobTrends.jobsGrowthIncrease),
                            jobsPercentage: jobTrends.jobsPercentage,
                            jobsFromTotalCount: formatNumber(jobTrends.jobsFromTotalCount),
                            jobsToTotalCount: formatNumber(jobTrends.jobsToTotalCount),
                            JobsNationalRateGrowthIncrease: getIncreaseDecrease(jobTrends.JobsNationalRateGrowthIncrease),
                            jobsNationalRatePercentage: jobTrends.jobsNationalRatePercentage
                        }) })] })) }));
};
export var LaborForceTrends = function (_a) {
    var laborForceTrends = _a.laborForceTrends, timeframe = _a.timeframe;
    return (_jsx("div", { className: "wm-grid-col wm-grid-col-3 wm-grid-col-lg-3 wm-grid-col-md-10 wm-grid-col-sm-8 -trend", children: laborForceTrends && (_jsxs("div", { className: "-trend-value", children: [_jsx("div", { className: "label-name", children: translate("app.trends.laborForce") }), _jsxs("div", { className: "-percentage flex-row", children: [_jsx("i", { className: "-icon mdi -midnight ".concat(getArrowIcon(laborForceTrends.laborForceGrowthIncrease)) }), _jsx("div", { children: "".concat(laborForceTrends.laborForcePercentage, "%") })] }), _jsx("div", { children: timeframe &&
                        translate("app.trends.laborForceDescription", {
                            laborForceFromDate: timeframe.pastYear,
                            laborForceToDate: timeframe.currentYear,
                            laborForceGrowthIncrease: getIncreaseDecrease(laborForceTrends.laborForceGrowthIncrease),
                            laborForcePercentage: laborForceTrends.laborForcePercentage,
                            laborForceGrowthPercentageFrom: laborForceTrends.laborForceGrowthPercentageFrom,
                            laborForceGrowthPercentageTo: laborForceTrends.laborForceGrowthPercentageTo
                        }) })] })) }));
};
export var UnemploymentTrends = function (_a) {
    var laborForceTrends = _a.laborForceTrends, timeframe = _a.timeframe;
    return (_jsx("div", { className: "wm-grid-col wm-grid-col-3 wm-grid-col-lg-3 wm-grid-col-md-10 wm-grid-col-sm-8", children: laborForceTrends && (_jsxs("div", { className: "-trend-value", children: [_jsx("div", { className: "label-name", children: translate("app.trends.unemployment") }), _jsxs("div", { className: "-percentage flex-row", children: [_jsx("i", { className: "-icon mdi -midnight ".concat(getArrowIcon(laborForceTrends.unemploymentGrowthIncrease)) }), _jsx("div", { children: "".concat(laborForceTrends.unemploymentPercentage, "%") })] }), _jsx("div", { children: timeframe &&
                        translate("app.trends.unemploymentDescription", {
                            unemploymentFromDate: timeframe.pastYear,
                            unemploymentToDate: timeframe.currentYear,
                            unemploymentGrowthIncrease: getIncreaseDecrease(laborForceTrends.unemploymentGrowthIncrease),
                            unemploymentPercentage: laborForceTrends.unemploymentPercentage,
                            unemploymentGrowthPercentageFrom: laborForceTrends.unemploymentGrowthPercentageFrom,
                            unemploymentGrowthPercentageTo: laborForceTrends.unemploymentGrowthPercentageTo
                        }) })] })) }));
};
