var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmTabItem, WmTabList, WmTabPanel } from "@watermarkinsights/ripple-react";
import { useReducer } from "react";
import { useIntl } from "react-intl";
import WidgetLoader from "src/features/common/elements/WidgetLoader";
import translate from "src/i18n/translate";
import "../Lightcast.scss";
import PopulationByAge from "./PopulationByAge";
import PopulationByRaceEthnicity from "./PopulationByRaceEthnicity";
var defaultChangeInPopulationAction = {
    type: "",
    tabId: "",
    tab: ""
};
var reducer = function (state, action) {
    switch (action.type) {
        case "SetSelectedTab":
            return __assign(__assign({}, state), { selectedTab: action.tabId });
        case "setLoadedTab":
            var tab = action.tab;
            var loadedTabs = state.loadedTabs;
            loadedTabs[tab] = true;
            return __assign(__assign({}, state), { loadedTabs: loadedTabs });
        default:
            return state;
    }
};
var allTabsLoaded = function (loadedTabs) {
    return loadedTabs.ageCohort && loadedTabs.raceEthinicity;
};
export default function ChangeInPopulation(_a) {
    var onComponentLoad = _a.onComponentLoad;
    function setTooglePosition(event, chartId) {
        var _a;
        var chartBodyRect;
        chartBodyRect = (_a = document
            .querySelector("." + chartId)) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        var targetRect = event.target.getBoundingClientRect();
        var availableSpaceLeft = targetRect.left - (chartBodyRect === null || chartBodyRect === void 0 ? void 0 : chartBodyRect.left);
        var availableSpaceRight = (chartBodyRect === null || chartBodyRect === void 0 ? void 0 : chartBodyRect.right) - targetRect.right;
        if (window.innerWidth >= 1024) {
            event.target.childNodes[0].style.width = "180px";
        }
        else {
            event.target.childNodes[0].style.width = "160px";
        }
        if (availableSpaceLeft > availableSpaceRight) {
            event.target.childNodes[0].style.right = "30%";
            event.target.childNodes[0].style.removeProperty("left");
        }
        else {
            event.target.childNodes[0].style.left = "10%";
            event.target.childNodes[0].style.removeProperty("right");
        }
    }
    var ageCohortLabel = useIntl().formatMessage({
        id: "app.changeInPopulation.ageCohortLabel"
    });
    var raceEthnicityLabel = useIntl().formatMessage({
        id: "app.changeInPopulation.raceEthnicityLabel"
    });
    var tabOption = [
        {
            name: ageCohortLabel,
            id: "age-cohort"
        },
        {
            name: raceEthnicityLabel,
            id: "race-ethnicity"
        }
    ];
    var initialState = {
        selectedTab: "change-in-population-tab-1",
        loadedTabs: {
            ageCohort: false,
            raceEthinicity: false
        }
    };
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var onWmTabSelected = function (event) {
        dispatch(__assign(__assign({}, defaultChangeInPopulationAction), { type: "SetSelectedTab", tabId: event.detail.tabId }));
    };
    var onTabLoad = function (tab) {
        dispatch(__assign(__assign({}, defaultChangeInPopulationAction), { type: "setLoadedTab", tab: tab }));
    };
    return (_jsxs("div", { className: "paper-card", children: [_jsx("div", { className: "header", children: _jsxs("div", { className: "info", id: "change-population-info", children: [_jsx("h3", { className: "title", children: translate("app.changeInPopulation.changePopulationLabel") }), _jsx("div", { className: "description -descrip", children: translate("app.changeInPopulation.helperText") })] }) }), _jsxs("div", { className: "body", id: "population-change-tab-component", children: [!allTabsLoaded(state.loadedTabs) && (_jsx(WidgetLoader, { loaderContainerClasses: "loader", loaderClasses: "content" })), allTabsLoaded(state.loadedTabs) && (_jsx("div", { className: "tabbar", children: _jsx(WmTabList, { id: "tab-with-controller-change-in-population", selectedTab: state.selectedTab, onWmTabSelected: onWmTabSelected, children: tabOption.map(function (tab, index) { return (_jsx(WmTabItem, { tabId: "change-in-population-tab-" + (index + 1), children: tab.name }, tab.id + (index + 1))); }) }) })), _jsx(WmTabPanel, { className: "tab-panel", id: "tabs-one", tabId: "change-in-population-tab-1", children: _jsx(PopulationByAge, { setTooglePosition: setTooglePosition, onTabLoad: onTabLoad, onComponentLoad: onComponentLoad, showIntegratedHeader: true }) }), _jsx(WmTabPanel, { className: "tab-panel", id: "tabs-two", tabId: "change-in-population-tab-2", children: _jsx(PopulationByRaceEthnicity, { setTooglePosition: setTooglePosition, onTabLoad: onTabLoad, onComponentLoad: onComponentLoad, showIntegratedHeader: true }) })] })] }));
}
