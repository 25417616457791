export const pig = (lang) => {
  const newLang = {};
  for (var key in lang) {
    newLang[key] = converToPigLatin(lang[key]);
  }
  return newLang;
};

const converToPigLatin = (str) => {
  let vowels = ["a", "e", "i", "o", "u"];
  let newStr = "";

  if (vowels.indexOf(str[0]) > -1) {
    newStr = str + "way";
    return newStr;
  } else if (str.startsWith("{") && str.indexOf("plural") !== -1) {
    return translatePlural(str);
  } else if (str.startsWith("{") && str.indexOf("plural") < 0) {
    return translateDynamicMessage(str);
  } else {
    let firstMatch = str.match(/[aeiou]/gi) || 0;
    let vowel = str.indexOf(firstMatch[0]);
    newStr = str.substring(vowel) + str.substring(0, vowel) + "ay";
    return newStr;
  }
};
const translatePlural = (str) => {
  const parts = str.split(",");
  let toTranslate = parts[2];
  let lastIndex = 0;
  let next = true;
  let translated = parts[2];
  while (next) {
    let startIndex = toTranslate.indexOf("{", lastIndex);
    let endIndex = toTranslate.indexOf("}", lastIndex);
    let message = toTranslate.substring(startIndex + 1, endIndex);

    lastIndex = endIndex + 1;

    next = toTranslate.indexOf("{", lastIndex) > -1;
    translated = translated.replace(
      message,
      translateDynamicMessage(message).trim()
    );
  }

  return [parts[0], parts[1], translated].join(",");
};
const translateDynamicMessage = (str) => {
  let newStr = "";
  let words = str.split(" ");
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (word.startsWith("{") || word === "#") {
      newStr = newStr + " " + word;
    } else {
      let firstMatch = word.match(/[aeiou]/g) || 0;
      let vowel = word.indexOf(firstMatch[0]);
      newStr =
        newStr + " " + word.substring(vowel) + word.substring(0, vowel) + "ay";
    }
  }
  return newStr;
};
