var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useReducer, useContext, useEffect } from "react";
import PlanParticipation from "./PlanParticipation";
import ResponseRate from "../ces/ResponseRate";
import "../common/Insights.scss";
import Sll from "../sll/Sll";
import translate from "src/i18n/translate";
import { AppContext } from "../../contexts/AppContext";
import ReportingYears from "../common/elements/ReportingYears";
import ApiError from "../common/elements/ApiError";
import { checkIfSllProductIntegrated, getLoaderStatus } from "../common/helper";
import { defaultReportingYear } from "../common/interfaces/Interface";
var REDIRECT_URI = runConfig.REDIRECT_URI;
var defaultCIAction = {
    type: "",
    reportingYear: defaultReportingYear,
    component: ""
};
var reducer = function (state, action) {
    switch (action.type) {
        case "selectReportingYear":
            return __assign(__assign({}, state), { selectedReportingYear: action.reportingYear });
        case "setLoadedComponent":
            var component = action.component;
            var loadedComponents = state.loadedComponents;
            loadedComponents[component] = true;
            return __assign(__assign({}, state), { loadedComponents: loadedComponents });
        case "resetLoadedComponent":
            var newComponent = action.component;
            var newloadedComponents = state.loadedComponents;
            newloadedComponents[newComponent] = false;
            return __assign(__assign({}, state), { loadedComponents: newloadedComponents });
        case "resetAllLoadedComponent":
            return __assign(__assign({}, state), { loadedComponents: {
                    planParticipationPlans: false,
                    planParticipationChartData: false,
                    responseRate: false,
                    sll: false
                } });
        default:
            return state;
    }
};
var allComponentsLoaded = function (loadedComponents, platformUuid, showCourseEvaluation) {
    return (loadedComponents.planParticipationPlans &&
        loadedComponents.planParticipationChartData &&
        (showCourseEvaluation ? loadedComponents.responseRate : true) &&
        (platformUuid ? loadedComponents.sll : true));
};
export default function ContinuousImprovement(_a) {
    var reportingYears = _a.reportingYears, defaultReportingYear = _a.defaultReportingYear, ryApiError = _a.ryApiError;
    var _b = useContext(AppContext), institution = _b.institution, membership = _b.membership, integratedProducts = _b.integratedProducts;
    var initialState = {
        selectedReportingYear: defaultReportingYear,
        loadedComponents: {
            planParticipationPlans: false,
            planParticipationChartData: false,
            responseRate: false,
            sll: false
        }
    };
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    var selectReportingYear = function (year) {
        dispatch(__assign(__assign({}, defaultCIAction), { type: "resetAllLoadedComponent" }));
        dispatch(__assign(__assign({}, defaultCIAction), { type: "selectReportingYear", reportingYear: year }));
    };
    useEffect(function () {
        selectReportingYear(defaultReportingYear);
    }, [defaultReportingYear]);
    var onComponentLoad = function (component) {
        dispatch(__assign(__assign({}, defaultCIAction), { type: "setLoadedComponent", component: component }));
    };
    var resetLoadedComponent = function (component) {
        dispatch(__assign(__assign({}, defaultCIAction), { type: "resetLoadedComponent", component: component }));
    };
    useEffect(function () {
        var liveRegion = document.querySelector(".continuous-improvement-status");
        institution &&
            getLoaderStatus(!allComponentsLoaded(state.loadedComponents, institution.platformUuid, institution.showCourseEvaluation), liveRegion);
    }, [
        institution &&
            allComponentsLoaded(state.loadedComponents, institution.platformUuid, institution.showCourseEvaluation),
        state.selectedReportingYear
    ]);
    var continuousImprovementWidgets = function () {
        return (_jsxs("div", { className: "wm-grid-row", children: [_jsx("span", { className: "sr-only continuous-improvement-status", role: "status", "aria-live": "assertive" }), institution && institution.isPssIntegrated && (_jsx("div", { className: institution && institution.showCourseEvaluation
                        ? "grid-container wm-grid-col-6  wm-grid-col-md-10 -min-width"
                        : "grid-container wm-grid-col-12 wm-grid-col-md-10 -min-width", children: _jsx(PlanParticipation, { selectedReportingYear: state.selectedReportingYear, onComponentLoad: onComponentLoad, resetLoadedComponent: resetLoadedComponent }, state.selectedReportingYear.uuid) })), institution && institution.showCourseEvaluation && (_jsx("div", { className: institution && institution.isPssIntegrated
                        ? "grid-container wm-grid-col-6 wm-grid-col-md-10"
                        : "grid-container wm-grid-col-12 wm-grid-col-md-10 -min-width", children: _jsx(ResponseRate, { selectedReportingYear: state.selectedReportingYear, reportingYears: reportingYears, onComponentLoad: onComponentLoad, resetLoadedComponent: resetLoadedComponent }) })), checkIfSllProductIntegrated(integratedProducts) && (_jsx(Sll, { selectedReportingYear: state.selectedReportingYear, onComponentLoad: onComponentLoad }))] }));
    };
    return (_jsx("section", { className: (institution === null || institution === void 0 ? void 0 : institution.navigatedFrom) === "planning"
            ? "body wm-grid tab-body"
            : "body wm-grid tab-body-home", id: "section_insights", children: _jsx("div", { children: _jsxs("section", { className: "insights", children: [_jsxs("div", { className: "institution-name -bottom flex-row -justified sse-desc flex-col-sm", children: [_jsx("div", { className: "helper-label", children: translate("app.continuousImprovement.title") }), reportingYears &&
                                (reportingYears === null || reportingYears === void 0 ? void 0 : reportingYears.length) > 0 &&
                                state.selectedReportingYear.startDate && (_jsx(ReportingYears, { reportingYears: reportingYears, selectedReportingYear: state.selectedReportingYear, selectReportingYear: selectReportingYear }, state.selectedReportingYear.uuid))] }), reportingYears &&
                        (reportingYears === null || reportingYears === void 0 ? void 0 : reportingYears.length) > 0 &&
                        continuousImprovementWidgets(), (reportingYears === null || reportingYears === void 0 ? void 0 : reportingYears.length) === 0 &&
                        membership &&
                        academicYearEmptyState(membership), ryApiError && _jsx(ApiError, {})] }) }) }));
}
var academicYearEmptyState = function (membership) {
    return (_jsx("div", { className: "academic-yr-empty-state flex-row", children: _jsxs("div", { children: [_jsx("div", { className: "empty-state-title", children: translate("app.continuousImprovement.noAcademicYearTitle") }), membership.userRole === "admin" && adminEmptyState(), membership.userRole === "contributor" && contributorEmptyState()] }) }));
};
function contributorEmptyState() {
    return (_jsx("div", { className: "empty-state-text", children: translate("app.continuousImprovement.noAcademicYearText") }));
}
function adminEmptyState() {
    return (_jsxs("div", { className: "empty-state-text", children: [_jsx("a", { className: "empty-state-link", href: "".concat(REDIRECT_URI, "/admin/configuration_settings/reporting_year"), children: translate("app.continuousImprovement.createReportingYear") }), translate("app.continuousImprovement.noAcademicYearSubText")] }));
}
