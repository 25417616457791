import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "../Lightcast.scss";
import translate from "src/i18n/translate";
import { useIntl } from "react-intl";
import { WmChart, WmChartSlice } from "@watermarkinsights/ripple-react";
import WidgetLoader from "src/features/common/elements/WidgetLoader";
import ApiError from "../../common/elements/ApiError";
export default function BottomGrowingOccupations(_a) {
    var occupations = _a.occupations, loader = _a.loader, forHtml = _a.forHtml;
    return (_jsxs("div", { className: forHtml ? "bottom-growing-occupations" : "paper-card", children: [_jsx("div", { className: "header", children: _jsxs("div", { className: "info", children: [_jsx("h3", { className: forHtml ? "html-title" : "title", children: translate("app.bottomGrowingOccp.bottomThreeGrowing") }), _jsx("p", { className: forHtml ? "html-desc" : "description -des", children: translate("app.bottomGrowingOccp.reviewBottomOccp") })] }) }), _jsxs("div", { className: "body", children: [occupations && _jsx(OccupationsChart, { occupations: occupations }), loader && !occupations && (_jsx(WidgetLoader, { loaderContainerClasses: "loader", loaderClasses: "content" })), !loader && !occupations && _jsx(ApiError, {})] })] }));
}
function useGetPopoverTextLabel(changeVal) {
    return useIntl().formatMessage({
        id: "app.bottomGrowingOccp.popoverTextLabel"
    }, {
        changeJob: changeVal.toLocaleString()
    });
}
var GetPopoverTextLabel = function (changeVal) {
    return useGetPopoverTextLabel(changeVal);
};
var OccupationsChart = function (_a) {
    var occupations = _a.occupations;
    var chartLabel = useIntl().formatMessage({
        id: "app.bottomGrowingOccp.bottomThreeGrowing"
    });
    return (_jsx(WmChart, { label: chartLabel, labelPosition: "none", chartType: "bar6", valueFormat: "amount", showGrid: true, labelWidth: "120px", "print-mode": true, children: occupations.map(function (row) {
            return (_jsx(WmChartSlice, { amount: String(row === null || row === void 0 ? void 0 : row.change), legend: row === null || row === void 0 ? void 0 : row.name, popoverText: GetPopoverTextLabel(String(row === null || row === void 0 ? void 0 : row.change)), popoverTitle: row === null || row === void 0 ? void 0 : row.changePercentage }, row === null || row === void 0 ? void 0 : row.name));
        }) }));
};
