var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useReducer, useRef } from "react";
import "../common/Insights.scss";
import { useIntl } from "react-intl";
var initialState = {
    tooltipVisible: false,
    tooltipPosition: { x: 0, y: 0 },
    tooltipTitle: "",
    tooltipText: ""
};
var defaultAction = {
    type: "",
    payload: { x: 0, y: 0 },
    title: "",
    label: ""
};
var reducer = function (state, action) {
    switch (action.type) {
        case "showTooltip":
            return __assign(__assign({}, state), { tooltipVisible: true, tooltipPosition: action.payload, tooltipTitle: action.title, tooltipText: action.label });
        case "hideTooltip":
            return __assign(__assign({}, state), { tooltipVisible: false });
        default:
            return state;
    }
};
var AssessmentProgressChart = function (_a) {
    var amount = _a.amount, actualCount = _a.actualCount, expectedCount = _a.expectedCount, tooltipCompletedText = _a.tooltipCompletedText, tooltipNotCompletedText = _a.tooltipNotCompletedText;
    var greenBarWidth = "".concat(amount, "%");
    var grayBarWidth = "".concat(100 - amount, "%");
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var notCompletedCount = expectedCount - actualCount;
    var showTooltip = function (event, count, totalCount, completedText, isGreenBar) {
        var _a;
        var _b = event.nativeEvent, offsetX = _b.offsetX, offsetY = _b.offsetY;
        var barContainerWidth = (_a = event.currentTarget.parentElement) === null || _a === void 0 ? void 0 : _a.clientWidth;
        var greenBarWidth = 0;
        if (barContainerWidth) {
            greenBarWidth = (amount / 100) * barContainerWidth;
        }
        var correctedOffsetX = isGreenBar ? offsetX : offsetX + greenBarWidth;
        if (event.type !== "click") {
            correctedOffsetX = isGreenBar ? offsetX : greenBarWidth;
        }
        dispatch({
            type: "showTooltip",
            payload: { x: correctedOffsetX, y: offsetY },
            title: "".concat(count, "/").concat(totalCount),
            label: completedText
        });
    };
    var handleArrowKeyPress = function (event) {
        var _a, _b, _c;
        if (event.key === "ArrowUp" ||
            event.key === "ArrowDown" ||
            event.key === "ArrowLeft" ||
            event.key === "ArrowRight") {
            event.preventDefault();
            if (!state.tooltipVisible ||
                state.tooltipText === tooltipNotCompletedText) {
                (_a = event.currentTarget.querySelector(".green-bar")) === null || _a === void 0 ? void 0 : _a.focus();
                showTooltip(event, actualCount, expectedCount, tooltipCompletedText, true);
            }
            else if (state.tooltipVisible ||
                state.tooltipText === tooltipCompletedText) {
                (_b = event.currentTarget.querySelector(".gray-bar")) === null || _b === void 0 ? void 0 : _b.focus();
                showTooltip(event, notCompletedCount, expectedCount, tooltipNotCompletedText, false);
            }
        }
        else if (event.key === "Tab") {
            dispatch(__assign(__assign({}, defaultAction), { type: "hideTooltip" }));
            (_c = event.currentTarget.parentElement.querySelector(".bar-container")) === null || _c === void 0 ? void 0 : _c.focus();
        }
    };
    var tooltipRef = useRef(null);
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (tooltipRef.current &&
                !tooltipRef.current.contains(event.target)) {
                dispatch(__assign(__assign({}, defaultAction), { type: "hideTooltip" }));
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    var ariaLabel = useIntl().formatMessage({
        id: "app.assessmentProgressChart.ariaLabel"
    });
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "bar-chart flex-row", children: [_jsxs("div", { className: "bar-container", tabIndex: 0, "aria-label": ariaLabel, role: "application", onKeyDown: handleArrowKeyPress, children: [_jsx("div", { className: "green-bar", style: { width: "calc(".concat(greenBarWidth, " - 1px)") }, tabIndex: -1, onBlur: function () { return dispatch(__assign(__assign({}, defaultAction), { type: "hideTooltip" })); }, onClick: function (event) {
                                    showTooltip(event, actualCount, expectedCount, tooltipCompletedText, true);
                                } }), _jsx("div", { className: amount === 0 ? "" : "gap" }), _jsx("div", { className: "gray-bar", style: { width: "calc(".concat(grayBarWidth, " - 1px)") }, tabIndex: -1, onBlur: function () { return dispatch(__assign(__assign({}, defaultAction), { type: "hideTooltip" })); }, onClick: function (event) {
                                    showTooltip(event, notCompletedCount, expectedCount, tooltipNotCompletedText, false);
                                } })] }), _jsxs("div", { className: "percentage-text-label", children: [Math.round(amount), "%"] })] }), state.tooltipVisible && (_jsx(Tooltip, { tooltipTitle: state.tooltipTitle, tooltipText: state.tooltipText, tooltipRef: tooltipRef, tooltipPosition: state.tooltipPosition }))] }));
};
function Tooltip(_a) {
    var tooltipTitle = _a.tooltipTitle, tooltipText = _a.tooltipText, tooltipRef = _a.tooltipRef, tooltipPosition = _a.tooltipPosition;
    var liveRegionRef = useRef(null);
    useEffect(function () {
        if (liveRegionRef.current) {
            liveRegionRef.current.innerText = "".concat(tooltipTitle, " : ").concat(tooltipText);
        }
    }, [tooltipTitle, tooltipText]);
    return (_jsxs("div", { className: "tooltip", ref: tooltipRef, style: {
            left: tooltipPosition.x,
            top: tooltipPosition.y
        }, children: [_jsx("div", { className: "sr-only", "aria-atomic": true, "aria-live": "polite", role: "alert", ref: liveRegionRef }), _jsx("div", { className: "popover", children: _jsxs("div", { className: "tooltip-text", children: [_jsx("div", { className: "popover-amount", children: tooltipTitle }), _jsx("div", { className: "popover-text", children: tooltipText })] }) })] }));
}
export default AssessmentProgressChart;
