import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmOption, WmSelect } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import { formatDate } from "../../common/helper";
var ReportingYears = function (_a) {
    var reportingYears = _a.reportingYears, selectedReportingYear = _a.selectedReportingYear, selectReportingYear = _a.selectReportingYear;
    var academicYearLabel = useIntl().formatMessage({
        id: "app.reportingYears.academicYear"
    });
    return (_jsxs("div", { className: "reporting-year-width", children: [_jsx(WmSelect, { label: academicYearLabel, labelPosition: "left", id: "select-terms", children: reportingYears.map(function (year) {
                    return (_jsx(WmOption, { id: "reportingYears-".concat(year.uuid), selected: (selectedReportingYear === null || selectedReportingYear === void 0 ? void 0 : selectedReportingYear.uuid) === year.uuid, onClick: function () { return selectReportingYear(year); }, children: year.name }, "reportingYears-".concat(year.uuid)));
                }) }), selectedReportingYear && (_jsx("div", { className: "reporting-year-duration", children: "".concat(formatDate(selectedReportingYear === null || selectedReportingYear === void 0 ? void 0 : selectedReportingYear.startDate), " - ").concat(formatDate(selectedReportingYear === null || selectedReportingYear === void 0 ? void 0 : selectedReportingYear.endDate)) }))] }));
};
export var getDefaultReportingYear = function (defaultReportingYear, reportingYears) {
    var currentReportingYear = reportingYears.find(function (reportingYear) { return reportingYear.isCurrent; });
    return currentReportingYear || reportingYears[0] || defaultReportingYear;
};
export var dateWithoutTimezone = function (dateStr) {
    if (dateStr) {
        var index = dateStr.indexOf("T");
        return index > 0 ? dateStr.slice(0, index) : dateStr;
    }
};
export default ReportingYears;
