var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import translate from "../../../i18n/translate";
import { useEffect, useReducer } from "react";
import { sendRequest } from "../../../common/http";
import BarChart from "./BarChart";
import ParticipatingTable from "./ParticipatingTable";
import { useIntl } from "react-intl";
export default function ParticipatingOrganizations(_a) {
    var plan = _a.plan;
    var initialState = {
        chartData: {
            completedCount: 0,
            completedPrgmCount: 0,
            submittedCourseCount: 0,
            inProgressCount: 0,
            inProgressPrgmCount: 0,
            inProgressCourseCount: 0,
            notStartedCount: 0,
            notStartedCourseCount: 0,
            notStartedPrgmCount: 0,
            totalParticipatingOrgs: 0,
            totalParticipatingPrgms: 0,
            totalParticipatingCourses: 0,
            totalPrograms: 0,
            totalOrganizations: 0,
            totalCourses: 0
        },
        orgHierarchy: {},
        programHierarchy: {},
        leads: []
    };
    var reducer = function (state, action) {
        switch (action.type) {
            case "setChartData":
                return __assign(__assign({}, state), { chartData: action.data });
            case "setOrgHierarchy":
                return __assign(__assign({}, state), { orgHierarchy: action.data });
            case "setProgramHierarchy":
                return __assign(__assign({}, state), { programHierarchy: action.data });
            case "setLeads":
                return __assign(__assign({}, state), { leads: action.data });
            default:
                return state;
        }
    };
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    useEffect(function () {
        var applyResult = function (result) {
            dispatch({ type: "setChartData", data: result });
        };
        if (plan.uuid) {
            sendRequest({ uri: "/plan/".concat(plan.uuid, "/stats"), camelize: true }, applyResult);
        }
    }, [plan]);
    useEffect(function () {
        var applyOrgHierarchyResult = function (result) {
            dispatch({ type: "setOrgHierarchy", data: result });
        };
        if (plan.uuid && state.chartData.totalParticipatingOrgs > 0) {
            sendRequest({
                uri: "/hierarchy/participating_organizations/".concat(plan.uuid),
                camelize: true
            }, applyOrgHierarchyResult);
        }
    }, [plan, state.chartData.totalParticipatingOrgs]);
    useEffect(function () {
        var applyProgramHierarchyResult = function (result) {
            dispatch({ type: "setProgramHierarchy", data: result });
        };
        if (plan.uuid && state.chartData.totalParticipatingPrgms > 0) {
            sendRequest({
                uri: "/hierarchy/participating_programs/".concat(plan.uuid),
                camelize: true
            }, applyProgramHierarchyResult);
        }
    }, [plan, state.chartData.totalParticipatingPrgms]);
    useEffect(function () {
        var applyLeadsResult = function (result) {
            dispatch({ type: "setLeads", data: result });
        };
        sendRequest({ uri: "/leads", camelize: true }, applyLeadsResult);
    }, []);
    var participatingProgramsNotStarted = useIntl().formatMessage({
        id: "app.planParticipation.participatingProgramsNotStarted"
    });
    var participatingProgramsInProgress = useIntl().formatMessage({
        id: "app.planParticipation.participatingProgramsInProgress"
    });
    var participatingProgramsInComplete = useIntl().formatMessage({
        id: "app.planParticipation.participatingProgramsInComplete"
    });
    var participatingProgramsCompleted = useIntl().formatMessage({
        id: "app.planParticipation.participatingProgramsSubmitted"
    });
    var participatingOrgsNotStarted = useIntl().formatMessage({
        id: "app.planParticipation.participatingOrgsNotStarted"
    });
    var participatingOrgsInProgres = useIntl().formatMessage({
        id: "app.planParticipation.participatingOrgsInProgres"
    });
    var participatingOrgsInComplete = useIntl().formatMessage({
        id: "app.planParticipation.participatingOrgsInComplete"
    });
    var participatingOrgsCompleted = useIntl().formatMessage({
        id: "app.planParticipation.participatingOrgsSubmitted"
    });
    var participatingCoursesNotStarted = useIntl().formatMessage({
        id: "app.planParticipation.participatingCoursesNotStarted"
    });
    var participatingCoursesInProgress = useIntl().formatMessage({
        id: "app.planParticipation.participatingCoursesInProgress"
    });
    var participatingCoursesInComplete = useIntl().formatMessage({
        id: "app.planParticipation.participatingCoursesInComplete"
    });
    var participatingCoursesCompleted = useIntl().formatMessage({
        id: "app.planParticipation.participatingCoursesSubmitted"
    });
    var inProgress = useIntl().formatMessage({
        id: "app.drilldown.inProgress"
    });
    var inComplete = useIntl().formatMessage({
        id: "app.drilldown.inComplete"
    });
    var planCourseBar = function (status) {
        var _a = status == 1
            ? [participatingCoursesInComplete, inComplete]
            : [participatingCoursesInProgress, inProgress], popupText = _a[0], legend = _a[1];
        if (state.chartData.totalParticipatingCourses > 0 &&
            plan.assessmentTypeId === 1) {
            return (_jsxs(_Fragment, { children: [_jsx("div", { className: "chart-heading", children: translate("app.planParticipation.participatingCoursesByStatus") }), _jsxs("div", { className: "flex-row bar-content", children: [_jsxs("div", { className: "counts dropnumber", children: [_jsx("p", { className: "count", children: state.chartData.totalParticipatingCourses.toString() +
                                            "/" +
                                            state.chartData.totalCourses.toString() }), _jsx("p", { className: "details", children: translate("app.planParticipation.courseParticipating") })] }), _jsx(BarChart, { notStartedCount: state.chartData.notStartedCourseCount.toString(), inProgressCount: state.chartData.inProgressCourseCount.toString(), submittedCount: state.chartData.submittedCourseCount.toString(), totalCount: state.chartData.totalParticipatingCourses.toString(), notStartedText: participatingCoursesNotStarted, inProgressText: popupText, inProgressLegend: legend, completedText: participatingCoursesCompleted })] })] }));
        }
    };
    var planOrgBar = function (status) {
        var _a = status == 1
            ? [participatingOrgsInComplete, inComplete]
            : [participatingOrgsInProgres, inProgress], popupText = _a[0], legend = _a[1];
        if (state.chartData.totalParticipatingOrgs > 0 &&
            plan.assessmentTypeId === 0) {
            return (_jsxs(_Fragment, { children: [_jsx("div", { className: "chart-heading", children: translate("app.planParticipation.participatingOrgs") }), _jsxs("div", { className: "flex-row", children: [_jsxs("div", { className: "counts dropnumber", children: [_jsx("p", { className: "count", children: state.chartData.totalParticipatingOrgs.toString() +
                                            "/" +
                                            state.chartData.totalOrganizations.toString() }), _jsx("p", { className: "details", children: translate("app.planParticipation.orgParticipating") })] }), _jsx(BarChart, { notStartedCount: state.chartData.notStartedCount.toString(), inProgressCount: state.chartData.inProgressCount.toString(), submittedCount: state.chartData.completedCount.toString(), totalCount: state.chartData.totalParticipatingOrgs.toString(), notStartedText: participatingOrgsNotStarted, inProgressText: popupText, inProgressLegend: legend, completedText: participatingOrgsCompleted })] })] }));
        }
    };
    var planPrgmBar = function (status) {
        var _a = status == 1
            ? [participatingProgramsInComplete, inComplete]
            : [participatingProgramsInProgress, inProgress], popupText = _a[0], legend = _a[1];
        if (state.chartData.totalParticipatingPrgms > 0 &&
            plan.assessmentTypeId === 0) {
            return (_jsxs(_Fragment, { children: [_jsx("div", { className: "chart-heading", children: translate("app.planParticipation.participatingProgramsByStatus") }), _jsxs("div", { className: "flex-row", children: [_jsxs("div", { className: "counts dropnumber", children: [_jsx("p", { className: "count", children: state.chartData.totalParticipatingPrgms.toString() +
                                            "/" +
                                            state.chartData.totalPrograms.toString() }), _jsx("p", { className: "details", children: translate("app.planParticipation.programsParticipating") })] }), _jsx(BarChart, { notStartedCount: state.chartData.notStartedPrgmCount.toString(), inProgressCount: state.chartData.inProgressPrgmCount.toString(), submittedCount: state.chartData.completedPrgmCount.toString(), totalCount: state.chartData.totalParticipatingPrgms.toString(), notStartedText: participatingProgramsNotStarted, inProgressText: popupText, inProgressLegend: legend, completedText: participatingProgramsCompleted })] })] }));
        }
    };
    return (_jsx("section", { className: "-mainheader -withbreadcrumbs only-breadcrumbs body-padding", id: "page-header", children: _jsxs("div", { className: "body", children: [_jsxs("div", { className: state.chartData.totalParticipatingOrgs &&
                        state.chartData.totalParticipatingPrgms
                        ? "flex-row insightsgrid border-bottom"
                        : "flex-row insightsgrid", children: [planCourseBar(plan.status), planOrgBar(plan.status), state.chartData.totalParticipatingOrgs ? (_jsx(ParticipatingTable, { orgHierarchy: state.orgHierarchy, leads: state.leads, heading: translate("app.planParticipation.progressOrgs"), planStatus: plan.status })) : (_jsx(_Fragment, {}))] }), _jsxs("div", { className: "flex-row insightsgrid", children: [planPrgmBar(plan.status), state.chartData.totalParticipatingPrgms &&
                            plan.assessmentTypeId === 0 ? (_jsx(ParticipatingTable, { orgHierarchy: state.programHierarchy, leads: state.leads, heading: translate("app.planParticipation.progressPrgm"), planStatus: plan.status })) : (_jsx(_Fragment, {}))] })] }) }));
}
