export var defaultLaborForceBreakdown = {
    civilianPopulation: "",
    civilianPopulationPercentage: 0,
    employed: "",
    employedPercentage: 0,
    laborForcePopulation: "",
    laborForcePopulationPercentage: 0,
    notInLaborForce: "",
    notInLaborForcePercentage: 0,
    totalPopulation: "",
    under16: "",
    under16Percentage: 0,
    unemployed: "",
    unemployedPercentage: 0
};
export var defaultCoordinates = {
    latitude: 0,
    longitude: 0
};
export var defaultRegionalContext = {
    coordinates: defaultCoordinates,
    countyCodes: [],
    mapId: "",
    mapTilerApiKey: "",
    noOfCounties: 0,
    radius: 0,
    timeFrame: "",
    zip: 0
};
