import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import translate from "src/i18n/translate";
export default function FacultySuccessCollegesTable(_a) {
    var accomplishmentByCategory = _a.accomplishmentByCategory, categoryColumnHeader = _a.categoryColumnHeader;
    return (_jsx(_Fragment, { children: _jsx("div", { children: _jsxs(Table, { children: [_jsx(TableHeader, { columns: [
                            categoryColumnHeader,
                            translate("app.faculty.grants"),
                            translate("app.faculty.publications"),
                            translate("app.faculty.intellectualProperty"),
                            translate("app.faculty.performances"),
                            translate("app.faculty.presentations"),
                            translate("app.faculty.total")
                        ] }), _jsx(TableBody, { accomplishmentByCategory: accomplishmentByCategory })] }) }) }));
}
var TableBody = function (props) {
    var accomplishmentByCategory = props.accomplishmentByCategory;
    return (_jsx("tbody", { className: "databody", children: Object.keys(accomplishmentByCategory)
            .sort()
            .map(function (collegeName, index) { return (_jsxs("tr", { className: "datarow", children: [_jsx("td", { className: "datacell", children: collegeName }), Object.keys(accomplishmentByCategory[collegeName]).map(function (category, index) { return (_jsx("td", { className: "datacell", children: accomplishmentByCategory[collegeName][category] }, "".concat(category).concat(index))); })] }, "".concat(collegeName).concat(index))); }) }));
};
var Table = function (props) {
    return _jsx("table", { className: "data-table -shadow", children: props.children });
};
export var TableHeader = function (props) {
    return (_jsx("thead", { className: "dataheader", children: _jsx("tr", { className: "dataheaderrow", children: props.columns.map(function (column, index) {
                return (_jsx("th", { className: "dataheadercell", "data-th": column, children: column }, "column-".concat(index)));
            }) }) }));
};
