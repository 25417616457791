var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmNavigator } from "@watermarkinsights/ripple-react";
import "./Header.scss";
import { initiateGainsight } from "./../../../static/js/gainsight_integration.js";
import { toggleNavOrgMgt } from "../../../static/js/commons";
import translate from "src/i18n/translate";
import { useIntl } from "react-intl";
import { useEffect, useReducer, useContext } from "react";
import { sendRequest } from "../../../common/http";
import { AppContext } from "../../../contexts/AppContext";
var API_ROOT = runConfig.API_ROOT;
var reducer = function (state, action) {
    switch (action.type) {
        case "SetMemberShip":
            return __assign(__assign({}, state), { membership: action.membership });
        default:
            return state;
    }
};
var openHelpCentreLink = function () {
    return window.open("".concat(API_ROOT, "/zendesk/login"), "_blank");
};
export default function AppBar(_a) {
    var institution = _a.institution;
    var initialState = {
        membership: null
    };
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var _c = useContext(AppContext), setMembership = _c.setMembership, setComponentLoaded = _c.setComponentLoaded;
    useEffect(function () {
        var orgParam = "";
        if (institution.organization && institution.organization.platformUuid) {
            orgParam =
                "?organization_platform_uuid=" + institution.organization.platformUuid;
        }
        var applyResult = function (result) {
            setMembership(result);
            setComponentLoaded("membership");
            dispatch({ type: "SetMemberShip", membership: result });
        };
        sendRequest({ uri: "/membership" + orgParam, camelize: true }, applyResult);
    }, []);
    useEffect(function () {
        if (state.membership != null) {
            initiateGainsight(state.membership.gainsightData);
        }
    }, [state.membership]);
    var headerBody = function () {
        var renderNavBar = function () {
            if (state.membership && state.membership.userRole === "admin") {
                return (_jsxs("button", { id: "mobile-nav-toggle", className: "icononly hamburger-show", onClick: toggleNavOrgMgt, children: [_jsx("span", { className: "sr-only tooltip -left", children: translate("app.showNavigation") }), _jsx("span", { className: "hamburger-inner" })] }));
            }
        };
        return (_jsxs("header", { className: "top-bar topbar-overlay", role: "banner", children: [state.membership && renderNavBar(), _jsx("h1", { className: "logo", children: _jsx("img", { src: "/images/planning-self-study.svg", alt: "Watermark" }) }), state.membership && (_jsx(_Fragment, { children: _jsx(NavigationMenu, { institution: institution, membership: state.membership }) }))] }));
    };
    return _jsx(_Fragment, { children: headerBody() });
}
var NavigationMenu = function (_a) {
    var institution = _a.institution, membership = _a.membership;
    if (membership.authType !== "off") {
        return (_jsx("div", { className: "menus", role: "application", children: _jsxs("ul", { className: "menu-bar nobullets", children: [membership.institutions.length > 1 && (_jsxs("li", { className: "dropdown-select", children: [_jsx(SelectedHierarchy, { institution: institution }), _jsx(InstitutionList, { institution: institution, membership: membership })] })), _jsx(HelpCenter, {}), _jsx(Navigator, { institution: institution, membership: membership })] }) }));
    }
    else {
        return (_jsx("div", { className: "menus", role: "application", children: _jsxs("ul", { className: "menu-bar nobullets", children: [membership.institutions.length > 1 && (_jsx("li", { className: "topbar-menu", tabIndex: -1, children: _jsxs("div", { id: "hierarchy-select", className: "dropdown-select prevent-header", children: [_jsx(SelectedHierarchy, { institution: institution }), _jsx(InstitutionList, { institution: institution, membership: membership })] }) })), _jsx(HelpCenter, {}), _jsx(UserInfo, { membership: membership })] }) }));
    }
};
var HelpCenter = function () {
    var helpCenterlink = useIntl().formatMessage({
        id: "app.appBar.helpCenterlink"
    });
    return (_jsx("li", { className: "products dropdown-select", children: _jsx("button", { className: "header-help-icon", tabIndex: 0, "aria-label": helpCenterlink, onClick: openHelpCentreLink, children: _jsx("i", { className: "mdi mdi-help-circle" }) }) }));
};
var Navigator = function (props) {
    return (_jsx("li", { className: "products dropdown-select", children: _jsx(WmNavigator, { id: "navigator-example", "user-name": props.membership.userName, email: props.membership.email, "connection-name": props.membership.connectionName, "auth-type": props.membership.authCode, products: props.membership.userModules, logoutUrl: props.membership.logoutUrl, mode: "planning" }) }));
};
var SelectedHierarchy = function (props) {
    var wmAccountName = useIntl().formatMessage({ id: "app.appBar.wmAccountName" }, { institutionName: props.institution.name });
    var institutionName = props.institution.name || _jsx(_Fragment, { children: "\u00A0" });
    return (_jsx("button", { className: "selectedoption button", id: "oa-menu", tabIndex: 0, "aria-label": wmAccountName, onClick: function () { return window.wmrk.toggleHierarchySelect(); }, "aria-expanded": "false", "aria-controls": "oa-menu-listbox", children: _jsx("span", { id: "oa-menu-text", className: "overflowcontrol prevent-header", children: institutionName }) }));
};
var InstitutionList = function (props) {
    var selectInstitution = useIntl().formatMessage({
        id: "app.appBar.selectInstitution"
    });
    return (_jsx("ul", { id: "oa-menu-listbox", className: "options nobullets", role: "listbox", "aria-label": selectInstitution, "aria-activedescendant": "".concat(props.institution.name), tabIndex: -1, children: props.membership.institutions.map(function (i) {
            return (_jsx("li", { id: i.uuid, role: "option", className: hierarchyClass(props.institution.platformUuid, i.platformUuid), "aria-selected": props.institution.platformUuid === i.platformUuid, onClick: function () {
                    return wmrk.switchHierarchy(props.institution.platformUuid, i.platformUuid, i.uuid);
                }, children: i.name }, i.uuid));
        }) }));
};
var UserInfo = function (props) {
    var userSetting = useIntl().formatMessage({
        id: "app.appBar.userSetting"
    });
    var logOutButton;
    if (props.membership.authType === "off") {
        logOutButton = (_jsx("li", { className: "option", tabIndex: -1, onClick: function () { return wmrk.logout(); }, children: translate("app.appBar.signOut") }));
    }
    else {
        logOutButton = (_jsx("li", { className: "logout option", onClick: function () {
                return wmrk.goToLocation(props.membership.logoutUrl);
            }, id: "logprod", children: translate("app.appBar.signOut") }));
    }
    return (_jsx("li", { id: "usersettings", className: "topbar-menu", tabIndex: -1, children: _jsxs("div", { id: "account-select", className: "dropdown-select", children: [_jsx("button", { id: "account-select-link", className: "username selectedoption prevent-header", "aria-controls": "acc-listbox", "aria-expanded": "false", tabIndex: 0, onClick: function () { return wmrk.toggleAccountMenu(); }, children: props.membership.userName }), _jsxs("ul", { id: "acc-listbox", className: "dropdown-menu options nobullets", "aria-label": userSetting, tabIndex: -1, children: [_jsx("li", { id: "user-menu-account", tabIndex: -1, className: "option prevent-header", onClick: function () { return wmrk.goToProfile(); }, children: translate("app.appBar.account") }), logOutButton] })] }) }));
};
function hierarchyClass(loggedInInstitutionPlatformUuid, institutionPlatformUuid) {
    var className = "option";
    if (loggedInInstitutionPlatformUuid === institutionPlatformUuid) {
        return className + " selected";
    }
    return className;
}
