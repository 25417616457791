var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useReducer, useContext, Fragment } from "react";
import "../../common/Insights.scss";
import OpenDrillDownView from "../../common/OpenDrillDownView";
import { WmProgressIndicator, WmProgressMonitor, WmProgressSlice } from "@watermarkinsights/ripple-react";
import { sendRequest } from "../../../common/http";
import { AppContext } from "../../../contexts/AppContext";
import translate from "src/i18n/translate";
import { useIntl } from "react-intl";
import { openFlyout } from "../../../static/js/commons";
import ApiError from "src/features/common/elements/ApiError";
var COMPLETED = "completed";
var NOT_STARTED = "not_started";
var drilldownFilters = [
    {
        value: COMPLETED,
        name: translate("app.drilldown.completed")
    },
    {
        value: NOT_STARTED,
        name: translate("app.drilldown.notStarted")
    }
];
var popover = function (amount, total) {
    return "".concat(amount, "/").concat(total);
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setMissionOutcome":
            return __assign(__assign({}, state), { totalPrograms: action.missionOutcomeData.totalPrograms, programMissionComplete: action.missionOutcomeData.programMissionComplete, programMissionIncomplete: action.missionOutcomeData.programMissionIncomplete, programLearningOutcomes: action.missionOutcomeData.programLearningOutcomes, programLearningOutcomesIncomplete: action.missionOutcomeData.programLearningOutcomesIncomplete, programSuccessOutcomes: action.missionOutcomeData.programSuccessOutcomes, programSuccessOutcomesIncomplete: action.missionOutcomeData.programSuccessOutcomesIncomplete, programCurriculumMapsComplete: action.missionOutcomeData.programCurriculumMapsComplete, programCurriculumMapsIncomplete: action.missionOutcomeData.programCurriculumMapsIncomplete, iloProgramMappingsComplete: action.missionOutcomeData.iloProgramMappingsComplete, iloProgramMappingsIncomplete: action.missionOutcomeData.iloProgramMappingsIncomplete });
        case "setDrilldownData":
            return __assign(__assign({}, state), { drilldownType: action.segment, drilldownStatus: action.status });
        case "openDrilldown":
            return __assign(__assign({}, state), { isDrilldownOpen: true });
        case "closeDrilldown":
            return __assign(__assign({}, state), { isDrilldownOpen: false, showTable: false, hierarchy: [] });
        case "setHierarchy":
            return __assign(__assign({}, state), { hierarchy: action.hierarchy, showTable: true });
        case "setApiError":
            return __assign(__assign({}, state), { apiError: action.apiError });
        default:
            return state;
    }
};
export default function ProgramProgressCard(_a) {
    var onTabLoad = _a.onTabLoad, onComponentLoad = _a.onComponentLoad;
    var POPOVER_BUTTON_TEXT = useIntl().formatMessage({
        id: "app.programProgressCard.progressDrilldown"
    });
    var leads = useContext(AppContext).leads;
    var initialState = {
        drilldownType: "",
        drilldownStatus: "any",
        isDrilldownOpen: false,
        hierarchy: [],
        showTable: false,
        totalPrograms: 0,
        programMissionComplete: 0,
        programMissionIncomplete: 0,
        programLearningOutcomes: 0,
        programLearningOutcomesIncomplete: 0,
        programSuccessOutcomes: 0,
        programSuccessOutcomesIncomplete: 0,
        programCurriculumMapsComplete: 0,
        programCurriculumMapsIncomplete: 0,
        iloProgramMappingsComplete: 0,
        iloProgramMappingsIncomplete: 0,
        apiError: false
    };
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var defaultAction = {
        type: "",
        apiError: false,
        missionOutcomeData: {
            totalPrograms: 0,
            programMissionComplete: 0,
            programMissionIncomplete: 0,
            programLearningOutcomes: 0,
            programLearningOutcomesIncomplete: 0,
            programSuccessOutcomes: 0,
            programSuccessOutcomesIncomplete: 0,
            programCurriculumMapsComplete: 0,
            programCurriculumMapsIncomplete: 0,
            iloProgramMappingsComplete: 0,
            iloProgramMappingsIncomplete: 0
        },
        segment: "",
        status: "",
        hierarchy: []
    };
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setMissionOutcome", missionOutcomeData: result }));
            onTabLoad("programs");
            onComponentLoad("programs");
        };
        var onError = function () {
            dispatch(__assign(__assign({}, defaultAction), { type: "setApiError", apiError: true }));
            onTabLoad("programs");
            onComponentLoad("programs");
        };
        sendRequest({ uri: "/mission_outcome/programs", camelize: true }, applyResult, onError);
    }, []);
    var getDrilldownHierarchy = function (segment) {
        openDrilldown();
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setHierarchy", hierarchy: result }));
        };
        sendRequest({ uri: "/hierarchy?segment=".concat(segment), camelize: true }, applyResult);
    };
    var openDrilldownView = function (segment, status) {
        dispatch(__assign(__assign({}, defaultAction), { type: "setDrilldownData", segment: segment, status: status }));
        getDrilldownHierarchy(segment);
    };
    var openDrilldown = function () {
        setTimeout(function () {
            return openFlyout("drill-down-view", "prog-charts", "drill-down-close-btn");
        }, 100);
        dispatch(__assign(__assign({}, defaultAction), { type: "openDrilldown" }));
    };
    var closeDrilldown = function () {
        dispatch(__assign(__assign({}, defaultAction), { type: "closeDrilldown" }));
    };
    var DD_SUB_HEADER = useIntl().formatMessage({
        id: "app.orgProgressCard.missionOutcome"
    });
    var programsWithMission = useIntl().formatMessage({
        id: "app.programProgressCard.programsWithMission"
    });
    var completed = useIntl().formatMessage({
        id: "app.drilldown.completed"
    });
    var notStarted = useIntl().formatMessage({
        id: "app.drilldown.notStarted"
    });
    var added = useIntl().formatMessage({
        id: "app.drilldown.added"
    });
    var notAdded = useIntl().formatMessage({
        id: "app.drilldown.notAdded"
    });
    var created = useIntl().formatMessage({
        id: "app.drilldown.created"
    });
    var notCreated = useIntl().formatMessage({
        id: "app.drilldown.notCreated"
    });
    var mapped = useIntl().formatMessage({
        id: "app.drilldown.mapped"
    });
    var notMapped = useIntl().formatMessage({
        id: "app.drilldown.notMapped"
    });
    var missionStatement = useIntl().formatMessage({
        id: "app.orgProgressCard.missionStatement"
    });
    var programsWithoutMission = useIntl().formatMessage({
        id: "app.programProgressCard.programsWithoutMission"
    });
    var learningOutcomes = useIntl().formatMessage({
        id: "app.orgProgressCard.learningOutcomes"
    });
    var successOutcomes = useIntl().formatMessage({
        id: "app.orgProgressCard.successOutcomes"
    });
    var ILOMapped = useIntl().formatMessage({
        id: "app.orgProgressCard.ILOMapped"
    });
    var curriculumMap = useIntl().formatMessage({
        id: "app.orgProgressCard.curriculumMap"
    });
    var programsWithLO = useIntl().formatMessage({
        id: "app.programProgressCard.programsWithLO"
    });
    var programsWithoutLO = useIntl().formatMessage({
        id: "app.programProgressCard.programsWithoutLO"
    });
    var programsWithSO = useIntl().formatMessage({
        id: "app.programProgressCard.programsWithSO"
    });
    var programsWithoutSO = useIntl().formatMessage({
        id: "app.programProgressCard.programsWithoutSO"
    });
    var programsWithCM = useIntl().formatMessage({
        id: "app.programProgressCard.programsWithCM"
    });
    var programsWithoutCM = useIntl().formatMessage({
        id: "app.programProgressCard.programsWithoutCM"
    });
    var programsWithILO = useIntl().formatMessage({
        id: "app.programProgressCard.programsWithILO"
    });
    var programsWithoutILO = useIntl().formatMessage({
        id: "app.programProgressCard.programsWithoutILO"
    });
    var totalPrograms = useIntl().formatMessage({ id: "app.programProgressCard.totalPrograms" }, { totalPrograms: state.totalPrograms });
    var programChart = function () {
        return (_jsx("div", { id: "prog-charts", children: _jsxs(WmProgressMonitor, { id: "program-progress", "bar-labels-width": "5rem", children: [_jsxs(WmProgressIndicator, { label: missionStatement, children: [_jsx(WmProgressSlice, { legend: added, amount: state.programMissionComplete.toString(), popoverTitle: popover(state.programMissionComplete, state.totalPrograms), popoverText: programsWithMission, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () { return openDrilldownView("program_mission", COMPLETED); } }), _jsx(WmProgressSlice, { legend: notAdded, amount: state.programMissionIncomplete.toString(), popoverTitle: popover(state.programMissionIncomplete, state.totalPrograms), popoverText: programsWithoutMission, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () { return openDrilldownView("program_mission", NOT_STARTED); } })] }), _jsxs(WmProgressIndicator, { label: learningOutcomes, children: [_jsx(WmProgressSlice, { legend: created, amount: state.programLearningOutcomes.toString(), popoverTitle: popover(state.programLearningOutcomes, state.totalPrograms), popoverText: programsWithLO, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () {
                                    return openDrilldownView("program_learning_outcomes", COMPLETED);
                                } }), _jsx(WmProgressSlice, { legend: notCreated, popoverTitle: popover(state.programLearningOutcomesIncomplete, state.totalPrograms), popoverText: programsWithoutLO, amount: state.programLearningOutcomesIncomplete.toString(), popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () {
                                    return openDrilldownView("program_learning_outcomes", NOT_STARTED);
                                } })] }), _jsxs(WmProgressIndicator, { label: successOutcomes, children: [_jsx(WmProgressSlice, { legend: created, amount: state.programSuccessOutcomes.toString(), popoverTitle: popover(state.programSuccessOutcomes, state.totalPrograms), popoverText: programsWithSO, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () {
                                    return openDrilldownView("program_success_outcomes", COMPLETED);
                                } }), _jsx(WmProgressSlice, { legend: notCreated, amount: state.programSuccessOutcomesIncomplete.toString(), popoverTitle: popover(state.programSuccessOutcomesIncomplete, state.totalPrograms), popoverText: programsWithoutSO, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () {
                                    return openDrilldownView("program_success_outcomes", NOT_STARTED);
                                } })] }), _jsxs(WmProgressIndicator, { label: curriculumMap, children: [_jsx(WmProgressSlice, { legend: mapped, amount: state.programCurriculumMapsComplete.toString(), popoverTitle: popover(state.programCurriculumMapsComplete, state.totalPrograms), popoverText: programsWithCM, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () { return openDrilldownView("program_cmap", COMPLETED); } }), _jsx(WmProgressSlice, { legend: notMapped, amount: state.programCurriculumMapsIncomplete.toString(), popoverTitle: popover(state.programCurriculumMapsIncomplete, state.totalPrograms), popoverText: programsWithoutCM, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () { return openDrilldownView("program_cmap", NOT_STARTED); } })] }), _jsxs(WmProgressIndicator, { label: ILOMapped, children: [_jsx(WmProgressSlice, { legend: mapped, amount: state.iloProgramMappingsComplete.toString(), popoverTitle: popover(state.iloProgramMappingsComplete, state.totalPrograms), popoverText: programsWithILO, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () {
                                    return openDrilldownView("program_ilo_mapping", COMPLETED);
                                } }), _jsx(WmProgressSlice, { legend: notMapped, amount: state.iloProgramMappingsIncomplete.toString(), popoverTitle: popover(state.iloProgramMappingsIncomplete, state.totalPrograms), popoverText: programsWithoutILO, popoverButtonText: POPOVER_BUTTON_TEXT, onClick: function () {
                                    return openDrilldownView("program_ilo_mapping", NOT_STARTED);
                                } })] })] }) }));
    };
    return (_jsxs(React.Fragment, { children: [state.apiError && _jsx(ApiError, { containerClasses: "api-error" }), !state.apiError && (_jsxs("div", { children: [_jsx("h4", { className: "title", children: translate("app.programProgressCard.programProgressDefinition") }), _jsx("p", { className: "prog totals", children: translate("app.programProgressCard.totalPrograms", {
                            totalPrograms: state.totalPrograms
                        }) }), programChart()] })), state.isDrilldownOpen ? (_jsxs(Fragment, { children: [_jsx("div", { className: "sr-only", id: "program-dialog-wrapper-flyout-top", tabIndex: 0 }), _jsx(OpenDrillDownView, { setIsDrilldownOpen: closeDrilldown, drilldownType: state.drilldownType, drilldownStatus: state.drilldownStatus, subHeader: DD_SUB_HEADER, total: totalPrograms, showTable: state.showTable, completeHierarchy: state.hierarchy, filters: drilldownFilters, leads: leads }), _jsx("div", { className: "sr-only", id: "program-dialog-wrapper-flyout-bottom", tabIndex: 0 })] })) : ("")] }));
}
