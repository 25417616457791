import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "../common/elements/Header.scss";
import InsightsFooter from "./common/InsightsFooter";
import InsightsHeader from "./common/InsightsHeader";
import NavigatorSideNav from "./NavigatorSideNav";
import Loader from "src/features/common/elements/Loader";
export var InsightsHeaderSidebar = function (_a) {
    var user = _a.user, componentLoaded = _a.componentLoaded, children = _a.children, hideNavigatorSideNav = _a.hideNavigatorSideNav;
    var isMainComponentLoaded = function () {
        return componentLoaded.institution && componentLoaded.membership;
    };
    return (_jsxs(_Fragment, { children: [!isMainComponentLoaded() && _jsx(Loader, {}), _jsxs("div", { className: hideNavigatorSideNav ? "pagewrapper" : "pagewrapper newHome", children: [_jsx(InsightsHeader, { hideNavigatorSideNav: hideNavigatorSideNav }), _jsxs("div", { className: "nav-main", children: [user && user.uuid !== "" && user.roles.includes("administrator") && (_jsx(NavigatorSideNav, { hideNavigatorSideNav: hideNavigatorSideNav, collapseSideNav: true })), _jsx("div", { className: "contentwrapper background", children: _jsxs("div", { id: "maincontent", children: [children, _jsx(InsightsFooter, {})] }) })] })] })] }));
};
