import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useContext } from "react";
import { AppContext } from "src/contexts/AppContext";
import { CheckCircle, InstitutionIcon } from "src/features/common/Icons";
import WidgetLoader from "src/features/common/elements/WidgetLoader";
import translate from "src/i18n/translate";
import "../../sll/Sll.scss";
import { ProgramInsights } from "./ProgramInsights";
import { ContributorInsights } from "./ContributorInsights";
function navigateToInstitutionsInsights(url) {
    window.location.href = url;
}
export default function OrganizationProgramInsights(_a) {
    var currentReportingYear = _a.currentReportingYear;
    var sysUser = useContext(AppContext).sysUser;
    var isAdminUser = sysUser === null || sysUser === void 0 ? void 0 : sysUser.roles.includes("administrator");
    return (_jsx(_Fragment, { children: _jsxs("div", { className: isAdminUser
                ? "view-insights-container"
                : "view-insights-container contributor-container", children: [isAdminUser && (_jsx(AdministratorInsights, { currentReportingYear: currentReportingYear })), !isAdminUser && _jsx(ContributorInsights, {})] }) }));
}
function AdministratorInsights(_a) {
    var currentReportingYear = _a.currentReportingYear;
    var institution = useContext(AppContext).institution;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "heading", children: translate("app.home.organizationProgramInsights") }), _jsx("div", { className: "desc", children: translate("app.home.organizationProgramInsightsDesc") }), _jsx("div", { className: "wm-grid", children: _jsxs("div", { className: "wm-grid-row", children: [_jsx("div", { className: "grid-container wm-grid-col-6 wm-grid-col-md-10", children: _jsx(InstitutionInsights, {}) }), institution && (institution === null || institution === void 0 ? void 0 : institution.isSllSysAdminIntegrated) && (_jsx("div", { className: "grid-container wm-grid-col-6 wm-grid-col-md-10", children: _jsx(ProgramInsights, { currentReportingYear: currentReportingYear }) }))] }) })] }));
}
function InstitutionInsights() {
    var _a = useContext(AppContext), institution = _a.institution, integratedProducts = _a.integratedProducts;
    var queryString = window.location.search;
    var institutionInsightsUrl = queryString.includes("locale")
        ? "/insights/institution/".concat(institution === null || institution === void 0 ? void 0 : institution.platformUuid, "/").concat(queryString)
        : "/insights/institution/".concat(institution === null || institution === void 0 ? void 0 : institution.platformUuid);
    return (_jsxs("div", { className: "institution-insights-card paper-card", children: [_jsxs("div", { className: "header-container", children: [_jsxs("div", { children: [_jsxs("div", { className: "institution-name flex-row", children: [_jsx(InstitutionIcon, {}), _jsx("div", { className: "", children: _jsx(WmButton, { buttonType: "textonly", onClick: function () {
                                                return navigateToInstitutionsInsights(institutionInsightsUrl);
                                            }, children: _jsx("div", { className: "instiution-link", children: institution === null || institution === void 0 ? void 0 : institution.name }) }) })] }), _jsx("div", { className: "institution-text", children: translate("app.home.org.institution") })] }), _jsx(WmButton, { buttonType: "primary", onClick: function () { return navigateToInstitutionsInsights(institutionInsightsUrl); }, children: translate("app.home.goToInstitution") })] }), _jsxs("div", { children: [_jsx("div", { className: "product-list-heading", children: translate("app.home.availableInsightsHeading") }), _jsxs("div", { children: [integratedProducts === null && (_jsx(WidgetLoader, { loaderContainerClasses: "loader", loaderClasses: "content" })), integratedProducts !== null &&
                                integratedProducts.map(function (product) {
                                    return (_jsxs("div", { className: "product-list-item", children: [_jsx(CheckCircle, {}), _jsx("span", { children: product.name })] }, "".concat(product.id)));
                                })] })] })] }));
}
