var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useReducer } from "react";
import { AppContext } from "src/contexts/AppContext";
import translate from "src/i18n/translate";
import { sendRequest } from "src/common/http";
import WidgetLoader from "src/features/common/elements/WidgetLoader";
import { ContributorInstitutionInsights } from "./ContributorInstitutionInsights";
import { ContributorProgramInsights } from "./ContributorProgramInsights";
import { checkIfSllProductIntegrated } from "src/features/common/helper";
var defaultAction = {
    type: "",
    nodeAccess: null
};
var stateReducer = function (state, action) {
    switch (action.type) {
        case "setNodeAccess":
            return __assign(__assign({}, state), { nodeAccess: action.nodeAccess });
        default:
            return state;
    }
};
var initialState = {
    nodeAccess: null
};
export function ContributorInsights() {
    var _a;
    var _b = useReducer(stateReducer, initialState), state = _b[0], dispatch = _b[1];
    var _c = useContext(AppContext), sysUser = _c.sysUser, integratedProducts = _c.integratedProducts;
    var isAdminUser = sysUser === null || sysUser === void 0 ? void 0 : sysUser.roles.includes("administrator");
    useEffect(function () {
        if (!isAdminUser) {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setNodeAccess", nodeAccess: result }));
            };
            sendRequest({ uri: "/sa/nodes_access", camelize: true }, applyResult);
        }
    }, []);
    var showLoader = state.nodeAccess === null &&
        checkIfSllProductIntegrated(integratedProducts) &&
        !isAdminUser;
    function showContributorInsights() {
        return (state.nodeAccess &&
            !isAdminUser &&
            (state.nodeAccess.programs.length > 0 ||
                state.nodeAccess.organizations.length > 0));
    }
    return (_jsxs(_Fragment, { children: [showContributorInsights() && (_jsxs("div", { children: [_jsx("div", { className: "heading", children: translate("app.home.organizationProgramInsights") }), _jsx("div", { className: "desc", children: translate("app.home.organizationProgramInsightsDesc") }), state.nodeAccess && ((_a = state.nodeAccess.organizations) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsx(ContributorInstitutionInsights, { organizations: state.nodeAccess.organizations })), state.nodeAccess &&
                        state.nodeAccess.programs.length > 0 &&
                        checkIfSllProductIntegrated(integratedProducts) && (_jsx(ContributorProgramInsights, { programs: state.nodeAccess.programs }))] })), showLoader && (_jsx("div", { className: "loader-height", children: _jsx(WidgetLoader, { loaderContainerClasses: "loader", loaderClasses: "content" }) }))] }));
}
