import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import translate from "src/i18n/translate";
var EmptyStateSll = function () {
    return (_jsxs("div", { className: "wm-grid-row student-learning-assesment empty-state-sll ", children: [_jsx("span", { className: "sr-only faculty-loader-status", role: "status", "aria-live": "assertive" }), _jsx("div", { className: "grid-container wm-grid-col-12 wm-grid-col-md-10", children: _jsxs("div", { className: "paper-card paper-card-height", children: [_jsx("div", { className: "header", children: _jsx("div", { className: "info", children: _jsx("h3", { className: "title", children: translate("app.studentLearningAssesment.insightsComingSoon") }) }) }), _jsxs("div", { className: "flex-row empty-state-content", children: [_jsxs("div", { className: "continuous-improvement-insights", children: [_jsx(DataSaveroffIcon, {}), _jsx("div", { className: "sub-header", children: translate("app.continuousImprovement.title") }), _jsx("div", { className: "desc", children: translate("app.studentLearningAssesment.continuousInsightsDesc") })] }), _jsxs("div", { className: "organization-strategy-insights", children: [_jsx(VectorIcon, {}), _jsx("div", { className: "sub-header", children: translate("app.studentLearningAssesment.organizationInsights") }), _jsx("div", { className: "desc", children: translate("app.studentLearningAssesment.organizationInsightsDesc") })] }), _jsxs("div", { className: "student-success-insights", children: [_jsx(AreaChartIcon, {}), _jsx("div", { className: "sub-header", children: translate("app.studentLearningAssesment.studentInsights") }), _jsx("div", { className: "desc", children: translate("app.studentLearningAssesment.studentInsightsDesc") })] })] })] }) })] }));
};
function DataSaveroffIcon() {
    return (_jsx("svg", { width: "50", height: "50", viewBox: "0 0 50 50", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M22.9163 4.21875V10.5312C15.8538 11.5521 10.4163 17.5937 10.4163 24.9479C10.4163 26.8229 10.7913 28.5937 11.4163 30.2396L5.99968 33.4271C4.83301 30.8437 4.16634 27.9687 4.16634 24.9479C4.16634 14.1562 12.3955 5.26042 22.9163 4.21875ZM24.9997 39.5312C33.0622 39.5312 39.583 33.0104 39.583 24.9479C39.583 17.5937 34.1455 11.5521 27.083 10.5312V4.21875C37.6247 5.26042 45.833 14.1354 45.833 24.9479C45.833 36.4479 36.5205 45.7812 25.0205 45.7812C18.1247 45.7812 12.0205 42.4271 8.22884 37.2604L13.6455 34.0729C16.3122 37.4062 20.3955 39.5312 24.9997 39.5312Z", fill: "#6B6B6B" }) }));
}
function VectorIcon() {
    return (_jsxs("svg", { width: "50", height: "50", viewBox: "0 0 50 50", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M10 12.5H0V40H10V12.5Z", fill: "#6B6B6B" }), _jsx("path", { d: "M10 0H0V10H10V0Z", fill: "#6B6B6B" }), _jsx("path", { d: "M25 7.5H15V17.5H25V7.5Z", fill: "#6B6B6B" }), _jsx("path", { d: "M40 15H30V25H40V15Z", fill: "#6B6B6B" }), _jsx("path", { d: "M40 27.5H30V40H40V27.5Z", fill: "#6B6B6B" }), _jsx("path", { d: "M25 20H15V40H25V20Z", fill: "#6B6B6B" })] }));
}
function AreaChartIcon() {
    return (_jsx("svg", { width: "50", height: "50", viewBox: "0 0 50 50", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M35.5 10.75L23 0.75L10.5 18.25L0.5 10.75V43.25H45.5V10.75H35.5ZM40.5 35.625L23 22L13 35.75L5.5 29.75V20.75L11.6 25.325L24 7.95L33.75 15.75H40.5V35.625Z", fill: "#6B6B6B" }) }));
}
export default EmptyStateSll;
