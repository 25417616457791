var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmModal, WmModalFooter, WmModalHeader, WmSnackbar } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import translate from "src/i18n/translate";
import CreateReportingYearForm from "./CreateReportingYearForm";
import { useRef } from "react";
export default function CreateReportingYearModal(_a) {
    var openReportingYearModalState = _a.openReportingYearModalState, closeReportingYearModal = _a.closeReportingYearModal, reportingYears = _a.reportingYears, fetchReportingYears = _a.fetchReportingYears, updateReportingYear = _a.updateReportingYear;
    var intl = useIntl();
    function closeButtonTriggered() {
        closeReportingYearModal();
    }
    var reportingYearRef = useRef(null);
    var addNewReportingYear = function () {
        if (reportingYearRef.current) {
            reportingYearRef.current.getCsrfToken();
        }
    };
    var notifications = [];
    function addSnackBar(reportingYearName, requestType) {
        var snackbar = document.getElementById("wm-snackbar");
        var randomId = "snack-".concat(Math.floor(Math.random() * 100) + 1);
        var newNotification = {
            id: randomId,
            message: requestType === "update"
                ? updateReportingYearMessage(reportingYearName)
                : createReportingYearMessage(reportingYearName),
            link: ""
        };
        notifications = __spreadArray([newNotification], notifications, true);
        snackbar.notifications = JSON.stringify(notifications);
    }
    function dismissSnackBar(ev) {
        var snackbar = document.getElementById(ev.target.id);
        notifications = notifications.filter(function (snack) { return snack.id !== ev.detail.id; });
        snackbar.notifications = JSON.stringify(notifications);
    }
    var updateReportingYearMessage = function (name) {
        return intl.formatMessage({
            id: "app.settings.reportingYear.update"
        }, { name: name });
    };
    var createReportingYearMessage = function (name) {
        return intl.formatMessage({
            id: "app.settings.reportingYear.create"
        }, { name: name });
    };
    var getAddOrUpdateDetails = function () {
        if (updateReportingYear) {
            return [
                useIntl().formatMessage({
                    id: "app.settings.reportingYearModal.updateBtn"
                }),
                useIntl().formatMessage({
                    id: "app.settings.editReportingYears"
                })
            ];
        }
        else {
            return [
                useIntl().formatMessage({
                    id: "app.settings.reportingYearModal.primaryBtn"
                }),
                useIntl().formatMessage({
                    id: "app.settings.addReportingYears"
                })
            ];
        }
    };
    var _b = getAddOrUpdateDetails(), primaryBtn = _b[0], modalHeading = _b[1];
    var secondaryBtn = useIntl().formatMessage({
        id: "app.settings.reportingYearModal.secondaryBtn"
    });
    var modalSubHeading = useIntl().formatMessage({
        id: "app.settings.reportingYearModal.subheading"
    });
    var sortedReportingYears = reportingYears;
    sortedReportingYears === null || sortedReportingYears === void 0 ? void 0 : sortedReportingYears.sort(function (current, next) {
        return (new Date(next.startDate).getTime() - new Date(current.startDate).getTime());
    });
    return (_jsxs(_Fragment, { children: [_jsx("div", { id: "preview-modal", children: _jsxs(WmModal, { open: openReportingYearModalState, id: "create-reporting-year-modal", elementToFocus: "titleinput", "aria-label": modalHeading, uid: "create-reporting-year-modal", onWmModalCloseTriggered: closeReportingYearModal, onWmModalSecondaryTriggered: closeButtonTriggered, onWmModalPrimaryTriggered: addNewReportingYear, children: [_jsx(WmModalHeader, { heading: modalHeading, subheading: modalSubHeading }), _jsxs("div", { className: "modal-content body", id: "content-create-reporting-year-modal", children: [_jsxs("div", { className: "modal-content-heading", children: [translate("app.settings.reportingYearModal.requiredTextPart1"), _jsx("span", { className: "modal-content-heading-asterisk", children: " * " }), translate("app.settings.reportingYearModal.requiredTextPart2")] }), _jsx(CreateReportingYearForm, { reportingYears: sortedReportingYears, openReportingYearModalState: openReportingYearModalState, ref: reportingYearRef, closeReportingYearModal: closeReportingYearModal, fetchReportingYears: fetchReportingYears, updateReportingYear: updateReportingYear, addSnackBar: addSnackBar })] }), _jsx(WmModalFooter, { primaryText: primaryBtn, secondaryText: secondaryBtn, deleteStyle: false })] }) }), _jsx(WmSnackbar, { notifications: "[]", id: "wm-snackbar", onWmSnackbarSnackFinished: function (event) { return dismissSnackBar(event); } })] }));
}
