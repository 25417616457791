var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useReducer } from "react";
import { WmButton, WmChart, WmChartSlice } from "@watermarkinsights/ripple-react";
import { AppContext } from "../../contexts/AppContext";
import { closeFlyout, focusButtonComponent } from "../../static/js/commons";
import { useIntl } from "react-intl";
import translate from "src/i18n/translate";
import { sendRequest } from "src/common/http";
import { decode } from "html-entities";
var defaultOrgCountData = {
    common_assessments: [],
    name: "",
    orgs_count: 0,
    uuid: ""
};
var defaultOpenDrillDownViewSllAction = {
    type: "",
    showLessMoreBtn: false,
    selectedAssessmentId: "",
    orgCountData: defaultOrgCountData,
    hierarchy: []
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setCommonAssessmentsData":
            return __assign(__assign({}, state), { hierarchy: action.hierarchy });
        case "setOrgCountData":
            return __assign(__assign({}, state), { orgCount: action.orgCountData.orgsCount, showTableView: true });
        case "setShowLessMoreBtn":
            return __assign(__assign({}, state), { showLessMoreBtn: !action.showLessMoreBtn, selectedAssessmentId: action.selectedAssessmentId });
        default:
            return state;
    }
};
export default function OpenDrillDownViewSll(_a) {
    var setIsDrilldownOpen = _a.setIsDrilldownOpen, drilldownName = _a.drilldownName, subHeader = _a.subHeader, title = _a.title, termUuid = _a.termUuid;
    var institution = useContext(AppContext).institution;
    var initialState = {
        hierarchy: [],
        orgCount: 0,
        showTableView: false,
        showLessMoreBtn: false,
        selectedAssessmentId: ""
    };
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, defaultOpenDrillDownViewSllAction), { type: "setOrgCountData", orgCountData: result }));
            generateRandomCommonAssessmentId(result);
        };
        sendRequest({ uri: "/sll/activity_details/term/".concat(termUuid), camelize: true }, applyResult);
    }, []);
    var getColumnName = function () {
        return [
            commonAssessmentVal,
            organizationLabel,
            totalActivities,
            studentAssessmentByStatus
        ];
    };
    var generateRandomCommonAssessmentId = function (hierarchyData) {
        var _a;
        var finalHierarchyData = (_a = hierarchyData.commonAssessments) === null || _a === void 0 ? void 0 : _a.map(function (commonAssessments) { return ({
            commonAssessments: commonAssessments,
            id: getRandomId()
        }); });
        dispatch(__assign(__assign({}, defaultOpenDrillDownViewSllAction), { type: "setCommonAssessmentsData", hierarchy: finalHierarchyData }));
    };
    function getRandomId() {
        return Math.random();
    }
    var getCommonAssessmentCount = function () {
        return state.hierarchy.length;
    };
    var hierarchyTableBody = function () {
        return (_jsx("div", { className: "org-table", children: _jsxs(Table, { children: [_jsx(TableHeader, { columns: getColumnName() }), _jsx(TableBody, { children: state.hierarchy.map(function (node) {
                            var organizations = renderOrganizationsView(node === null || node === void 0 ? void 0 : node.commonAssessments.orgs, String(node === null || node === void 0 ? void 0 : node.id));
                            return (_jsxs("tr", { className: "datarow", children: [_jsx("td", { className: "datacell", children: decode(node === null || node === void 0 ? void 0 : node.commonAssessments.name) }), _jsx("td", { className: "datacell", children: organizations }), _jsx("td", { className: "datacell", children: node === null || node === void 0 ? void 0 : node.commonAssessments.totalActivities }), _jsx("td", { className: "datacell", children: _jsx(RenderStudentAssessmentByTermChart, { stuAssessment: node === null || node === void 0 ? void 0 : node.commonAssessments.studentAssessments, labelText: decode(node === null || node === void 0 ? void 0 : node.commonAssessments.name) }) })] }, node === null || node === void 0 ? void 0 : node.id));
                        }) })] }) }));
    };
    var renderOrganizationsView = function (organizations, assessmentId) {
        return organizations.length <= 1
            ? renderSingleOrganization(organizations)
            : renderMultipleOrganizations(organizations, assessmentId);
    };
    var renderSingleOrganization = function (organizations) {
        return organizations.map(function (name) {
            return _jsx("div", { children: decode(name) }, name);
        });
    };
    var renderMultipleOrganizations = function (organizations, assessmentId) {
        var sortedOrgs = organizations.sort().map(function (name) {
            return name;
        });
        return !state.showLessMoreBtn ||
            assessmentId !== state.selectedAssessmentId ? (_jsxs("div", { children: [_jsx("span", { children: translate("app.openDrillDownViewSll.orgNameLabel", {
                        orgName: decode(sortedOrgs[0]),
                        orgLength: sortedOrgs.length - 1
                    }) }), _jsx("div", { children: _jsx(WmButton, { buttonType: "textonly", id: "show-more-btn-".concat(assessmentId), onClick: function () {
                            return showLessMoreBtn(state.showLessMoreBtn, "show-more-btn-".concat(assessmentId), assessmentId);
                        }, children: translate("app.openDrillDownViewSll.showMoreBtn") }, "".concat(sortedOrgs[0], "-data")) })] })) : (_jsxs("div", { children: [sortedOrgs.map(function (name) {
                    return (_jsx("div", { children: _jsx("span", { children: decode(name) }) }, "".concat(name, "-row")));
                }), _jsx("div", { children: _jsx(WmButton, { buttonType: "textonly", id: "show-less-btn-".concat(assessmentId), onClick: function () {
                            return showLessMoreBtn(state.showLessMoreBtn, "show-less-btn-".concat(assessmentId), assessmentId);
                        }, children: translate("app.openDrillDownViewSll.showLessBtn") }) })] }));
    };
    var showLessMoreBtn = function (showLessMoreBtn, btnId, assessmentId) {
        dispatch(__assign(__assign({}, defaultOpenDrillDownViewSllAction), { type: "setShowLessMoreBtn", showLessMoreBtn: showLessMoreBtn, selectedAssessmentId: assessmentId }));
        setTimeout(function () { return focusButtonComponent(btnId); }, 300);
    };
    var CloseDrillDownView = function () {
        setIsDrilldownOpen(false);
        closeFlyout();
    };
    var DrillDownNameVal = function (val) {
        return useIntl().formatMessage({
            id: "app.assessmentProgressChart.studentAssessmentDrilldownName"
        }, { termName: val });
    };
    var closeBtnText = useIntl().formatMessage({
        id: "app.openDrillDownViewSll.closeBtnText"
    });
    var studentAssessmentByStatus = useIntl().formatMessage({
        id: "app.openDrillDownViewSll.studentAssessmentsbyStatus"
    });
    var totalActivities = useIntl().formatMessage({
        id: "app.openDrillDownViewSll.totalActivities"
    });
    var organizationLabel = useIntl().formatMessage({
        id: "app.openDrillDownViewSll.organization"
    });
    var commonAssessmentVal = useIntl().formatMessage({
        id: "app.openDrillDownViewSll.commonAssessmentVal"
    });
    var drillDownViewAriaLabel = useIntl().formatMessage({
        id: "app.openDrillDownViewSll.drillDownViewAriaLabel"
    });
    return (_jsxs("div", { className: "flyout-panel half-flyout show", role: "dialog", id: "drill-down-view", tabIndex: -1, "aria-label": drillDownViewAriaLabel, children: [_jsx("div", { className: "overlay" }), _jsxs("div", { className: "container-box", children: [_jsx("div", { className: "paper-card", children: _jsxs("div", { className: "overlay-content", children: [_jsx(WmButton, { buttonType: "navigational", icon: "f156", tooltip: closeBtnText, tooltipPosition: "bottom", id: "drill-down-close-btn", class: "hydrated", onClick: CloseDrillDownView }), _jsxs("div", { className: "helper-text", children: [_jsx("h2", { className: "title", children: subHeader }), _jsx("div", { className: "description", children: DrillDownNameVal(String(drilldownName)) })] })] }) }), state.showTableView && state.hierarchy ? (_jsxs("section", { className: "body", children: [_jsxs("div", { className: "flex-row -justified -verticallycentered", children: [_jsxs("div", { id: "organisation-details", children: [_jsx("div", { className: "title -title", children: title !== null && title !== void 0 ? title : (institution && institution.name) }), _jsx("div", { className: "count", children: translate("app.openDrillDownViewSll.orgProgCount", {
                                                    orgCount: state.orgCount
                                                }) }), _jsx("div", { className: "count", children: translate("app.openDrillDownViewSll.commonAssessmentCount", {
                                                    commonAssessmentCount: getCommonAssessmentCount()
                                                }) })] }), _jsxs("div", { id: "chart-legends-area", className: "flex-row", children: [_jsx("div", { className: "legends-label", children: translate("app.openDrillDownViewSll.legend") }), _jsx("div", { className: "legend-wrapper", children: _jsxs("div", { className: "legend", "aria-hidden": "true", children: [_jsxs("div", { className: "legend-item -drilldown-legends", children: [_jsx("div", { className: "legend-color legend1 -legend-wrap" }), _jsx("div", { className: "legend-text", children: translate("app.assessmentProgressChart.awaitingSubmission") })] }), _jsxs("div", { className: "legend-item -drilldown-legends", children: [_jsx("div", { className: "legend-color legend2 -legend-wrap" }), _jsx("div", { className: "legend-text", children: translate("app.assessmentProgressChart.awaitingAssessment") })] }), _jsxs("div", { className: "legend-item -drilldown-legends", children: [_jsx("div", { className: "legend-color legend3" }), _jsx("div", { className: "legend-text", children: translate("app.done") })] })] }) })] })] }), hierarchyTableBody()] })) : (_jsx("div", { className: "loader -halfscreen", children: _jsx("div", { className: "content content-loader", children: translate("app.loading") }) }))] })] }));
}
var Table = function (props) {
    return _jsx("table", { className: "data-table -shadow", children: props.children });
};
var TableHeader = function (props) {
    return (_jsx("thead", { className: "dataheader", children: _jsx("tr", { className: "dataheaderrow", children: props.columns.map(function (column, index) {
                return (_jsx("th", { className: "dataheadercell", "data-th": column, children: column }, "column-".concat(index)));
            }) }) }));
};
var TableBody = function (props) {
    return _jsx("tbody", { className: "databody", children: props.children });
};
var RenderStudentAssessmentByTermChart = function (props) {
    var awaitingSubmission = useIntl().formatMessage({
        id: "app.assessmentProgressChart.awaitingSubmission"
    });
    var awaitingAssessment = useIntl().formatMessage({
        id: "app.assessmentProgressChart.awaitingAssessment"
    });
    var done = useIntl().formatMessage({
        id: "app.done"
    });
    function usePopTextByStatus(status, labelText) {
        return useIntl().formatMessage({
            id: "app.assessmentProgressChart.popoverStatusText"
        }, { statusLabel: status, labelText: labelText });
    }
    function useGetLabelNameVal(labelText) {
        return useIntl().formatMessage({
            id: "app.assessmentProgressChart.labelNameText"
        }, { labelName: labelText });
    }
    var total = Number(Object.values(props.stuAssessment).reduce(function (newVal, oldVal) { return (newVal = newVal + oldVal); }, 0));
    return (_jsxs(WmChart, { "chart-type": "bar4", "value-format": "amount", "show-legend": "false", label: useGetLabelNameVal(props.labelText), children: [_jsx(WmChartSlice, { legend: awaitingSubmission, amount: String(props.stuAssessment.awaitingSubmission), popoverText: usePopTextByStatus(awaitingSubmission, props.labelText), "popover-title": props.stuAssessment.awaitingSubmission + "/" + total }), _jsx(WmChartSlice, { legend: awaitingAssessment, amount: String(props.stuAssessment.awaitingAssessment), popoverText: usePopTextByStatus(awaitingAssessment, props.labelText), "popover-title": props.stuAssessment.awaitingAssessment + "/" + total }), _jsx(WmChartSlice, { legend: done, amount: String(props.stuAssessment.done), popoverText: usePopTextByStatus(done, props.labelText), "popover-title": props.stuAssessment.done + "/" + total })] }));
};
