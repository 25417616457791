var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useReducer } from "react";
import "../app/App.scss";
import Body from "../features/common/elements/Body";
import { OrgInsightsBody } from "../features/organization-insights/OrgInsightsBody";
import PlanView from "../features/continuous-improvement/export/PlanView";
import LaborMarketExportView from "../features/lightcast/LaborMarketExportView";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import FacultySuccessExportView from "src/features/faculty-success/export/FacultySuccessExportView";
import MissionAndOutcomesExportView from "src/features/strategy/mission-and-outcomes/export/MissionAndOutcomesExportView";
import SllExportView from "src/features/sll/export/SllExportView";
import CesHtmlView from "src/features/ces/export/HtmlView";
import HomeComponent from "src/features/insights/home/HomeComponent";
import UnauthorizedComponent from "src/features/insights/UnauthorizedComponent";
import IncorrectURLComponent from "src/features/insights/IncorrectURLComponent";
import OrgProfileComponent from "src/features/insights/OrgProfileComponent";
import AcademicsAndOffices from "src/features/insights/academicsAndOffices/AcademicsAndOffices";
import { InsightsSettings } from "src/features/insights/settings/InsightsSettings";
import { InsightsHeaderSidebar } from "src/features/insights/InsightsHeaderSidebar";
import { PlanningHeaderSidebar } from "src/features/insights/PlanningHeaderSidebar";
import { IntlProvider } from "react-intl";
import { pig } from "../static/js/pig.js";
import ENGLISH from "../i18n/languages/en-US.json";
import { AppContext } from "../contexts/AppContext";
import Loader from "src/features/common/elements/Loader";
import { initiateGainsight } from "../static/js/gainsight_integration";
import { defaultInstitution, defaultMembership, defaultInsightsUser } from "src/features/common/interfaces/Interface";
import { sendRequest } from "src/common/http";
import { checkIfProductIntegrated } from "src/features/common/helper";
var defaultAction = {
    locale: "",
    type: "",
    leads: [],
    component: "",
    institution: defaultInstitution,
    membership: defaultMembership,
    showLightcastPreview: false,
    user: defaultInsightsUser,
    tab: "",
    integratedProducts: null,
    nodeAccessContributor: null
};
var reducer = function (state, action) {
    var componentLoaded = state.componentLoaded;
    switch (action.type) {
        case "setLocale":
            return setLocale(state, action.locale);
        case "setUser":
            return __assign(__assign({}, state), { user: action.user });
        case "setLeads":
            return __assign(__assign({}, state), { leads: action.leads });
        case "setInstitution":
            return __assign(__assign({}, state), { institution: action.institution });
        case "setNodeAccess":
            return __assign(__assign({}, state), { nodeAccess: false });
        case "setMembership":
            return __assign(__assign({}, state), { membership: action.membership });
        case "setComponentLoaded":
            componentLoaded[action.component] = true;
            return __assign(__assign({}, state), { componentLoaded: componentLoaded });
        case "setPreviewModalStatus":
            componentLoaded.openPreviewModal = false;
            return __assign(__assign({}, state), { componentLoaded: componentLoaded });
        case "SetSelectedTabView":
            return __assign(__assign({}, state), { tab: action.tab });
        case "setLightcastPreviewFlag":
            return __assign(__assign({}, state), { institution: __assign(__assign({}, state.institution), { showLightcastPreview: action.showLightcastPreview }) });
        case "setIntegratedProducts":
            return state.integratedProducts
                ? state
                : __assign(__assign({}, state), { integratedProducts: action.integratedProducts });
        case "setNodeAccessContributor":
            return __assign(__assign({}, state), { nodeAccessContributor: action.nodeAccessContributor });
        default:
            return state;
    }
};
var setLocale = function (state, locale) {
    switch (locale) {
        case "pig": {
            return __assign(__assign({}, state), { locale: "en-US", messages: pig(ENGLISH) });
        }
        default: {
            return __assign(__assign({}, state), { locale: "en-US", messages: ENGLISH });
        }
    }
};
var initialState = {
    locale: "en-US",
    messages: ENGLISH,
    institution: defaultAction.institution,
    user: defaultInsightsUser,
    leads: [],
    membership: defaultMembership,
    tab: "institution-strategy",
    componentLoaded: {
        selectedTab: "item-1",
        collapseSideNav: false,
        openPreviewModal: false,
        institution: false,
        membership: false
    },
    integratedProducts: null,
    nodeAccess: true,
    nodeAccessContributor: null
};
function getUuid(type) {
    var pathname = window.location.pathname;
    var regexMap = {
        organization: /\/organization\/([a-z0-9\-]{36})/,
        institution: /\/institution\/([a-z0-9\-]{36})/
    };
    var regex = regexMap[type];
    var match = pathname.match(regex);
    return match ? match[1] : null;
}
function App() {
    var _a, _b;
    var REDIRECT_URI = runConfig.REDIRECT_URI;
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    useEffect(function () {
        var queryString = window.location.search;
        var param = new URLSearchParams(queryString);
        var paramlocale = param.get("locale");
        var locale = paramlocale ? paramlocale : "";
        dispatch(__assign(__assign({}, defaultAction), { type: "setLocale", locale: locale }));
    }, []);
    useEffect(function () {
        var applyResult = function (result) {
            if (result) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setInstitution", institution: result }));
            }
            else {
                dispatch(__assign(__assign({}, defaultAction), { type: "setNodeAccess" }));
            }
            dispatch(__assign(__assign({}, defaultAction), { type: "setComponentLoaded", component: "institution" }));
        };
        var onError = function () {
            window.location.href = REDIRECT_URI;
        };
        var organizationUuid = getUuid("organization");
        var institutionUuid = getUuid("institution");
        var params = [];
        if (organizationUuid) {
            params.push("program_uuid=".concat(organizationUuid));
        }
        if (institutionUuid) {
            params.push("institution_uuid=".concat(institutionUuid));
        }
        var queryString = params.length > 0 ? "?".concat(params.join("&")) : "";
        sendRequest({
            uri: "/sa/institution".concat(queryString),
            camelize: true
        }, applyResult, onError);
    }, [REDIRECT_URI]);
    useEffect(function () {
        if (state.institution.navigatedFrom === "navigator") {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setUser", user: result }));
                dispatch(__assign(__assign({}, defaultAction), { type: "setComponentLoaded", component: "membership" }));
            };
            sendRequest({ uri: "/sa/user_info", camelize: true }, applyResult);
        }
    }, [state.institution.navigatedFrom]);
    useEffect(function () {
        if (state.institution.navigatedFrom === "navigator" &&
            state.user &&
            state.user.gainsightData) {
            initiateGainsight(state.user.gainsightData);
        }
    }, [state.user]);
    useEffect(function () {
        var queryString = window.location.search;
        var param = new URLSearchParams(queryString);
        var paramlocale = param.get("locale");
        var tab = param.get("tab");
        var locale = paramlocale ? paramlocale : "";
        dispatch(__assign(__assign({}, defaultAction), { type: "setLocale", locale: locale }));
        dispatch(__assign(__assign({}, defaultAction), { type: "SetSelectedTabView", tab: tab || "" }));
    }, []);
    useEffect(function () {
        if (state.institution.platformUuid != "" && !state.integratedProducts) {
            var applyResult = function (result) {
                setIntegratedProducts(result);
            };
            sendRequest({
                uri: "/home/integrated-products",
                camelize: true
            }, applyResult);
        }
    }, [state.institution.platformUuid]);
    var onWmTabSelectedEvent = function (event) {
        if (event.detail.tabId === "labor-market-insights" &&
            state.institution.showLightcastPreview) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setComponentLoaded", component: "openPreviewModal" }));
            dispatch(__assign(__assign({}, defaultAction), { type: "setLightcastPreviewFlag", showLightcastPreview: false }));
        }
        dispatch(__assign(__assign({}, defaultAction), { type: "SetSelectedTabView", tab: event.detail.tabId }));
    };
    var setSelectedTabView = function (tabId) {
        dispatch(__assign(__assign({}, defaultAction), { type: "SetSelectedTabView", tab: tabId }));
    };
    var setIntegratedProducts = function (result) {
        dispatch(__assign(__assign({}, defaultAction), { type: "setIntegratedProducts", integratedProducts: result }));
    };
    var initialContext = {
        institution: state.institution,
        selectedTab: state.tab,
        onWmTabSelectedEvent: onWmTabSelectedEvent,
        sysUser: state.user,
        leads: state.leads,
        componentLoaded: state.componentLoaded,
        membership: state.membership,
        integratedProducts: state.integratedProducts,
        setComponentLoaded: function (component) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setComponentLoaded", component: component }));
        },
        setleads: function (result) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setLeads", leads: result }));
        },
        setMembership: function (result) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setMembership", membership: result }));
        },
        closePreviewModal: function () {
            var applyResult = function (result) { };
            sendRequest({ uri: "/lightcast/lightcast_preview_shown", camelize: true }, applyResult);
            dispatch(__assign(__assign({}, defaultAction), { type: "setPreviewModalStatus" }));
        }
    };
    var ifAdministrator = state.user.roles.includes("administrator");
    var ifInsightsContributor = state.user.roles.includes("contributor") &&
        state.user.roles.length === 1 &&
        state.institution.navigatedFrom === "navigator";
    useEffect(function () {
        if (ifInsightsContributor) {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setNodeAccessContributor", nodeAccessContributor: result }));
            };
            sendRequest({ uri: "/sa/nodes_access", camelize: true }, applyResult);
        }
    }, [ifInsightsContributor]);
    var isContributorUserWithInstitutionAccess = ifInsightsContributor &&
        state.nodeAccessContributor !== null &&
        state.nodeAccessContributor.organizations.length > 0;
    var authForPssHtml = state.institution.isPssIntegrated &&
        (isContributorUserWithInstitutionAccess || !ifInsightsContributor);
    var authForCEHtml = state.institution.showCourseEvaluation &&
        (isContributorUserWithInstitutionAccess || !ifInsightsContributor);
    var authForSllHtml = ((_a = state.integratedProducts) === null || _a === void 0 ? void 0 : _a.some(function (product) { return product.id === "sll"; })) &&
        (isContributorUserWithInstitutionAccess || !ifInsightsContributor);
    var authForFacultyHtml = (_b = state.integratedProducts) === null || _b === void 0 ? void 0 : _b.some(function (product) {
        return product.id === "fs" &&
            (isContributorUserWithInstitutionAccess || !ifInsightsContributor);
    });
    var isPSSUserOrLoadedInsightsUser = state.institution.navigatedFrom === "navigator"
        ? state.user && state.user.uuid !== ""
        : true;
    var orgProgramsPageAuth = ifAdministrator ||
        (ifInsightsContributor &&
            checkIfProductIntegrated(state.integratedProducts, "sll") &&
            state.nodeAccessContributor !== null &&
            state.nodeAccessContributor.programs.length > 0);
    var institutionPageAuth = ifAdministrator || isContributorUserWithInstitutionAccess;
    var isNodeAccessDataRequired = ifAdministrator ||
        (ifInsightsContributor && state.nodeAccessContributor) ||
        state.institution.navigatedFrom === "planning";
    return (_jsx(IntlProvider, { locale: state.locale, messages: state.messages, children: _jsx(AppContext.Provider, { value: initialContext, children: _jsx("div", { className: "pagewrapper", children: state.nodeAccess ? (state.messages &&
                    state.institution &&
                    state.institution.platformUuid != "" &&
                    state.integratedProducts &&
                    isNodeAccessDataRequired &&
                    (isPSSUserOrLoadedInsightsUser ? (_jsx(_Fragment, { children: _jsx(Router, { children: _jsxs(Switch, { children: [_jsx(Route, { path: "/insights/reporting_year/:reporting_year_uuid/plan/:plan_uuid/html", children: _jsx(PlanView, { auth: authForPssHtml }) }), _jsx(Route, { path: "/insights/labor_market/html", children: _jsx(LaborMarketExportView, { auth: authForPssHtml }) }), _jsx(Route, { path: "/insights/faculty_success/reporting_year/:reporting_year_uuid/mapping_id/:mapping_id/html", children: _jsx(FacultySuccessExportView, { auth: authForFacultyHtml }) }), _jsx(Route, { path: "/insights/institution-strategy/html", children: _jsx(MissionAndOutcomesExportView, { auth: authForPssHtml }) }), _jsx(Route, { path: "/insights/sll/reporting_year/:reporting_year_uuid/html", children: _jsx(SllExportView, { auth: authForSllHtml }) }), _jsx(Route, { path: "/insights/ces/survey/:survey_id/project/:project_id/html", children: _jsx(CesHtmlView, { auth: authForCEHtml }) }), _jsx(Route, { path: "/insights/academic_office", children: InsightsHeaderSidebar({
                                            user: state.user,
                                            componentLoaded: state.componentLoaded,
                                            children: _jsx(AcademicsAndOffices, { auth: ifAdministrator })
                                        }) }), _jsx(Route, { path: "/insights/home", children: InsightsHeaderSidebar({
                                            user: state.user,
                                            componentLoaded: state.componentLoaded,
                                            children: _jsx(HomeComponent, {})
                                        }) }), _jsx(Route, { path: "/insights/settings", children: InsightsHeaderSidebar({
                                            user: state.user,
                                            componentLoaded: state.componentLoaded,
                                            children: _jsx(InsightsSettings, { auth: ifAdministrator })
                                        }) }), _jsx(Route, { path: "/insights/planning/institution/:institution_uuid/organization/:organization_uuid", children: PlanningHeaderSidebar({
                                            institution: state.institution,
                                            componentLoaded: state.componentLoaded,
                                            children: (_jsx(OrgInsightsBody, { auth: state.institution.isPssIntegrated }))
                                        }) }), _jsx(Route, { path: "/insights/institution/:institution_uuid/organization/:organization_uuid", children: InsightsHeaderSidebar({
                                            user: state.user,
                                            componentLoaded: state.componentLoaded,
                                            hideNavigatorSideNav: true,
                                            children: _jsx(OrgInsightsBody, { auth: orgProgramsPageAuth })
                                        }) }), _jsx(Route, { path: "/insights/institution/:institution_uuid", children: InsightsHeaderSidebar({
                                            user: state.user,
                                            componentLoaded: state.componentLoaded,
                                            hideNavigatorSideNav: true,
                                            children: (_jsx(OrgProfileComponent, { setSelectedTabView: setSelectedTabView, auth: institutionPageAuth }))
                                        }) }), _jsx(Route, { path: "/insights/planning/home", children: PlanningHeaderSidebar({
                                            institution: state.institution,
                                            componentLoaded: state.componentLoaded,
                                            children: (_jsx(Body, { setSelectedTabView: setSelectedTabView, auth: state.institution.isPssIntegrated }))
                                        }) }), _jsx(Route, { path: "/insights/unauthorized", children: _jsx(UnauthorizedComponent, {}) }), _jsx(Route, { children: _jsx(IncorrectURLComponent, {}) })] }) }) })) : (_jsx(Loader, {})))) : (_jsx(UnauthorizedComponent, {})) }) }) }));
}
export default App;
