var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useReducer } from "react";
import { sendRequest } from "../../../common/http";
import translate from "src/i18n/translate";
import ApiError from "../../common/elements/ApiError";
import TopProgramsTable from "./TopProgramsTable";
import WidgetLoader from "src/features/common/elements/WidgetLoader";
var defaultCatalogAction = {
    type: "",
    programAndPageViews: null,
    showLoader: true,
    apiError: false
};
var initialState = {
    apiError: false,
    programAndPageViews: null,
    showLoader: true
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setPrograms":
            return __assign(__assign({}, state), { programAndPageViews: action.programAndPageViews });
        case "setShowLoader":
            return __assign(__assign({}, state), { showLoader: false });
        case "setApiError":
            return __assign(__assign({}, state), { apiError: action.apiError });
        default:
            return state;
    }
};
export default function TopProgramsByCatalog(_a) {
    var mappingId = _a.mappingId, onComponentLoad = _a.onComponentLoad;
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, defaultCatalogAction), { type: "setShowLoader" }));
            dispatch(__assign(__assign({}, defaultCatalogAction), { type: "setPrograms", programAndPageViews: result }));
            onComponentLoad("topProgramsByCatalog");
        };
        var onError = function () {
            dispatch(__assign(__assign({}, defaultCatalogAction), { type: "setShowLoader" }));
            dispatch(__assign(__assign({}, defaultCatalogAction), { type: "setApiError", apiError: true }));
            onComponentLoad("topProgramsByCatalog");
        };
        sendRequest({ uri: "/cs/program_page_views?mapping_id=".concat(mappingId), camelize: true }, applyResult, onError);
    }, [mappingId]);
    var showData = function () {
        return !state.showLoader &&
            !state.apiError &&
            state.programAndPageViews &&
            state.programAndPageViews.length;
    };
    return (_jsxs("div", { className: "paper-card paper-card-height", children: [_jsx("div", { className: "header", children: _jsxs("div", { className: "info", children: [_jsx("h3", { className: "title", children: translate("app.strategy.topProgramsByCatalogHeader") }), _jsx("p", { className: "description", children: translate("app.strategy.topProgramsByCatalogDescription") })] }) }), _jsxs("div", { className: "body", children: [state.showLoader && (_jsx("div", { className: "loader-position", children: _jsx(WidgetLoader, { loaderContainerClasses: "loader-container", loaderClasses: "widget-loader" }) })), state.apiError && _jsx(ApiError, {}), showData() && state.programAndPageViews ? (_jsx("div", { children: _jsx(TopProgramsTable, { programDetails: state.programAndPageViews }) })) : (""), !state.showLoader &&
                        state.programAndPageViews &&
                        state.programAndPageViews.length === 0 ? (_jsx(NoProgramViews, {})) : ("")] })] }));
}
var NoProgramViews = function () {
    return (_jsx("div", { className: "no-plans", children: _jsx("div", { className: "no-plans-text", children: translate("app.strategy.topPrograms.noProgramViews") }) }));
};
