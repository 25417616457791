import { camlizeKeys } from "src/features/common/helper";
var API_ROOT = runConfig.API_ROOT;
var defaultErrorHandler = function (error) { return console.log("Error:", error); };
export function sendRequest(_a, applyResult, onError) {
    var uri = _a.uri, camelize = _a.camelize;
    if (onError === void 0) { onError = defaultErrorHandler; }
    fetch("".concat(API_ROOT).concat(uri), {
        method: "GET",
        mode: "cors",
        credentials: "include"
    })
        .then(function (res) {
        if (!res.ok) {
            throw new Error("Error in API: ".concat(res.url, ", Status: ").concat(res.status));
        }
        res.json().then(function (result) {
            if (camelize) {
                result = camlizeKeys(result);
            }
            applyResult(result);
        });
    })
        .catch(onError);
}
export function sendRequestWithPayload(_a, applyResult, onError) {
    var uri = _a.uri, body = _a.body, camelize = _a.camelize, _b = _a.method, method = _b === void 0 ? "POST" : _b;
    if (onError === void 0) { onError = defaultErrorHandler; }
    fetch("".concat(API_ROOT).concat(uri), {
        method: method,
        mode: "cors",
        credentials: "include",
        body: body
    })
        .then(function (res) {
        return res.json().then(function (result) {
            if (camelize) {
                result = camlizeKeys(result);
            }
            applyResult(result);
        });
    })
        .catch(onError);
}
