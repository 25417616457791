import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import translate from "src/i18n/translate";
export default function CoursesTable() {
    return (_jsxs(Table, { children: [_jsx(TableHeader, { columns: [
                    translate("app.missionAndOutcomes.course"),
                    translate("app.orgProgressCard.learningOutcomes"),
                    translate("app.orgProgressCard.ILOMapped"),
                    translate("app.drilldown.lead")
                ] }), _jsx(TableBody, {})] }));
}
var Table = function (props) {
    return _jsx("table", { className: "data-table -shadow", children: props.children });
};
var TableHeader = function (props) {
    return (_jsx("thead", { className: "dataheader", children: _jsx("tr", { className: "dataheaderrow", children: props.columns.map(function (column, index) {
                return (_jsx("th", { className: "dataheadercell", "data-th": column, children: column }, "column-".concat(index)));
            }) }) }));
};
var TableBody = function (props) {
    return (_jsx("tbody", { className: "databody", children: _jsx("tr", { children: _jsx("td", { colSpan: 4, className: "table-row", children: translate("app.missionAndOutcomes.coursesComingSoon") }) }) }));
};
